import { INFINITE_SCROLL_GENERIC_LIST_FILTERS, InfiniteScrollGenericListFilters, LocalizedString, SequenceToken } from "./commons";
import { PriceAmount } from "./currency";
import { PropertyCategory } from "./group";
import { OrderType } from "./orders";
import { Media } from "./request";
import { CurrencyTypes } from "./vehicle";

export const BasketBehaviourTypesArr = ["ResetTimeWhenEmpty", "ContinueTimerInCaseOfEmpty"] as const;

export type BasketBehaviourTypes = typeof BasketBehaviourTypesArr[number];

export const ListEntityTypeArr = ["User", "Organization"] as const;

export type ListEntityType = typeof ListEntityTypeArr[number];

export const ProductTypeArr = ["", "Vehicle", "SparePart"] as const;

export type ProductType = typeof ProductTypeArr[number];

export const ShopStatusArr = ["", "Draft", "ReadyToBePublished", "Published", "Unpublished", "Created", "Preview", "Expired", "Disabled"] as const;

export type ShopStatusType = typeof ShopStatusArr[number];

export const DiscountTypeArr = ["None", "FixedPriceOnly", "FixedPriceFlat", "FixedPriceMultiLevel", "MatrixGrossOnly", "MatrixFlat", "MatrixFlexible", "MatrixMultiLevel"] as const;

export type DiscountType = typeof DiscountTypeArr[number];

export type BlackWhiteList = {
    entityId: string,
    name: string,
    entityType: ListEntityType
}

//---- BASKET RULES -----------------------------------------------------------------

export const BasketRuleStatusesArr = ["", "Enabled", "Disabled"] as const;

export type BasketRuleStatusType = typeof BasketRuleStatusesArr[number];

export const MIN_DURATION = 5;

export const MAX_DURATION = 60;

export type NewBasketRule = {
    [key: string]: any
    name: string,
    duration: string,
    behaviour: BasketBehaviourTypes,
    maxItems?: number | null,
    minItems?: number,
    maxAmount?: number | null,
    minAmount?: number,
    enabled: boolean,
    resetTimeWhenItemIsAdded?: boolean,
    //-------- custom
    minutes?: number
}

export const DEFAULT_BASKET_RULE: NewBasketRule = {
    name: "",
    duration: "00:10:00",
    behaviour: "ResetTimeWhenEmpty",
    maxItems: null,
    minItems: 0,
    maxAmount: null,
    minAmount: 0,
    enabled: true,
    resetTimeWhenItemIsAdded: false,
    //-------- custom
    minutes: 10
};

export type BasketRuleType = {
    id: string,
    behaviour: BasketBehaviourTypes,
    resetTimeWhenItemIsAdded: boolean,
    duration: string,
    name: string,
    minItems: number,
    maxItems: number,
    minAmount: number,
    maxAmount: number,
    enabled: boolean,
    isDefault: boolean
}

export type CartAmount = {
    minWithoutVat: number,
    minWithVat: number,
    maxWithoutVat: number,
    maxWithVat: number,
    currentCurrency: CurrencyTypes
};

export interface BasketRuleSearchResult extends SequenceToken {
    id: string,
    behaviour: BasketBehaviourTypes,
    duration: string,
    name: string,
    minItems: number,
    maxItems: number,
    minAmount: number,
    maxAmount: number,
    enabled: boolean,
    isDefault: boolean,
    resetTimeWhenItemIsAdded: boolean,
    createdOn: Date
}

export interface BasketRuleFilters extends InfiniteScrollGenericListFilters {
}

export const DEFAULT_BASKET_RULES_FILTERS: BasketRuleFilters = {
    ...INFINITE_SCROLL_GENERIC_LIST_FILTERS
};

export type EnableDisableBasketRuleSliceProps = {
    id: string,
    action: "Enable" | "Disable"
    tab: BasketRuleStatusType,
    filters: BasketRuleFilters
}

export type BasketRuleStatistics = {
    all: number,
    disabled: number,
    enabled: number
}

export const DEFAULT_BASKET_RULES_STATISTICS: BasketRuleStatistics = {
    all: 0,
    disabled: 0,
    enabled: 0
};

//---- WEBSHOP ----------------------------------------------------------------------

export interface RichLocalizedString extends LocalizedString {
    normalText?: string | null
}

export type Level = {
    level: number
    fromAmount: number,
    toAmount: number,
    additionalDiscountPercentage: number
}

export const DEFAULT_LEVEL: Level = {
    level: 0,
    additionalDiscountPercentage: 0,
    fromAmount: 0,
    toAmount: 0,
} as const;

export type DiscountModel = {
    discountType: DiscountType,
    discountTemplateId: string | null,
    flat: {
        additionalDiscountPercentage: number,
        appliesToNetAmount: boolean
    } | null,
    multiLevel: {
        isMultiOrder: boolean,
        thresholds: Level[]
    } | null
}

export const DEFAULT_DISCOUNT_MODEL: DiscountModel = {
    discountType: 'None',
    discountTemplateId: null,
    flat: null,
    multiLevel: null
};

export type GroupShop = {
    groupId: string,
    groupName: string,
    numberOfUserInGroups: number,
    numberOfOrganizationInGroups: number
}

export type Shop = {
    backorderQuantity: number,
    backorderAllowed: boolean
    productType: OrderType,
    id: string,
    title: string,
    shortDescription?: RichLocalizedString[],
    longDescription: RichLocalizedString[],
    media: Media[],
    discountModel: DiscountModel,
    shopAccess: {
        groups: GroupShop[],
        whiteList: {
            entityId: string,
            name: string,
            entityType: PropertyCategory
        }[],
        blackList: {
            entityId: string,
            name: string,
            entityType: PropertyCategory
        }[]
    },
    previewDate?: string,
    startDate: string,
    endDate?: string,
    status: ShopStatusType,
    termAndCondition: {
        id: string,
        termAndCondition: LocalizedString[]
    },
    internalNote: RichLocalizedString[],
    featured: boolean
}

export type ShopDisplay = {
    backorderQuantity: number,
    backorderAllowed: boolean
    productType: OrderType,
    shortDescription: string,
    longDescription: string,
    internalNote: string,
    discountModel: DiscountModel,
    shopAccess: {
        groups: GroupShop[],
        whiteList: {
            entityId: string,
            name: string,
            entityType: PropertyCategory
        }[],
        blackList: string
    },
    termAndCondition: {
        id: string,
        termAndCondition: string
    },
    previewDate: string,
    startDate: string,
    endDate: string,
    id: string,
    title: string,
    media: Media[],
    hasMedia: boolean,
    featured: boolean,
    status: ShopStatusType
}

export type NewShop = {
    title: string | null,
    shortDescription: RichLocalizedString[],
    longDescription: RichLocalizedString[],
    previewDate: string | null,
    startDate: string | null,
    endDate: string | null,
    discountModel: DiscountModel | null,
    permanent: boolean
    internalNote: RichLocalizedString[],
    featured: boolean,
    termAndConditionTemplate: string | null,
    basketRuleId: string | null,
    shopAccess: {
        groupIds: string[],
        whiteList: BlackWhiteList[],
        blackList: BlackWhiteList[]
    },
    customFields: Record<string, string>,
    tags: string[],
    maxBackorderQuantity: number | null,
    backorderAllowed: boolean
}

export const DEFAULT_NEW_SHOP: NewShop = {
    title: "",
    shortDescription: [],
    longDescription: [],
    permanent: false,
    previewDate: "",
    startDate: "",
    endDate: "",
    internalNote: [],
    featured: false,
    termAndConditionTemplate: "",
    basketRuleId: "",
    shopAccess: {
        groupIds: [],
        whiteList: [],
        blackList: []
    },
    customFields: {},
    tags: [],
    maxBackorderQuantity: null,
    backorderAllowed: false,
    discountModel: null
};

export interface ShopSearchResult extends SequenceToken {
    id: string,
    score: number,
    title: string,
    previewDate: string,
    createdOn: string,
    startDate: string,
    endDate: string,
    status: ShopStatusType,
    shopType: ProductType,
    featured: boolean,
    enabled: boolean,
    shortDescription: string,
    media: Media[]
}

export interface ShopFilters extends InfiniteScrollGenericListFilters {
    [key: string]: any
    dateField?: "CreatedOn" | "PreviewDate" | "StartDate" | "EndDate",
    min?: Date,
    max?: Date,
    status?: ShopStatusType,
    shopType?: ProductType,
    partReference?: string,
    partDescription?: string,
    supplierFamily?: string,
    minPrice?: number,
    maxPrice?: number
}

export const DEFAULT_SHOP_FILTERS: ShopFilters = {
    ...INFINITE_SCROLL_GENERIC_LIST_FILTERS,
    dateField: "CreatedOn",
    min: undefined,
    max: undefined,
    status: "",
    shopType: "",
    partReference: "",
    partDescription: "",
    supplierFamily: "",
    minPrice: 0,
    maxPrice: 1500
};

export type EnableDisableShopSliceProps = {
    id: string,
    action: "Enable" | "Disable"
    tab: ShopStatusType,
    filters: ShopFilters
}

export type ShopStatistics = {
    all: number,
    published: number,
    created: number,
    preview: number,
    expired: number,
    disabled: number,
    draft: number
}

export const DEFAULT_SHOP_STATISTICS: ShopStatistics = {
    all: 0,
    published: 0,
    created: 0,
    preview: 0,
    expired: 0,
    disabled: 0,
    draft: 0
};

//---- WEBSHOP - PRODUCTS ----------------------------------------------------------------------

export const BundleAvailabilityStatusesArr = ["OutOfStock", "BackOrder", "InStock"] as const;

export type BundleAvailabilityStatusType = typeof BundleAvailabilityStatusesArr[number];

export interface ShopProductFromSearchResult {
    productId: string,
    externalId: string,
    name: string,
    description: string,
    family: {
        supplier: {
            code: string,
            description: string
        }
    },
    customFields: Record<string, string>,
    media: Media[]
}

export interface ShopBundleAvailability {
    stockQuantity: number,
    availableQuantity: number,
    status: BundleAvailabilityStatusType
}

export interface ShopBundleSearchResult extends SequenceToken {
    bundleId: string,
    score: number,
    availability: ShopBundleAvailability,
    price: {
        grossAmount: PriceAmount,
        unitDiscountedAmount: PriceAmount,
        unitDiscountAmount: PriceAmount
    }
    items: ShopProductFromSearchResult[]
    customFields: Record<string, string>
}

export interface ShopBundleFilters extends InfiniteScrollGenericListFilters {
    [key: string]: any,
    externalId?: string,
    familySupplier?: string,
    name?: string,
    availabilityStatus?: string,
    minAvailableQuantity?: number,
    minPrice?: number,
    maxPrice?: number,
    shopIds?: string[]
}

export const DEFAULT_SHOP_BUNDLE_FILTERS: ShopBundleFilters = {
    ...INFINITE_SCROLL_GENERIC_LIST_FILTERS,
    externalId: "",
    familySupplier: "",
    name: "",
    availabilityStatus: "",
    minAvailableQuantity: 0,
    minPrice: 0,
    maxPrice: 100,
    shopIds: []
};

export type Bundle = {
    bundleId: string,
    availability: {
        stockQuantity: number,
        availableQuantity: number,
        status: BundleAvailabilityStatusType
    }
    price: {
        grossAmount: PriceAmount,
        unitDiscountedAmount: PriceAmount,
        unitDiscountAmount: PriceAmount
    }
    items: ShopProductFromSearchResult[]
    customFields: Record<string, string>
}

//---- PUBLIC - WEBSHOP ----------------------------------------------------------------------

export interface PublicShopSearchResult extends SequenceToken {
    id: string,
    score: number,
    title: string,
    createdOn: string,
    startDate: string,
    endDate: string,
    shopType: ProductType,
    featured: boolean,
    shortDescription: string,
    media: Media[]
}

export interface PublicShopFilters extends InfiniteScrollGenericListFilters {
    [key: string]: any
    shopType?: ProductType,
    dateField?: "PreviewDate",
    min?: Date,
    max?: Date,
    all?: string,
    partReference?: string,
    partDescription?: string,
    supplierFamily?: string,
    minPrice?: number,
    maxPrice?: number
}

export const DEFAULT_PUBLIC_SHOP_FILTERS: PublicShopFilters = {
    ...INFINITE_SCROLL_GENERIC_LIST_FILTERS,
    shopType: "",
    dateField: "PreviewDate",
    min: undefined,
    max: undefined,
    all: "",
    partReference: "",
    partDescription: "",
    supplierFamily: "",
    minPrice: 0,
    maxPrice: 1500
};