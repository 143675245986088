import { Button, Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { resetWebshop } from 'src/redux/webshop/webshop-slices';
import AdminWebshopList from './admin/AdminWebshopList';
import PublicWebshopList from './public/PublicWebshopList';
import CartShopPopover from 'src/sections/@dashboard/webshop/publicShop/CartShopPopover';

interface WebshopListManagerProps {
    isPublic?: boolean
}

export default function WebshopListManager({ isPublic }: WebshopListManagerProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { webshop } = useSelector((state: RootState) => state.webshop);

    //reset to refresh the request when its status changes
    useEffect(() => {
        if (webshop) dispatch(resetWebshop());
    }, [webshop]);

    return (
        <Page title={translate(isPublic ? 'menu.management.webshop.public.title' : 'menu.management.webshop.admin.title')}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate('menu.management.webshop.public.title')}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate('menu.management.webshop.public.title') }
                    ]}
                    action={
                        !isPublic ?
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Admin_Create]}>
                                <Button
                                    variant="contained"
                                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                                    onClick={() => navigate(PATH_DASHBOARD.webshop.new)}
                                    sx={{ borderRadius: "100px" }}
                                >
                                    {translate('webshop.list.actions.new')}
                                </Button>
                            </PermissionBasedGuard>
                            :
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Buy]}>
                                <CartShopPopover />
                            </PermissionBasedGuard>
                    }
                />

                {isPublic ?
                    <PublicWebshopList />
                    :
                    <AdminWebshopList />
                }

            </Container>
        </Page>
    );
}