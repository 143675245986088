import { TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, Switch, Box, FormControlLabel, Divider, MenuItem, TextField, Chip } from "@mui/material";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { Permission } from "src/@types/permissions";
import { useLocales } from "src/locales";
import { permDependencies } from "src/pages/dashboard/role/adminRoles/NewAdminRole";
import { lokalizableRoleStr } from "src/pages/dashboard/role/utils";
import { scrollBarStyle } from "../scrollbar/Scrollbar";
import TableHeadCustom from "./TableHeadCustom";
import useTable from "./useTable";
import TableNoData from "./TableNoData";
import { getClusterPermissionListObj, getOrgPermissionListObj, getPermissionCategories } from "src/utils/permissionList";
import useTenant from "src/appHooks/useTenant";
import { hasLicense } from "src/utils/tenant";
import { LicenseTypes } from "src/@types/tenant";

interface PermissionTableTestProps {
    isCluster?: boolean,
    isAll?: boolean
    checkbox?: boolean
    onChange: (permissons: string[]) => void,
    defaultValue?: string[],
    switchField?: boolean,
    swithcValue?: boolean,
    noHereditary?: boolean,
    setSwitch?: (val: boolean) => void
}

export default function PermissionTable({ isCluster, noHereditary, checkbox, onChange, defaultValue, isAll, setSwitch, swithcValue, switchField }: PermissionTableTestProps) {

    const { translate } = useLocales();

    const { dense, onChangeDense } = useTable();

    const tenant = useTenant();

    const [permissions, setPermissions] = useState<string[]>(defaultValue ?? []);

    const [type, setType] = useState<string>("all");

    const permissionList: Permission[] = useMemo(() => {
        if (isAll)
            return [...getClusterPermissionListObj(tenant, translate), ...getOrgPermissionListObj(tenant, translate)];
        if (isCluster)
            return getClusterPermissionListObj(tenant, translate);
        else
            return getOrgPermissionListObj(tenant, translate);
    }, [isAll, isCluster, tenant, translate]);

    const permissionParse = useMemo(() => {
        if (type !== "all" && type !== "selected")
            return permissionList.filter(perm => perm.type === type);

        if (type === "selected")
            return permissionList.filter(perm => permissions.includes(perm.id));

        return permissionList;

    }, [permissionList, permissions, type]);

    const [searchQuery, setSearchQuery] = useState("");

    const categoryList = useMemo(() => {

        const notification = hasLicense(tenant, LicenseTypes.Notification) ? ['Notifications'] : [];

        if (isAll)
            return ['Users', 'Roles', ...notification, ...getPermissionCategories(tenant)];
        if (isCluster)
            return ['Users', 'Roles', ...notification];

        return getPermissionCategories(tenant);
    }, [isAll, isCluster, tenant]);

    const parsedPermissionList: Permission[] = useMemo(() => {
        let parsed: Permission[] = permissionParse;

        if (searchQuery)
            parsed = parsed.filter(v => v.label.toLowerCase().includes(searchQuery.toLowerCase()));

        return parsed;
    }, [permissionParse, searchQuery]);

    const handleChange = (name: string) => {

        let checked = false;

        if (!permissions.includes(name))
            checked = true;

        let perm: string[];

        if (checkbox) {
            let permissionList: string[] = cloneDeep(permissions);

            if (permissionList.includes(name))
                permissionList = permissionList.filter((v) => v !== name);
            else
                permissionList.push(name);

            setPermissions(permissionList);

            onChange(permissionList);
        }
        else if (checked) {

            perm = [name];

            if (!noHereditary && !isAll) {
                let permissionFather = "";
                if (name.includes("Logs")) {
                    permissionFather = "Logs";
                } else if (name.includes("Create")) {
                    permissionFather = "Create";
                } else if (name.includes("Edit")) {
                    permissionFather = "Edit";
                }

                if (permissionFather) {
                    perm = permDependencies(name, permissionFather, type, isCluster ? "Cluster" : "Organization");
                }
                if (name === "WebShop_Create" || name === "WebShop_Edit") {
                    perm.push("Group_View");
                }
            }

            setPermissions((p) => {
                const permList = Array.from(new Set([...p, ...perm]));

                onChange(permList);

                return permList;
            });
        }
        else {
            perm = [...permissions];

            perm.splice(permissions.indexOf(name), 1);

            setPermissions(perm);

            onChange(perm);
        }
    };

    const summary: Record<string, number> = useMemo(() => {
        let sum: Record<string, number> = { "selected": 0 };

        permissions.forEach(perm => {
            sum['selected']++;

            let category = permissionList.find(v => v.id === perm)?.type;

            if (!category)
                return;

            sum[category] = (sum[category] || 0) + 1;

        });

        return sum;
    }, [permissionList, permissions]);

    const TABLE_HEAD = useMemo(() => checkbox ?
        [
            { id: 'enable', align: 'left', width: '10vw', label: `${translate('commons.enabled')}` },
            { id: 'permissionName', label: `${translate('role.form.permissionName')}`, align: 'left' },
            { id: 'description', label: `${translate('commons.description')}`, align: 'left' },
            { id: 'category', label: `${translate('commons.category')}`, align: 'left' },
        ] : [
            { id: 'permissionName', label: `${translate('role.form.permissionName')}`, align: 'left' },
            { id: 'description', label: `${translate('commons.description')}`, align: 'left' },
            { id: 'category', label: `${translate('commons.category')}`, align: 'left' },
            { id: 'enable', label: `${translate('commons.enabled')}`, align: 'left' }
        ], [checkbox, translate]);

    return (
        <Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: switchField ? '1fr 1fr 0.4fr' : '0.5fr 1fr', width: '100%', gap: 3, my: 2 }}>
                <TextField
                    select
                    fullWidth
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    SelectProps={{
                        MenuProps: {
                            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                        },
                    }}
                    sx={{
                        pr: 3,
                        textTransform: 'capitalize'
                    }}>
                    <MenuItem
                        key={""}
                        value={"all"}
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                    >
                        {`${translate('commons.all')}`}
                    </MenuItem>
                    <MenuItem
                        key={"selected"}
                        value={"selected"}
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                    >
                        {`${translate('commons.selected')}`}
                    </MenuItem>

                    {categoryList.map((type) => (
                        <MenuItem
                            key={type}
                            value={type}
                            sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                            }}
                        >
                            {`${translate(`role.type.${type[0].toLowerCase() + type.slice(1)}`)}`}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    label={`${translate('commons.search')}`}
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />

                {switchField && <FormControlLabel
                    name="atLeastOne"
                    label={`${translate('notificationTemplate.form.atLeastOne')}`}
                    onChange={(_, check) => {
                        if (setSwitch)
                            setSwitch(check);
                    }}
                    control={<Switch checked={swithcValue} />}
                />}
            </Box>
            <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
                {Object.entries(summary)
                    .filter(([_, v]) => v > 0)
                    .map(([k, v]) => {
                        const label = k === "selected" ? 'commons.AllSelected' : `role.type.${k[0].toLowerCase() + k.slice(1)}`;

                        return <Chip
                            label={<p><b>{v}</b>{" " + translate(label)}</p>}
                            key={k}
                            onClick={() => setType(k)}
                        />;
                    })}
            </Box>
            <TableContainer sx={[{ minWidth: 800, maxHeight: 650, position: 'relative' }, scrollBarStyle]}>

                <Table stickyHeader size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                        headLabel={TABLE_HEAD}
                        rowCount={parsedPermissionList.length}
                    />
                    <TableBody>
                        {parsedPermissionList.sort((a, b) => a.label.localeCompare(b.label))
                            .map((role: Permission) => (
                                <TableRow key={role.id} hover
                                    onClick={() => {
                                        if (!window.getSelection()?.toString())
                                            handleChange(role.id);
                                    }}
                                    sx={{ cursor: 'pointer' }}>
                                    {checkbox &&
                                        <TableCell align="left">
                                            <Checkbox checked={permissions.includes(role.id)} />
                                        </TableCell>}
                                    <TableCell >
                                        {role.label}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {`${translate(`role.permission.${lokalizableRoleStr(role.id)}.description`)}`}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {`${translate(`role.type.${role.type[0].toLowerCase() + role.type.slice(1)}`)}`}
                                    </TableCell>
                                    {!checkbox &&
                                        <TableCell colSpan={1} align="left">
                                            <Switch
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleChange(role.id);
                                                }}
                                                checked={permissions.includes(role.id)} name={role.id}
                                            />
                                        </TableCell>}
                                </TableRow>
                            ))}
                        <TableNoData isNotFound={parsedPermissionList.length === 0} />
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider />
            <Box sx={{ position: 'relative' }}>

                <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label={`${translate('commons.dense')}`}
                    sx={{ px: 3, py: 1.5, top: 0 }} />
            </Box>
        </Box>
    );
}