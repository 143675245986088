import { Box } from "@mui/material";
import { Order } from "src/@types/orders";
import { Request, RequestNew } from "src/@types/request";
import MoreDetails from "src/components/request-utils/MoreDetails";

interface CompleteNewPartsProps {
    order: Order,
    request?: RequestNew
}

export default function CompleteNewParts({ order, request }: CompleteNewPartsProps) {

    return (
        <Box>
            {order && <MoreDetails order={order} request={request as Request | undefined} />}
        </Box>
    );
}