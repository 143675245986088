import { PriceAmount } from "./currency";
import { DEFAULT_PARENT, GenericOrganization, OganizationType, OrganizationSearchResult } from "./organizations";

export type Priority = "High" | "Medium" | "Low";

export const OrderTypesArr = ["Vehicle", "SparePart"] as const;

export type OrderType = typeof OrderTypesArr[number];

export const DocTypesArr = ["", "Invoice", "CreditNote", "DeliveryNote"] as const;

export type DocType = typeof DocTypesArr[number];

export type OrderSearchItem = {
    itemId: string,
    itemExternalId: string,
    type: OrderType,
    priority: Priority,
    name: string,
    quantity: number
};

export type OrderDetailItem = {
    [key: string]: any
    id: string,
    externalId: string,
    quantity: number,
    itemIndex: number,
    deprecated: boolean,
    type: OrderType,
    priority: Priority,
    name: string,
    purchasePrice: PriceAmount,
    cataloguePrice: PriceAmount,
    grossAmount: PriceAmount,
    regularDiscountPercentage: number,
    extraDiscountPercentage: number,
    extraDiscountAmount: PriceAmount,
    regularDiscountAmount: PriceAmount,
    forcedDiscount: boolean,
    specialPrice: boolean,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    family: {
        name: string,
        code: string
    },
    category: {
        name: string,
        code: string
    },
    class: {
        name: string,
        code: string
    },
    customFields: Record<string, string>,
};

export type VendorAndCustomerInOrders = {
    id: string,
    status: boolean,
    type: OganizationType,
    name: string,
    externalId: string,
    vat: string,
    address: {
        address: string,
        zipCode: string,
        district: string,
        country: string,
        city: string
    }
};
//------------------------------------------------------------------

export type DocumentSearchResult = {
    id: string,
    createdOn: Date,
    prettyName: string,
    type: DocType,
    externalId: string,
    vendorId: string,
    vendorName: string,
    customerId: string,
    customerName: string,
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    customFields: Record<string, string>,
    tags: string[]
}

export type DocumentFilters = {
    [key: string]: any
    //GENERAL
    sortField?: string,
    sortAscending?: boolean,
    pageIndex: number,
    pageSize: number,
    organizationId?: string,
    //SIDEBAR
    externalId?: string,
    documentType?: DocType | "All",
    minAmount?: number,
    maxAmount?: number,
    customerId?: string,
    customer?: OrganizationSearchResult | GenericOrganization,
    parcel?: string,
    orderdRef?: string,
    country?: string,
    tags?: string[],
    customFields?: Record<string, string>,
    //TOOLBAR
    all?: string,
    from?: string,
    to?: string
}

//------------------------------------------------------------------

export type OrderSearchResult = {
    id: string,
    externalId: string,
    createdOn: Date,
    prettyName: string,
    vendorId: string,
    vendorName: string,
    customerId: string,
    customerName: string,
    shopId: string,
    invoiceId: string | null,
    invoiceExternalId?: string | null,
    deliveryNoteId: string | null,
    deliveryNoteExternalId?: string | null,
    creditNodeId: string | null,
    items: OrderSearchItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    customFields: Record<string, string>,
    tags: string[]
};

export type OrderSuggestFilters = {
    vendorIds?: string[],
    customerIds?: string[],
    startDate?: string,
    endDate?: string,
    text?: string,
    size?: number,
    tags?: string[]
};

export type OrderFilters = {
    [key: string]: any
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortAscending?: boolean,
    customer?: OrganizationSearchResult | GenericOrganization,
    customerId?: string,
    from?: string,
    to?: string,
    prettyName?: string,
    documentExternalId?: string,
    itemName?: string,
    itemExternalId?: string,
    itemCustomFields?: Record<string, string>,
    tags?: string[],
    customFields?: Record<string, string>
};

//------------------------------------------------------------------

export type Order = {
    id: string,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    invoiceId: string | null,
    invoiceExternalId?: string | null,
    deliveryNoteId: string | null,
    deliveryNoteExternalId?: string | null,
    creditNodeIds: string[],
    vendor: VendorAndCustomerInOrders,
    customer: VendorAndCustomerInOrders,
    items: OrderDetailItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    customFields: Record<string, string>,
    tags: string[]
};

//------------------------------------------------------------------

export type Invoice = {
    id: string,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    vendor: VendorAndCustomerInOrders,
    customer: VendorAndCustomerInOrders,
    items: OrderDetailItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    hasDeliveryNote: boolean,
    customFields: Record<string, string>,
    tags: string[],
};

export type DeliveryNote = {
    id: string,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    vendor: VendorAndCustomerInOrders,
    customer: VendorAndCustomerInOrders,
    items: OrderDetailItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    hasDeliveryNote?: boolean,
    hasInvoice: boolean,
    customFields: Record<string, string>,
    tags: string[]
};

export type CreditNote = {
    id: string,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    vendor: VendorAndCustomerInOrders,
    customer: VendorAndCustomerInOrders,
    items: OrderDetailItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    customFields: Record<string, string>,
    tags: string[],
    orderIds: string[],
    hasDeliveryNote?: boolean,
};

//------------------------------------------------------------------

export type DocData = {
    partExternalId: string,
    documentId: string,
    documentExternalId: string
};

//------------------------------------------------------------------
export const DEFAULT_ORDERS_FILTERS: OrderFilters = {
    pageIndex: 0,
    pageSize: 10,
    sortField: "",
    sortAscending: false,
    customer: DEFAULT_PARENT,
    customerId: "",
    from: "",
    to: "",
    prettyName: "",
    documentExternalId: "",
    itemName: "",
    itemExternalId: "",
    itemCustomFields: {},
    tags: [],
    customFields: {}
};

export const MIN_PRICE_DOC_FILTER = 0;

export const MAX_PRICE_DOC_FILTER = 200;

export const STEP_PRICE_DOC_FILTER = 5;

export const DEFAULT_DOCUMENTS_FILTERS: DocumentFilters = {
    sortField: "",
    sortAscending: false,
    pageIndex: 0,
    pageSize: 10,
    organizationId: "",
    //--------------
    externalId: "",
    documentType: "All",
    minAmount: MIN_PRICE_DOC_FILTER,
    maxAmount: MAX_PRICE_DOC_FILTER,
    customerId: "",
    customer: DEFAULT_PARENT,
    parcel: "",
    orderdRef: "",
    country: "",
    tags: [],
    customFields: {},
    //--------------
    all: "",
    from: "",
    to: ""
};