import { forwardRef } from 'react';
// @mui
import { Box, BoxProps, CardMedia } from '@mui/material';
//

export interface VideoProps extends BoxProps {
    src: string;
}
// ----------------------------------------------------------------------

const VideoPlayer = forwardRef<HTMLSpanElement, VideoProps>(
    ({ src, sx, ...other }, ref) => {
        return (
            <Box
                ref={ref}
                component="span"
                sx={{
                    lineHeight: 1,
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    position: 'relative',
                    aspectRatio: '1/1',
                    ...sx,
                }}
            >
                <Box
                    sx={{
                        width: '100%'
                    }}>
                    <CardMedia
                        component="video"
                        controls
                        src={src}
                        {...other}
                    />
                </Box>
            </Box >
        );
    }
);

export default VideoPlayer;