import { ClusterPermissionTypes, OrganizationPermissionTypes } from "./permissions";
import { AxiosResponse } from "axios";
import { SelectItem, Tag } from "./commons";
import { LabelColor } from "src/components/label";
import { Lang } from "src/locales";
import { NavigateFunction } from "react-router";

export const convertObjToSelectOptions = (list: any, codeField: string, labelField: string): SelectItem[] => {
    return list.map((item: any) => { return { value: item[codeField], label: item[labelField] }; });
};

export const convertArrayToSelectOptions = (
    list: any,
    translate?: (text: unknown, options?: any) => string,
    lokalizeBaseText?: string
): SelectItem[] => {
    return list.map((item: any) => (
        {
            value: item,
            label: translate ? translate(lokalizeBaseText + item.charAt(0).toLowerCase() + item.slice(1)) : item
        }
    ));
};

const filterTypes = ['Section', 'TextField', 'Select', 'MultiSelect', 'Autocomplete', 'Slider', 'SwitchGroup'] as const;

type FilterTypes = typeof filterTypes[number];

const viewTypes = ['Grid', 'List'] as const;

export type ViewTypes = typeof viewTypes[number];

export type FilterListType = {
    name: string,
    label: string,
    type: FilterTypes,
    toParse?: boolean,
    //---- Permissions
    permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[],
    //---- Autocomplete
    service?: (tag: string, size: number) => Promise<AxiosResponse<Tag[]>>,
    //---- Select
    options?: SelectItem[],
    //---- Slider
    stepValue?: number,
    stepsNumber?: number,
    //---- Switch
    switchesInfo?: {
        name: string,
        label: string,
        permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]
    }[]
}

export interface GenericListFilters {
    [key: string]: any
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortAscending?: boolean
}

export type QuickFilters = {
    key: string,
    label: string,
    color: LabelColor
};

export type ToolbarDateErrors = {
    [key: string]: any
    fromFormat: boolean,
    fromMissing: boolean,
    toFormat: boolean,
    toMissing: boolean,
    toInvalid: boolean
}

export const DEFAULT_DATE_ERRORS: ToolbarDateErrors = {
    fromFormat: false,
    fromMissing: false,
    toFormat: false,
    toMissing: false,
    toInvalid: false
};

export type SingleGridElementProps<T> = {
    key: string,
    item: T,
    isDesktop: boolean,
    translate: (text: unknown, options?: any) => string,
    navigate: NavigateFunction,
    currentLang?: Lang,
    extraProps?: any
}

export type MultipleViewProps<T> = {
    renderGridElement: (props: SingleGridElementProps<T>) => JSX.Element,
    rowNumber?: number,
    extraProps?: any
}

export type DatesGeneralFilterProps = {
    showDates?: boolean,
    datesNames?: [string, string],
    dateSearchBy?: [string, string]
}

//-------------------------------------------------------------------