import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Card, Box, FormControlLabel, Switch, TablePagination, MenuItem, Divider, Typography, useTheme } from '@mui/material';
import { RootState, dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import Label, { LabelColor } from 'src/components/label';
import useTable from 'src/appHooks/useTable';
import useTabs from 'src/appHooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useForm } from 'react-hook-form';
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { CompletedStatusesArr, DEFAULT_REQUEST_FILTERS, GreenStatusType, GreenStatusesArr, RedStatusType, RedStatusesArr, RefusedStatusesArr, RequestFilters, RequestSearchResult, UnderProcessingStatusesArr, YellowStatusType, YellowStatusesArr } from 'src/@types/request';
import { isArray, isEqual, isObject, remove } from 'lodash';
import DateZone from 'src/appComponents/DateZone';
import { requestOperations } from 'src/redux/request';
import { hasPermissions } from 'src/utils/user';
import BalanceIcon from '@mui/icons-material/Balance';
import useResponsive from 'src/hooks/useResponsive';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { detailGeneric } from 'src/services/organizationsService';
import { setFiltersInUrl } from 'src/redux/request/request-slices';
import ReportCreationModal from 'src/sections/@dashboard/report/ReportCreationModal';
import { RequestReportFilters } from 'src/@types/report';
import { LicenseTypes } from 'src/@types/tenant';
import RequestGeneralFilters from 'src/sections/@dashboard/request/filters/RequestGeneralFilters';
import RequestsFilterSidebar from 'src/sections/@dashboard/request/filters/RequestsFilterSidebar';
import RequestsFilterSummary from 'src/sections/@dashboard/request/filters/RequestsFilterSummary';
import { QuickFilters } from 'src/@types/list';
import LicenseGuard from 'src/guards/LicenseGuard';

const BASIC_FILTER_OPTIONS = {
    pageIndex: 0,
    pageSize: 10,
    status: null
};

interface OrganizationRequestsProps {
    organizationID?: string,
    vendor: boolean,
    carrier: boolean,
    anomaly?: boolean
}

export default function RequestList({ organizationID, anomaly, vendor, carrier }: OrganizationRequestsProps) {

    const {
        page,
        setPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    } = useTable();

    const navigate = useNavigate();

    const { organizationId } = useUserOrganizationContext();

    const theme = useTheme();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const { isLoading, requestList, totalCount, statistics, pageSize, pageIndex } = useSelector((state: RootState) => state.request);

    const [filters, setFilters] = useState<RequestFilters>({ ...DEFAULT_REQUEST_FILTERS });

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(true);

    const [resetFormElement, setResetFormElement] = useState("");

    const methods = useForm({ defaultValues: DEFAULT_REQUEST_FILTERS });

    const { reset, getValues, watch, setValue } = methods;

    var formValues = watch();

    //filters for report creation
    const reportFilters: RequestReportFilters = useMemo(() => ({
        all: filters.all || null,
        customerId: !vendor && !carrier ? organizationId : null,
        customFields: filters.customFields || null,
        externalId: filters.externalId || null,
        requestType: filters.requestType || null,
        status: filters.status as string[] || null,
        vendorId: vendor && !carrier ? organizationId : null,
        tags: null
    }), [filters, vendor, carrier, organizationId]);

    //---- REQUEST DIFFERENCIES - START ----//
    const [oldVendor, setOldVendor] = useState(vendor);

    const [oldCarrier, setOldCarrier] = useState(carrier);
    //---- REQUEST DIFFERENCIES - END ----//

    //---- IS DEFAULT - START ----//
    // Checks if there are some filters selected 
    const statsKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending", "status"], []);

    const fullKeysToRemove: string[] = useMemo(() => ["all", "from", "to"], []);

    const statsKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key));
    }, [statsKeysToRemove]);

    const fullKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key) || fullKeysToRemove.includes(key));
    }, [statsKeysToRemove, fullKeysToRemove]);

    const isDefault = useCallback((filter: RequestFilters, controller?: RequestFilters, forStats?: boolean) => {

        const check = controller ? controller : DEFAULT_REQUEST_FILTERS;

        const found = remove(Object.keys(check), forStats ? statsKeyRemover : fullKeyRemover)
            .find((element) => {
                if (isArray(filter[element]) && isArray(check[element])) {
                    return !isEqual(filter[element], check[element]);
                } else if (isObject(filter[element]) && isObject(check[element])) {

                    const strFilters = JSON.stringify(filter[element], Object.keys(filter[element]).sort());

                    const strDefaultFilters = JSON.stringify(check[element], Object.keys(check[element]).sort());

                    return strFilters !== strDefaultFilters;
                } else {
                    return filter[element] !== check[element];
                }
            });

        return (!found);
    }, [statsKeyRemover, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    //---- CLOSE AND OPEN SIDEBAR FUNC
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = () => {
        if (resetForm) {
            reset(DEFAULT_REQUEST_FILTERS);
        };
        setOpenSidebar(false);
    };

    //---- CLEAR FROM SUMMARY FUNC
    const handleClearFromSummary = (section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    };

    //---- FILTERS IN URL GET/SET START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const [lastStatsFilters, setLastStatsFilters] = useState<any>(BASIC_FILTER_OPTIONS);

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters).filter(field => (filters[field] || typeof filters[field] === "boolean") && !isEqual(filters[field], DEFAULT_REQUEST_FILTERS[field]))
            .map((key) => {
                if (isArray(filters[key]) || isObject(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]).replace(/stellantis-europe-/g, ""))}`;

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate]);

    const getPageAndSize = useCallback(() => {
        if (firstRender) {
            return [pageSize, pageIndex];
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    const getFiltersFromUrl = useCallback(() => {
        const { hash } = location;

        let searchFilters = {};

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize();

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
                all: searchParams.get('all'),
                externalId: searchParams.get('externalId'),
                requestType: searchParams.get('requestType'),
                customerId: searchParams.get('customerId'),
                status: searchParams.get('status') ? JSON.parse(searchParams.get('status')!) : null,
                from: searchParams.get('from'),
                to: searchParams.get('to'),
                hasClaim: searchParams.get('hasClaim'),
                customFields: searchParams.get('customFields') ? JSON.parse(searchParams.get('customFields')!.replace(/returnrequestapprover/g, "stellantis-europe-returnrequestapprover")) : null
            };

        } else {
            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null
            };
        }

        searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

        return searchFilters as RequestFilters;

    }, [location, order, orderBy, getPageAndSize]);
    //---- FILTERS IN URL GET/SET END ----//

    //---- QUICKFILTERS START ----// 
    const { organization } = useUserOrganizationContext();

    const findStatusName = useCallback((status: string[] | undefined | null) => {

        if (status) {
            const orderedStatus = JSON.stringify(status.sort());

            if (orderedStatus === JSON.stringify(RefusedStatusesArr.sort())) return "Refused";
            else if (orderedStatus === JSON.stringify(CompletedStatusesArr.sort())) return "Completed";
            else if (orderedStatus === JSON.stringify(UnderProcessingStatusesArr.sort())) return "UnderProcessing";

            return status[0];
        }

        return "All";
    }, []);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus, setCurrentTab: setCurrentStatus } = useTabs(findStatusName(getFiltersFromUrl().status));

    const quickFilters: QuickFilters[] = useMemo(() => {
        const statusFilters: QuickFilters[] = [
            {
                key: 'All',
                label: `all`,
                color: 'info',
            },
            {
                key: 'Completed',
                label: `completed`,
                color: 'success',
            },
            {
                key: 'Created',
                label: `created`,
                color: 'success',
            },
            {
                key: 'Deleted',
                label: `deleted`,
                color: 'error',
            },
            {
                key: 'Refused',
                label: `refusedAndAnomaly`,
                color: 'error',
            },
            {
                key: 'UnderProcessing',
                label: `underProcessing`,
                color: 'warning',
            },
        ];

        if (!hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp], organization.roles, false)) {
            statusFilters.push({
                key: 'Validated',
                label: `validatedBO`,
                color: 'default',
            });
        }

        return (statusFilters);
    }, [organization]);

    const renderQuickFilters = (key: string) => {
        if (statistics) {
            switch (key) {
                case "All":
                    return statistics.total;
                case "Completed":
                    return statistics.completed;
                case "Created":
                    return statistics.created;
                case "Deleted":
                    return statistics.deleted;
                case "Refused":
                    return statistics.refused;
                case "UnderProcessing":
                    return statistics.underProcessing;
                case "Validated":
                    return statistics.validated;
                case "Incomplete":
                    return statistics.incomplete;
                default:
                    return 0;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: RequestFilters) => {

        var searchFilters: any = {};

        if (values === DEFAULT_REQUEST_FILTERS) {
            searchFilters = BASIC_FILTER_OPTIONS;
        } else {

            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
                all: values.all !== DEFAULT_REQUEST_FILTERS.all ? values.all : null,
                externalId: values.externalId !== DEFAULT_REQUEST_FILTERS.externalId ? values.externalId : null,
                requestType: values.requestType !== DEFAULT_REQUEST_FILTERS.requestType ? values.requestType : null,
                customerId: values.customerId !== DEFAULT_REQUEST_FILTERS.customerId ? values.customerId : null,
                status: values.status !== DEFAULT_REQUEST_FILTERS.status ? values.status : null,
                from: values.from !== DEFAULT_REQUEST_FILTERS.from ? values.from : null,
                to: values.to !== DEFAULT_REQUEST_FILTERS.to ? values.to : null,
                hasClaim: values.hasClaim !== DEFAULT_REQUEST_FILTERS.hasClaim ? values.hasClaim : null,
                customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_REQUEST_FILTERS.customFields) ? values.customFields : null
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [order, orderBy, page, rowsPerPage, updateFiltersInUrl]);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    // This function is used to call APIs and get Requests list and statistics using filters
    const getVendorAndCustomerId = useCallback((filtersFromUrl: RequestFilters) => {

        //IF THIS LIST IS SHOWN IN SOME ORGANIZATION DETAIL
        if (organizationID) {
            return ({
                ...filtersFromUrl,
                customerId: organizationID
            });
        }

        return (filtersFromUrl);

    }, [organizationID]);

    const adjustLastFiltered = useCallback((values: RequestFilters) => {

        return {
            pageIndex: 0,
            pageSize: 10,
            sortField: "",
            sortAscending: false,
            all: values.all !== DEFAULT_REQUEST_FILTERS.all ? values.all : undefined,
            idRequest: values.idRequest !== DEFAULT_REQUEST_FILTERS.idRequest ? values.idRequest : undefined,
            externalId: values.externalId !== DEFAULT_REQUEST_FILTERS.externalId ? values.externalId : undefined,
            requestType: values.requestType !== DEFAULT_REQUEST_FILTERS.requestType ? values.requestType : undefined,
            region: values.region !== DEFAULT_REQUEST_FILTERS.region ? values.region : undefined,
            warehouse: values.warehouse !== DEFAULT_REQUEST_FILTERS.warehouse ? values.warehouse : undefined,
            depositRef: values.depositRef !== DEFAULT_REQUEST_FILTERS.depositRef ? values.depositRef : undefined,
            status: values.status !== DEFAULT_REQUEST_FILTERS.status ? values.status : undefined,
            from: values.from !== DEFAULT_REQUEST_FILTERS.from ? values.from : undefined,
            to: values.to !== DEFAULT_REQUEST_FILTERS.to ? values.to : undefined,
            hasClaim: values.hasClaim !== DEFAULT_REQUEST_FILTERS.hasClaim ? values.hasClaim : undefined,
            customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_REQUEST_FILTERS.customFields) ? values.customFields : undefined
        };
    }, []);

    const onSearch = useCallback(async (filtersFromUrl: RequestFilters) => {

        updateFiltersInUrl(filtersFromUrl);

        const filtersFromUrlCopy = JSON.parse(JSON.stringify(filtersFromUrl));

        if (filtersFromUrlCopy.from && filtersFromUrlCopy.to) {
            filtersFromUrlCopy.from = new Date(filtersFromUrlCopy.from || "").toISOString();
            filtersFromUrlCopy.to = new Date(new Date(filtersFromUrlCopy.to || "").setHours(24, 59, 59)).toISOString();
        }

        const vendorAndCustomerFilters = getVendorAndCustomerId(filtersFromUrlCopy);

        const customFieldsFromUrl: Record<string, string> = Object.entries(vendorAndCustomerFilters.customFields || {})
            .map(([k, val]) => ({
                key: "customFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFiltersFromUrl = { ...vendorAndCustomerFilters, ...customFieldsFromUrl };

        delete updatedFiltersFromUrl.customFields;

        let searchStats = false;

        const strFiltersFromUrl = JSON.stringify(filtersFromUrl, Object.keys(filtersFromUrl).sort());

        const strBasicFilters = JSON.stringify(BASIC_FILTER_OPTIONS, Object.keys(BASIC_FILTER_OPTIONS).sort());

        if (strFiltersFromUrl === strBasicFilters || !isDefault(filtersFromUrl, lastStatsFilters as RequestFilters, true) || firstRender) {
            searchStats = true;
            setLastStatsFilters(adjustLastFiltered(filtersFromUrl));
        }

        if (carrier) {
            dispatch(requestOperations.searchCarrierRequests(updatedFiltersFromUrl));
            if (searchStats) dispatch(requestOperations.searchCarrierStatistic(updatedFiltersFromUrl));
        }
        else {
            dispatch(requestOperations.searchRequests({ options: updatedFiltersFromUrl, isMine: vendor }));
            if (searchStats) dispatch(requestOperations.searchStatistic({ options: updatedFiltersFromUrl, isMine: vendor }));
        }

        if (filtersFromUrl.customerId && filtersFromUrl.customerId !== getValues("customer")?.id) {
            const { data } = await detailGeneric(filtersFromUrl.customerId);

            setValue("customer", data);
        }

        if (firstRender) setFirstRender(false);

    }, [carrier, getVendorAndCustomerId, vendor, updateFiltersInUrl, firstRender, adjustLastFiltered, isDefault, lastStatsFilters, getValues, setValue]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filterToCheck: RequestFilters, controller: RequestFilters) => {

        const differencesToController = Object.keys(filterToCheck).filter((filter) => filterToCheck[filter] !== controller[filter]);

        if (differencesToController.includes("sortField") || differencesToController.includes("sortAscending")) return true;

        const differencesToFilter = Object.keys(controller).filter((filter) => controller[filter] !== filterToCheck[filter]);

        return differencesToFilter.includes("sortField") || differencesToFilter.includes("sortAscending");
    }, []);

    useEffect(() => {

        let searchFilters = getFiltersFromUrl();

        if (anomaly) {
            searchFilters.status = RefusedStatusesArr as (GreenStatusType | RedStatusType | YellowStatusType)[];
        } else {
            switch (filterStatus) {
                case "Refused":
                    searchFilters.status = RefusedStatusesArr as (GreenStatusType | RedStatusType | YellowStatusType)[];
                    break;
                case "Completed":
                    searchFilters.status = CompletedStatusesArr as (GreenStatusType | RedStatusType | YellowStatusType)[];
                    break;
                case "UnderProcessing":
                    searchFilters.status = UnderProcessingStatusesArr as (GreenStatusType | RedStatusType | YellowStatusType)[];
                    break;
                case "All":
                    searchFilters.status = null;
                    break;
                default:
                    searchFilters.status = [filterStatus] as (GreenStatusType | RedStatusType | YellowStatusType)[];
            }
        }

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label || oldVendor !== vendor || oldCarrier !== carrier) {

            if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;
            setFilters(searchFilters as RequestFilters);
            onSearch(searchFilters as RequestFilters);
            setLastUsedLang(currentLang.label);
            setOldVendor(vendor);
            setOldCarrier(carrier);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
        }

    }, [firstRender, location, page, rowsPerPage, orderBy, order, filterStatus, anomaly, setCurrentStatus, currentLang, lastUsedLang, onSearch, getFiltersFromUrl, filters, oldVendor, vendor, carrier, oldCarrier, setPage, setRowsPerPage, isOrderDiff]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = () => {
        fetchData(getValues());
        setResetForm(false);
        setOpenSidebar(false);
        setPage(0);
    };

    //---- FILTERS RESET - START ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = () => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        setPage(0);
        setResetForm(true);
        fetchData(DEFAULT_REQUEST_FILTERS);
        reset(DEFAULT_REQUEST_FILTERS);
    };

    const handleResetSingleFilter = (fieldName: string, value?: any) => {
        if (isArray(formValues[fieldName])) {
            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues);
            }
        } else if (isObject(formValues[fieldName])) {

            delete (formValues[fieldName] as Record<string, string>)[value];

            setValue(fieldName, formValues[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues);

        } else {
            formValues[fieldName] = DEFAULT_REQUEST_FILTERS[fieldName];
            setValue(fieldName, DEFAULT_REQUEST_FILTERS[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues);
        }
    };
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            if (vendor && hasPermissions([
                OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation,
                OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp,
                OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView
            ], organization.roles)) {
                if (RefusedStatusesArr.includes(params.row.status) && hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly], organization.roles)) {
                    navigate(PATH_DASHBOARD.request.vendor.anomalies.detail(params.row.returnRequestId.toString(), anomaly ? "a" : "r"));
                }
                else navigate(PATH_DASHBOARD.request.vendor.backlogDetail(params.row.returnRequestId.toString()));
            }
            else if (carrier && hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp], organization.roles)) {
                navigate(PATH_DASHBOARD.request.carrier.backlogDetail(params.row.returnRequestId.toString()));
            }
            else {
                navigate(PATH_DASHBOARD.request.customer.detail(params.row.returnRequestId.toString()));
            }
        }
    };

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const getStatusColor = (status: string): LabelColor => {

        let color = 'default';

        if (status) {
            if (GreenStatusesArr.includes(status as GreenStatusType)) color = "success";
            else if (RedStatusesArr.includes(status as RedStatusType)) color = "error";
            else if (YellowStatusesArr.includes(status as YellowStatusType)) color = "warning";
        }

        return color as LabelColor;
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || requestList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const handleLogs = useCallback((id: string) => {
        if (vendor)
            navigate(PATH_DASHBOARD.request.vendor.logs(id));
        else
            navigate(PATH_DASHBOARD.request.customer.logs(id));
    }, [navigate, vendor]);

    //GROUP A: ADMIN, HUB BO, WAREHOUSE OPERATOR
    const COLUMNS_GROUP_A: GridColDef<RequestSearchResult>[] = useMemo(() => [
        {
            field: 'prettyName',
            headerName: `${translate('returns.tableHeaders.prettyName')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 125 : undefined
        },
        {
            field: 'itemExternalId',
            headerName: `${translate('returns.tableHeaders.partref')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, row) => {
                return row.item.productExternalId;
            }
        },
        {
            field: 'name',
            headerName: `${translate('returns.tableHeaders.partdescription')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            valueGetter: (_, row) => {
                return row.item.name;
            }
        },
        {
            field: 'customerName',
            headerName: `${translate('returns.tableHeaders.customerName')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined
        },
        {
            field: 'requestType',
            headerName: `${translate('returns.tableHeaders.reason')}`,
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, row) => {
                return `${translate(`request.${row?.requestType}`)}`;
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('commons.createdOn')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj: any) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'quantity',
            headerName: `${translate('returns.tableHeaders.quantity')}`,
            headerAlign: 'center',
            flex: isDesktop ? 0.5 : undefined,
            maxWidth: !isDesktop ? 75 : undefined,
            renderCell: (obj: any) => {
                return (
                    <Typography variant="body2" sx={{ px: "45%" }}>
                        {obj.row?.quantity}
                    </Typography>
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj: any) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {`${translate(`commons.order.returnRequest.${obj.row.status}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        object={obj}
                        currentRow={actualRow}
                        vendor={vendor}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, handleLogs, actualRow, vendor]);

    //GROUP B: CUSTOMER, DELIVERY ADDRESS OPERATOR
    const COLUMNS_GROUP_B: GridColDef<RequestSearchResult>[] = useMemo(() => [
        {
            field: 'prettyName',
            headerName: `${translate('returns.tableHeaders.prettyName')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 125 : undefined
        },
        {
            field: 'itemExternalId',
            headerName: `${translate('returns.tableHeaders.partref')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, row) => {
                return row?.item.productExternalId;
            },
        },
        {
            field: 'name',
            headerName: `${translate('returns.tableHeaders.partdescription')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            valueGetter: (_, row) => {
                return row?.item.name;
            },
        },
        {
            field: 'partFamily',
            headerName: `${translate('returns.tableHeaders.partFamily')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            sortable: false,
            renderCell: (params) =>
                <Box sx={{ width: "100%" }}>
                    <Typography variant="subtitle2">
                        <b>{params.row?.item.family?.code}</b>
                    </Typography>
                    <Typography variant="body2" noWrap>
                        {params.row?.item.family?.name}
                    </Typography>
                </Box >
        },
        {
            field: 'requestType',
            headerName: `${translate('returns.tableHeaders.reason')}`,
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, row) => {
                return `${translate(`request.${row?.requestType}`)}`;
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('commons.createdOn')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj: any) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'quantity',
            headerName: `${translate('returns.tableHeaders.quantity')}`,
            headerAlign: 'center',
            flex: isDesktop ? 0.5 : undefined,
            maxWidth: !isDesktop ? 75 : undefined,
            renderCell: (obj: any) => {
                return (
                    <Typography variant="body2" sx={{ px: (isDesktop ? 3.25 : 1.75) }}>
                        {obj.row?.quantity}
                    </Typography>
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj: any) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {`${translate(`commons.order.returnRequest.${obj.row.status}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        object={obj}
                        currentRow={actualRow}
                        vendor={vendor}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, handleLogs, actualRow, vendor]);

    //GROUP C: CARRIER DRIVER
    const COLUMNS_GROUP_C: GridColDef<RequestSearchResult>[] = useMemo(() => [
        {
            field: 'prettyName',
            headerName: `${translate('returns.tableHeaders.prettyName')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 140 : undefined
        },
        {
            field: 'customerName',
            headerName: `${translate('returns.tableHeaders.customerName')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 215 : undefined
        },
        {
            field: 'deliveryOrganizationName',
            headerName: `${translate('returns.tableHeaders.delivery')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            sortable: false,
        },
        {
            field: 'vendorName',
            headerName: `${translate('returns.tableHeaders.hubName')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 175 : undefined
        },
        {
            field: 'createdOn',
            headerName: `${translate('commons.createdOn')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj: any) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'bigAndHeavy',
            headerName: `${translate('returns.tableHeaders.bigAndHeavy')}`,
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 130 : undefined,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <BalanceIcon
                        sx={{
                            ...(!obj.row.bigAndHeavy && { color: theme.palette.grey[400] }),
                        }}
                    />
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 185 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj: any) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15 || !isDesktop}
                    >
                        {`${translate(`commons.order.returnRequest.${obj.row.status}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        object={obj}
                        currentRow={actualRow}
                        vendor={vendor}
                    />
                );
            }
        }
    ], [translate, isDesktop, theme.palette.grey, openMenu, handleLogs, actualRow, vendor]);

    const columns: GridColDef<RequestSearchResult>[] = useMemo(() => {

        if (vendor) {
            return (COLUMNS_GROUP_A);
        }

        if (carrier) {
            return (COLUMNS_GROUP_C);
        }

        return (COLUMNS_GROUP_B);
    }, [vendor, carrier, COLUMNS_GROUP_B, COLUMNS_GROUP_A, COLUMNS_GROUP_C]);

    useEffect(() => {
        if (totalCount < rowsPerPage * page)
            onChangePage(null, 0);
    }, [onChangePage, page, rowsPerPage, totalCount]);
    //---- HANDLE TABLE END ----//

    return (
        <Card>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 3.5 }, pb: 2 }}>
                <Box>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        {`${translate(anomaly ? 'request.anomalies.listDetail' : 'returns.detail')}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex'
                    }}
                >

                    {!carrier &&
                        <LicenseGuard license={LicenseTypes.Report}>
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Report_View]}>
                                <ReportCreationModal allFilters={reportFilters} reportType={"ReturnRequest"} />
                            </PermissionBasedGuard>
                        </LicenseGuard>
                    }

                    <FormProvider methods={methods}>
                        <RequestsFilterSidebar
                            isDefault={isDefault}
                            isOpen={openSidebar}
                            onFilter={handleSearchFilters}
                            onOpen={handleOpenSidebar}
                            onClose={handleCloseSidebar}
                            onResetAll={handleResetAllFilter}
                            reset={resetForm}
                            resetFormElement={resetFormElement}
                            filters={{ ...formValues, ...(getFiltersFromUrl()) }}
                            setResetFormElement={setResetFormElement}
                            isVendor={vendor}
                        />
                    </FormProvider>
                </Box>
            </Box>

            {!anomaly &&
                <>
                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons="auto"
                        value={filterStatus}
                        onChange={onChangeFilterStatus}
                        sx={{
                            px: { xs: 0, md: 2 },
                            bgcolor: 'background.neutral'
                        }}
                    >
                        {quickFilters.map((tab) => (
                            <Tab
                                disableRipple
                                key={tab.key}
                                label={`${translate(`returns.statuses.${tab.label}`)}`}
                                value={tab.key}
                                icon={
                                    <Label color={tab?.color} sx={{ mr: 1 }}>
                                        {statistics ? renderQuickFilters(tab.key) : ""}
                                    </Label>
                                }
                            />
                        ))}
                    </Tabs>

                    <Divider />
                </>
            }

            <FormProvider methods={methods}>
                <RequestGeneralFilters
                    filters={{ ...formValues, ...(getFiltersFromUrl()) }}
                    onFilter={handleSearchFilters}
                    resetAll={handleResetAllFilter}
                />
            </FormProvider>

            <RequestsFilterSummary
                filters={openSidebar ? DEFAULT_REQUEST_FILTERS : { ...formValues, ...(getFiltersFromUrl()) }}
                isShowReset={!isDefault({ ...formValues, ...(getFiltersFromUrl()) }) && !openSidebar}
                onResetAll={handleResetAllFilter}
                onResetFilter={handleResetSingleFilter}
            />

            <Divider />

            <Box>
                <DataGrid
                    rows={requestList}
                    columns={columns}
                    pagination
                    paginationModel={{
                        page: page,
                        pageSize: rowsPerPage
                    }}
                    density={(dense && requestList.length > 0) ? 'compact' : 'standard'}
                    sortingMode={"server"}
                    onSortModelChange={handleSort}
                    loading={isLoading}
                    slots={{
                        noRowsOverlay: noData,
                        footer: () => (
                            <Box sx={{
                                position: 'relative',
                                width: { xs: "90vw", md: "auto" },
                            }}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 30]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangeRowsPerPage}
                                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                    sx={{
                                        overflow: "hidden",
                                        "& .MuiTablePagination-input": {
                                            ml: { xs: 0.5, md: "default" },
                                            mr: { xs: 3.5, md: "default" }
                                        }
                                    }}
                                />
                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={`${translate('commons.dense')}`}
                                    sx={{
                                        px: { xs: 0, sm: 3 },
                                        py: { xs: 0, sm: 1.5 },
                                        pb: { xs: 1.5, sm: 0 },
                                        mx: 0,
                                        top: 0,
                                        justifyContent: "center",
                                        width: { xs: "90vw", sm: "auto" },
                                        position: { sm: 'absolute' }
                                    }}
                                />
                            </Box>
                        )
                    }}
                    disableColumnMenu
                    pageSizeOptions={[5, 10, 15, 30]}
                    disableRowSelectionOnClick
                    disableColumnResize
                    onCellClick={(param) => {
                        if (!window.getSelection()?.toString())
                            handleCellClick(param);
                    }}
                    sx={{
                        ...DataGridStyle,
                        cursor: 'pointer',
                        height: getHeight(),
                        maxHeight: getMaxHeight()
                    }}
                />
            </Box>

        </Card>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleLogs: (id: string) => void,
    object: any,
    currentRow: any,
    vendor: boolean
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, object, currentRow, vendor }: OptionsComponentProps) {

    const { translate } = useLocales();

    const navigate = useNavigate();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    {handleLogs &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorViewLogs, OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerViewLogs]}>
                            <MenuItem
                                onClick={() => {
                                    handleLogs(object.row.returnRequestId);
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate("commons.logs")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }
                    {vendor &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView]} absoluteVerification>
                            <MenuItem
                                onClick={() => {
                                    navigate(PATH_DASHBOARD.request.vendor.detail(object.row.returnRequestId.toString()));
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate("request.customerView")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }
                </>
            }
        />
    );
}