import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { SingleGridElementProps } from 'src/@types/list';
import { ShopBundleFilters, DEFAULT_SHOP_BUNDLE_FILTERS, ShopBundleSearchResult, ShopBundleAvailability } from 'src/@types/webshop';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { ToolbarSearchFilters } from 'src/@types/commons';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { CurrencyTypes } from 'src/@types/vehicle';
import { webshopOperations } from 'src/redux/webshop';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import SvgColor from 'src/components/svg-color';
import { ThemeModeValue, useSettingsContext } from 'src/components/settings';
import { getPrice } from 'src/utils/currency';
import Label from 'src/components/label';
import { getFirstLowerCase } from 'src/appUtils/string';
import IncrementerButton from 'src/components/custom-input/IncrementerButton';
import { basketOperations } from 'src/redux/basket';
import { getQuantityInBasket } from 'src/utils/basket';
import CheckIcon from '@mui/icons-material/Check';
import { PATH_DASHBOARD } from 'src/routes/paths';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MenuPopover from 'src/components/menu-popover';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { hasPermissions } from 'src/utils/user';
import { OrganizationPermissionTypes } from 'src/@types/permissions';

interface ProductsListDetailProps {
    isPublic?: boolean
}

export default function ProductsList({ isPublic }: ProductsListDetailProps) {

    const { translate } = useLocales();

    const { organization } = useUserOrganizationContext();

    const navigate = useNavigate();

    const { themeMode } = useSettingsContext();

    const isDesktop = useResponsive('up', 'md');

    const { id: webshopId } = useParams();

    const { isBundleLoading: isProductLoading, bundleList: productList, bundleTotalCount: productTotalCount } = useSelector((state: RootState) => state.webshop);

    const { basket } = useSelector((state: RootState) => state.basket);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: ShopBundleFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_SHOP_BUNDLE_FILTERS[field])
        , []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((filters: { filters: ShopBundleFilters & { sequenceToken: any }, check: boolean }) => {
        if (webshopId) dispatch(webshopOperations.searchShopBundles({ ...filters, shopId: webshopId, isPublic: isPublic }));
    }, [isPublic, webshopId]);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarSearchFilters[] = useMemo(() =>
        [
            { key: 'name', label: translate('spareParts.list.tableHeaders.partDesc') },
            { key: 'externalId', label: translate('spareParts.list.tableHeaders.partNumber') },
            { key: 'familySupplier', label: translate('webshop.detail.products.familySupplier') },
        ], [translate]);

    //---- HANDLE TABLE START ----//
    const [actualRow, setActualRow] = useState<GridCellParams<ShopBundleSearchResult> | null>(null);

    const handleCellClick = (params: GridCellParams<ShopBundleSearchResult>) => {

        setActualRow(params);

        if (params.field !== "addToCart" && params.field !== "availability.stockQuantity") {
            navigate(PATH_DASHBOARD.webshopPublic.productDetail(webshopId ?? "", params.row.bundleId));
        }
    };

    const [quantitiesRecord, setQuantitiesRecord] = useState<Record<string, number>>({});

    const [addedQuantitiesRecord, setAddedQuantitiesRecord] = useState<Record<string, number>>({});

    const handleAddQuantity = useCallback((bundleId: string) => ({
        ...quantitiesRecord,
        [bundleId]: 1
    }), [quantitiesRecord]);

    const handleRemoveQuantity = useCallback((bundleId: string) => {
        const updatedRecord = cloneDeep(quantitiesRecord);

        delete updatedRecord[bundleId];

        return updatedRecord;
    }, [quantitiesRecord]);

    const handleChangeQuantity = useCallback((bundleId: string, action: "increase" | "decrease") => {
        setQuantitiesRecord((prev) => {
            const updatedRecord = { ...prev };

            if (action === "increase") {
                if (updatedRecord[bundleId]) updatedRecord[bundleId] += 1;
                else return handleAddQuantity(bundleId);
            } else {
                updatedRecord[bundleId] -= 1;
                if (!updatedRecord[bundleId]) return handleRemoveQuantity(bundleId);
            }

            return updatedRecord;
        });
    }, [handleAddQuantity, handleRemoveQuantity]);

    const handleAddToCart = useCallback(async (bundleId: string, quantity: number) => {
        try {
            await dispatch(basketOperations.addItemPublicBasket({
                bundleId,
                shopId: webshopId!,
                quantity
            })).unwrap();

            setQuantitiesRecord(handleRemoveQuantity(bundleId));

            setAddedQuantitiesRecord((prev) => ({
                ...prev,
                [bundleId]: quantity
            }));
        } catch (e) {
            console.error(e);
        }
    }, [handleRemoveQuantity, webshopId]);

    const getQuantityInBasketPrefilled = useCallback((bundleId: string) => getQuantityInBasket(basket, webshopId!, bundleId), [basket, webshopId]);

    const COLUMNS: GridColDef<ShopBundleSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'flex', alignItems: "center" }}>

                    {obj.row.items[0].media[0] ?
                        <img
                            alt={""}
                            src={obj.row.items[0].media[0]?.fileInfo[0]?.url ?? ""}
                            style={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                aspectRatio: "1",
                                objectFit: "cover",
                                border: "1px ridge"
                            }}
                        />
                        :
                        <Box
                            sx={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                border: "1px ridge",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: (theme) => theme.palette.divider
                            }}
                        >
                            <NoPhotographyIcon sx={{ width: "30px", height: "30px" }} />
                        </Box>

                    }

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, flexGrow: 1 }}>
                        <Typography variant='subtitle2' sx={{ mb: -0.5 }}>
                            {obj.row.items[0].name}
                        </Typography>
                        {obj.row.items[0].family && <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            <b>{"[" + obj.row.items[0].family.supplier.code + "] - "}</b>{obj.row.items[0].family.supplier.description}
                        </Typography>}
                    </Box>
                </Box>
        },
        {
            field: 'externalId',
            headerName: translate('spareParts.list.tableHeaders.partNr'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'grossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.price.grossAmount)}
                        currency={obj.row.price.grossAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.price.grossAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
        {
            field: 'unitDiscountedAmount',
            headerName: translate('webshop.detail.products.discountedAmount'),
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <></>
                    // <CurrencyAdapter
                    //     value={getPrice(obj.row.price.unitDiscountedAmount)}
                    //     currency={obj.row.price.unitDiscountedAmount?.currentCurrency}
                    //     noVatAsterisk={!!obj.row.price.unitDiscountedAmount?.withoutVat}
                    //     fontSize={'0.875rem'}
                    // />
                );
            }
        },
        {
            field: 'availability.stockQuantity',
            headerName: translate('spareParts.list.tableHeaders.stockQuantity'),
            flex: isDesktop ? 0.4 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            valueGetter: (_, row) => row.availability.stockQuantity ?? "—"
        },
        {
            field: 'score',
            headerName: translate('webshop.detail.products.score'),
            flex: isDesktop ? 0.7 : undefined, //(0.7)
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <ProgressIcon progress={0} themeMode={themeMode} score={obj.row.score} />
                );
            }
        }
    ], [translate, isDesktop, themeMode]);

    const PUBLIC_COLUMNS: GridColDef<ShopBundleSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'grid', gridTemplateColumns: '20% 80%', alignItems: "center", width: '100%' }}>

                    {obj.row.items[0].media[0] ?
                        <img
                            alt={""}
                            src={obj.row.items[0].media[0]?.fileInfo[0]?.url ?? ""}
                            style={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                aspectRatio: "1",
                                objectFit: "cover",
                                border: "1px ridge"
                            }}
                        />
                        :
                        <Box
                            sx={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                border: "1px ridge",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: (theme) => theme.palette.divider
                            }}
                        >
                            <NoPhotographyIcon sx={{ width: "30px", height: "30px" }} />
                        </Box>

                    }

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                        <Typography variant={'subtitle2'} sx={{ mb: -0.5 }}>
                            {obj.row.items[0].name}
                        </Typography>
                        {obj.row.items[0].family && <Typography variant={'body2'} textOverflow={'ellipsis'} overflow={'hidden'}>
                            <b>{"[" + obj.row.items[0].family.supplier.code + "] - "}</b>{obj.row.items[0].family.supplier.description}
                        </Typography>}
                    </Box>
                </Box>
        },
        {
            field: 'externalId',
            headerName: translate('spareParts.list.tableHeaders.partNr'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'grossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.price.grossAmount)}
                        currency={obj.row.price.grossAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.price.grossAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
        {
            field: 'unitDiscountedAmount',
            headerName: translate('webshop.detail.products.discountedAmount'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <></>
                    // <CurrencyAdapter
                    //     value={getPrice(obj.row.price.unitDiscountedAmount)}
                    //     currency={obj.row.price.unitDiscountedAmount.currentCurrency}
                    //     noVatAsterisk={!!obj.row.price.unitDiscountedAmount.withoutVat}
                    //     fontSize={'0.875rem'}
                    // />
                );
            }
        },
        /*         {
                    field: 'unitDiscountAmount',
                    headerName: translate('webshop.detail.products.discountAmount'),
                    flex: isDesktop ? 0.5 : undefined,
                    minWidth: !isDesktop ? 250 : undefined,
                    renderCell: (obj) => {
                        return (
                            <CurrencyAdapter
                                value={getPrice(obj.row.price.unitDiscountAmount)}
                                currency={obj.row.price.unitDiscountAmount.currentCurrency}
                                noVatAsterisk={!!obj.row.price.unitDiscountAmount.withoutVat}
                                fontSize={'0.875rem'}
                            />
                        );
                    }
                }, */
        {
            field: 'availability.status',
            headerName: `${translate('commons.status')}`,
            flex: isDesktop ? 0.55 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (obj.row.availability.status !== "InStock" &&
                    <Label
                        color={obj.row.availability.status === "BackOrder" ? 'info' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`statuses.${getFirstLowerCase(obj.row.availability.status)}`)}
                    </Label>
                );
            }
        },
        ...((hasPermissions([OrganizationPermissionTypes.WebShop_Buy], organization.roles)) ? [
            {
                field: 'availability.stockQuantity',
                headerName: "",
                flex: isDesktop ? 0.8 : undefined,
                minWidth: !isDesktop ? 250 : undefined,
                sortable: false,
                renderCell: (obj) => {
                    return (
                        <AvailabilityIncrementer
                            translate={translate}
                            bundleId={obj.row.bundleId}
                            availability={obj.row.availability}
                            quantitiesRecord={quantitiesRecord}
                            handleChangeQuantity={handleChangeQuantity}
                            getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
                        />
                    );
                }
            },
            {
                field: 'addToCart',
                headerName: "",
                flex: isDesktop ? 0.5 : undefined,
                minWidth: !isDesktop ? 250 : undefined,
                sortable: false,
                align: "right",
                renderCell: (obj) => {

                    const quantity: number = quantitiesRecord[obj.row.bundleId] ?? 0;

                    const addedQuantityToCart: number = addedQuantitiesRecord[obj.row.bundleId] ?? 0;

                    return (
                        <>
                            {quantity > 0 ?
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => handleAddToCart(obj.row.bundleId, quantity)}
                                    sx={{ borderRadius: "100px" }}
                                >
                                    {translate(`webshop.detail.products.addToCart`)}
                                </Button>
                                :
                                <>
                                    {addedQuantityToCart > 0 &&
                                        <Label
                                            color={'success'}
                                            fontSize={"14px"}
                                            fontWeight={"700"}
                                            sx={{ borderRadius: "100px", px: 2, py: 2 }}
                                        >
                                            <CheckIcon sx={{ mr: 1 }} />
                                            {addedQuantityToCart + " " + translate(`webshop.detail.products.added`)}
                                        </Label>
                                    }
                                </>
                            }
                        </>
                    );
                }
            }] : []) as GridColDef<ShopBundleSearchResult>[]
    ], [translate, isDesktop, organization.roles, quantitiesRecord, handleChangeQuantity, getQuantityInBasketPrefilled, addedQuantitiesRecord, handleAddToCart]);
    // ----HANDLE TABLE END---- //

    return (
        <>
            <InfiniteScrollGenericList
                list={productList.map((bundle) => ({ ...bundle, id: bundle.bundleId, ...bundle.items[0] }))}
                isLoading={isProductLoading}
                totalCount={productTotalCount}
                defaultFilters={DEFAULT_SHOP_BUNDLE_FILTERS}
                toolbarFiltersList={toolbarFiltersList}
                datagridColumns={isPublic ? PUBLIC_COLUMNS : COLUMNS}
                handleCellClick={isPublic ? handleCellClick : undefined}
                updateCheckField={updateCheckField}
                context={"WebshopProduct"}
                setActualRow={setActualRow}
                customSearchFunc={customSearch}
                resetList={() => { }}
            /*                 multipleView={{
                                renderGridElement: SingleGridElement,
                                                                 extraProps: {
                                                                    quantitiesRecord: quantitiesRecord,
                                                                    handleChangeQuantity: handleChangeQuantity,
                                                                    getQuantityInBasketPrefilled: getQuantityInBasketPrefilled
                                                                } 
                            }} */
            />

            <Typography sx={{ textAlign: 'right', mt: 3, mr: 1 }} fontSize={'13px'}>
                {`${translate('orders.messages.vatAdvice')}`}
            </Typography>
        </>
    );
}

//-----------------------------------------------------------------------------

function SingleGridElement({ key, item: bundle, translate, extraProps }: SingleGridElementProps<ShopBundleSearchResult>) {

    const { quantitiesRecord, handleChangeQuantity, getQuantityInBasketPrefilled } = extraProps;

    const [openCartMenu, setOpenCartMenu] = useState<HTMLElement | null>(null);

    const handleOpenCartMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCartMenu(event.currentTarget);
    };

    const handleCloseCartMenu = () => {
        setOpenCartMenu(null);
    };

    return (
        <Card
            key={key}
            sx={{
                p: 1,
                transition: "0.5s",
                cursor: 'pointer',
                width: "100%",
                minHeight: "350px",
                height: "35vh",
                maxHeight: "435px",
                ':hover': { bgcolor: (theme) => theme.palette.action.selected }
            }}
            onClick={() => {
                /*                 if (!window.getSelection()?.toString())
                                    navigate(PATH_DASHBOARD.webshop.detail(item.id)); */
            }}
        >

            <Box sx={{ height: "72%", display: "flex", alignItems: "center" }}>
                {bundle.items[0].media[0]?.fileInfo[0]?.url ?
                    <img
                        alt={"product"}
                        src={bundle.items[0].media[0].fileInfo[0].url}
                        //src={"/assets/stellantis-europe/008.webp"}
                        style={{
                            borderRadius: "15px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            border: "1px ridge",
                            aspectRatio: "1"
                        }}
                    />
                    :
                    <NoPhotographyIcon sx={{ width: '100%', height: '7.5vh', objectFit: 'contain' }} />
                }
                <IconButton
                    onClick={handleOpenCartMenu}
                    sx={{
                        position: "absolute",
                        p: 1.5,
                        backgroundColor: (theme) => theme.palette.warning.light,
                        color: (theme) => theme.palette.text.primary,
                        top: "64.5%",
                        right: "5%"
                    }}
                >
                    <AddShoppingCartIcon width={"24px"} height={"24px"} />
                </IconButton>

                <CartMenuComponent
                    handleCloseMenu={handleCloseCartMenu}
                    translate={translate}
                    openMenu={openCartMenu}
                    bundle={bundle}
                    quantitiesRecord={quantitiesRecord}
                    handleChangeQuantity={handleChangeQuantity}
                    getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
                />
            </Box>

            <Box sx={{ mt: 2, ml: 1, display: 'flex', flexDirection: "column", gap: 1.5, height: "auto" }}>
                <Typography variant={"h6"} sx={{ wordBreak: 'break-word' }}>
                    {bundle.items[0].name}
                </Typography>

                <Typography variant='overline' textOverflow={'ellipsis'} overflow={'hidden'} sx={{ mt: -1.5 }}>
                    <b>{"[" + bundle.items[0].family.supplier.code + "] - "}</b>{bundle.items[0].family.supplier.description}
                </Typography>

                <CurrencyAdapter
                    value={14.9009}
                    currency={"EUR" as CurrencyTypes}
                    variant={"subtitle1"}
                />
            </Box>

        </Card>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleCloseMenu: () => void,
    translate: (text: unknown, options?: any) => string,
    bundle: ShopBundleSearchResult,
    quantitiesRecord: Record<string, number>,
    handleChangeQuantity: (id: string, action: "increase" | "decrease") => void,
    getQuantityInBasketPrefilled: (bundleId: string) => number
};

function CartMenuComponent({ openMenu, handleCloseMenu, translate, bundle, quantitiesRecord, handleChangeQuantity, getQuantityInBasketPrefilled }: OptionsComponentProps) {

    const { bundleId, availability } = bundle;

    return (
        <MenuPopover
            open={openMenu}
            anchorEl={openMenu}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            arrow="right-top"
            sx={{
                mt: -1,
                minWidth: 160,
                '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                    '& svg': { mr: 2, width: 20, height: 20 },
                },
            }}
        >
            <AvailabilityIncrementer
                translate={translate}
                bundleId={bundleId}
                availability={availability}
                quantitiesRecord={quantitiesRecord}
                handleChangeQuantity={handleChangeQuantity}
                getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
            />
        </MenuPopover>
    );
}

//-----------------------------------------------------------------------------

interface ProgressIconProps {
    progress: number,
    themeMode: ThemeModeValue,
    score: number
}

export function ProgressIcon({ progress, themeMode, score }: ProgressIconProps) {

    const greyValue = themeMode === "dark" ? 700 : 400;

    return (
        <Box sx={{ display: "flex" }}>
            <SvgColor
                src={'/assets/icons/utils/ic_vertical_line.svg'}
                color={progress >= 20 ? undefined : (theme) => theme.palette.grey[greyValue]}
            />
            <SvgColor
                src={'/assets/icons/utils/ic_vertical_line.svg'}
                color={progress >= 40 ? undefined : (theme) => theme.palette.grey[greyValue]}
                sx={{ ml: -2 }}
            />
            <SvgColor
                src={'/assets/icons/utils/ic_vertical_line.svg'}
                color={progress >= 60 ? undefined : (theme) => theme.palette.grey[greyValue]}
                sx={{ mx: -2 }}
            />
            <SvgColor
                src={'/assets/icons/utils/ic_vertical_line.svg'}
                color={progress >= 80 ? undefined : (theme) => theme.palette.grey[greyValue]}
                sx={{ mr: -2 }}
            />
            <SvgColor
                src={'/assets/icons/utils/ic_vertical_line.svg'}
                color={progress >= 100 ? undefined : (theme) => theme.palette.grey[greyValue]}
            />

            <Typography variant={"body1"} sx={{ wordBreak: 'break-word' }}>
                {" - " + score}
            </Typography>
        </Box>
    );
}

//-----------------------------------------------------------------------------

interface AvailabilityIncrementerProps {
    translate: (text: unknown, options?: any) => string,
    bundleId: string,
    availability: ShopBundleAvailability,
    quantitiesRecord: Record<string, number>,
    handleChangeQuantity: (id: string, action: "increase" | "decrease") => void,
    getQuantityInBasketPrefilled: (bundleId: string) => number
}

function AvailabilityIncrementer({ translate, bundleId, availability, quantitiesRecord, handleChangeQuantity, getQuantityInBasketPrefilled }: AvailabilityIncrementerProps) {

    const { stockQuantity } = availability;

    const quantity: number = quantitiesRecord[bundleId] ?? 0;

    const quantityInCart: number = getQuantityInBasketPrefilled(bundleId);

    return (
        <Box sx={{ display: "grid", gridTemplateColumns: 'repeat(2, 1fr)', alignItems: "center" }}>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant={"caption"}>
                    {translate(`statuses.available`) + ": " + stockQuantity}
                </Typography>
                {quantityInCart > 0 &&
                    <Typography variant={'caption'}>
                        {"(" + translate(`webshop.detail.products.inCart`) + ": " + quantityInCart + ")"}
                    </Typography>
                }
            </Box>

            <IncrementerButton
                quantity={quantity}
                disabledIncrease={quantity === stockQuantity || stockQuantity === 0}
                disabledDecrease={quantity === 0 || stockQuantity === 0}
                onIncrease={() => handleChangeQuantity(bundleId, 'increase')}
                onDecrease={() => handleChangeQuantity(bundleId, 'decrease')}
                sx={{ py: 1.5, height: "50%", my: 0 }}
            />

        </Box>
    );
}