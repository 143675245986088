import { Box, Button, Card, Container, Divider, MenuItem, TextField } from "@mui/material";
import { useReducer } from "react";
import { useNavigate, useParams } from "react-router";
import { DEFAULT_NEW_TERM_AND_CONDITIONS, NewTermCondition, TermsConditionEntityAvailable, TermsConditionEntityAvailableArr } from "src/@types/termCondition";
import { RichLocalizedInput } from "src/appComponents/LocalizedInput";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch } from "src/redux/store";
import { termConditionOperations } from "src/redux/termCondition";
import { PATH_DASHBOARD } from "src/routes/paths";

type Action = {
    payload: Partial<NewTermCondition>,
    type: 'update' | 'reset'
};

function reduce(state: NewTermCondition, action: Action) {

    switch (action.type) {
        case "update":
            return { ...state, ...action.payload };

        case "reset":
            return { ...DEFAULT_NEW_TERM_AND_CONDITIONS, ...action.payload };

        default:
            return state;
    }
}

interface NewEditTermsConditionProps {
    terms: NewTermCondition | null
}

export default function TermsConditionForm({ terms }: NewEditTermsConditionProps) {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const [state, setState] = useReducer(reduce, terms ?? DEFAULT_NEW_TERM_AND_CONDITIONS);

    const { id } = useParams();

    const navigate = useNavigate();

    const handleSave = async () => {
        if (id)
            await dispatch(termConditionOperations.updateTermCondition({ id, newTermCondition: state })).unwrap();
        else
            await dispatch(termConditionOperations.createTermCondition(state)).unwrap();

        dispatch(setSuccessMessage({ text: translate("termsCondition.messages.successMessage"), returnTo: PATH_DASHBOARD.termsCondition.list }));
    };

    return (
        <Container maxWidth={themeStretch ? false : 'lg'}>

            <HeaderBreadcrumbs
                heading={`${translate(`menu.management.termsCondition.${id ? 'update' : 'new'}`)}`}
                links={[
                    { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                    { name: `${translate(`termsCondition.title_short`)}`, href: PATH_DASHBOARD.termsCondition.list },
                    { name: id ? translate(`commons.edit`) : `${translate(`commons.new`)}` },
                ]}
            />
            <Card sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', gap: 3, width: '100%' }}>
                    <TextField
                        label={translate('commons.name')}
                        sx={{ width: '75%', '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                        required
                        value={state.name}
                        onChange={(e) =>
                            setState({ payload: { name: e.target.value }, type: 'update' })
                        }
                    />

                    <TextField
                        label={translate('commons.type')}
                        sx={{ width: '50%' }}
                        select
                        value={state.entityType}
                        onChange={(e) =>
                            setState({ payload: { entityType: e.target.value as TermsConditionEntityAvailable }, type: 'update' })
                        }
                    >
                        {TermsConditionEntityAvailableArr.map((type) =>
                            <MenuItem key={type} value={type}>
                                {translate(`termsCondition.form.type.${type}`)}
                            </MenuItem>
                        )}

                    </TextField>
                </Box>
                <Divider sx={{ mt: 2 }} />

                <RichLocalizedInput defaultTabs={state.text} onChangeTabs={(text) => setState({ payload: { text }, type: 'update' })} />

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>

                    <Button variant='soft' color="inherit" onClick={() => navigate(PATH_DASHBOARD.termsCondition.list)}>
                        {`${translate("commons.cancel")}`}
                    </Button>

                    <Button
                        variant="contained"
                        disabled={!state.name || !state.text.length}
                        onClick={() => handleSave()}
                    >

                        {!id ? `${translate("termsCondition.form.new")}` : `${translate("commons.justSave")}`}

                    </Button>

                </Box>
            </Card>
        </Container>
    );
}