import { Box, Button, Card, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material";
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { Dispatch, SetStateAction } from "react";
import { useLocales } from "src/locales";
import SaveIcon from '@mui/icons-material/Save';
import BoxSwitch from "src/components/custom-input/BoxSwitch";
import { RichLocalizedInput } from "src/appComponents/LocalizedInput";
import { NewShop, RichLocalizedString } from "src/@types/webshop";
import { FormikProps, useFormik } from "formik";
import * as Yup from 'yup';
import ImageUploaderEditor from "src/components/upload/ImageUploaderEditor";
import { NumericFormat } from "react-number-format";
import AvatarUploaderEditor from "src/components/upload/AvatarUploaderEditor";
import { FileWithPreview, ImageInModalType } from "src/@types/media";

const schema = Yup.object().shape({
    title: Yup.string().trim().required("commons.validation.requiredField"),
    startDate: Yup.string().required("commons.validation.requiredField").test("date", "webshop.messages.startDateError", (val, context) => {

        if (!context.parent.previewDate)
            return true;

        let preview = new Date(context.parent.previewDate);
        let start = new Date(val);

        return start >= preview;
    }),
    endDate: Yup.string().when('permanent', {
        is: false,
        then: (schema) => schema.required("commons.validation.requiredField").test("endDate", "webshop.messages.endDateError", (val, context) => {
            let end = new Date(val);
            let start = new Date(context.parent.startDate);

            return end > start;

        })
    }),
    shortDescription: Yup.array().test("array", "commons.validation.requiredField", (val: RichLocalizedString[] | undefined) => {
        if (!val?.length)
            return false;

        if (val.some(v => !v.normalText || !v.normalText.length))
            return false;

        return true;
    }),
    longDescription: Yup.array().test("array", "commons.validation.requiredField", (val: RichLocalizedString[] | undefined) => {
        if (!val?.length)
            return false;

        if (val.some(v => !v.normalText || !v.normalText.length))
            return false;

        return true;
    })
});

interface DetailStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    state: NewShop,
    onSubmit: Dispatch<{
        value: Partial<NewShop>
        type: 'update' | 'reset'
    }>;
    profileCover: (ImageInModalType & File)[],
    setProfileCover: (f: (ImageInModalType & File)[]) => void,
    profilePreview: FileWithPreview[],
    setProfilePreview: (f: FileWithPreview[]) => void,
    carousel: (ImageInModalType & File)[],
    setCarousel: (f: (ImageInModalType & File)[]) => void,
    carouselPreview: FileWithPreview[],
    setCarouselPreview: (f: FileWithPreview[]) => void
}

export default function DetailStep({ changeStep, onSubmit, state, setCarousel, setProfileCover, carousel, profileCover, profilePreview, setProfilePreview, carouselPreview, setCarouselPreview }: DetailStepProps) {

    const { translate } = useLocales();

    const formik = useFormik({
        initialValues: state,
        validationSchema: schema,
        validateOnMount: true,
        onSubmit: (value) => {
            onSubmit({ value, type: "update" });
        }
    });

    return (
        <Grid container gap={5}>

            <Grid item lg={8}>
                <Grid container>
                    <Grid item gap={3} lg={12}>

                        <Details
                            formik={formik}
                            carousel={carousel}
                            setCarousel={setCarousel}
                            carouselPreview={carouselPreview}
                            setCarouselPreview={setCarouselPreview}
                        />

                        <Duration formik={formik} />

                        <Card sx={{ p: 3, mt: 3, width: '100%' }}>
                            <BoxSwitch
                                label={translate('webshop.form.allowBackorder')}
                                value={formik.values.backorderAllowed}
                                onChange={(v) => {
                                    formik.setFieldValue('backorderAllowed', v);
                                    if (!v)
                                        formik.setFieldValue("maxBackorderQuantity", null);
                                }}
                            />
                            {
                                formik.values.backorderAllowed &&
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%', pt: 2 }}>
                                    <NumericFormat
                                        label={translate('webshop.form.maxBackorderQty')}
                                        customInput={TextField}
                                        name={"maxBackorderQuantity"}
                                        value={formik.values.maxBackorderQuantity ?? ""}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        placeholder={translate('basketRule.form.indefinite')}
                                        onValueChange={(e) => {
                                            formik.setFieldValue("maxBackorderQuantity", Number(e.floatValue));
                                        }}
                                        onBlur={(e) => {
                                            if (!formik.values.maxBackorderQuantity) formik.setFieldValue("maxBackorderQuantity", null);
                                            formik.handleBlur(e);
                                        }}

                                        sx={{
                                            width: "100%",
                                            '& .MuiFormHelperText-root.Mui-error': { ml: 0.5, mt: 8, position: "absolute", width: "200px" },
                                        }}
                                    />
                                    <Typography variant="body2" sx={{ width: '100%', color: theme => theme.palette.text.secondary }}>
                                        {translate('webshop.messages.backorderMsg')}
                                    </Typography>
                                </Box>
                            }
                        </Card>

                        <Card sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
                            <Typography variant="h6">
                                {translate("webshop.form.internalNote")} ({translate('commons.optional')})
                            </Typography>
                            <Box sx={{ width: '100%' }}>

                                <Typography variant="subtitle2" color="GrayText" sx={{ mb: 1 }}>{translate("webshop.messages.internalSubtitle")}</Typography>
                                <RichLocalizedInput
                                    sx={{ px: 2, minHeight: '10vh' }}
                                    defaultTabs={formik.values.internalNote ?? []}
                                    onChangeTabs={(tabs) => formik.setFieldValue('internalNote', tabs, true)}
                                />
                            </Box>
                        </Card>

                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Box sx={{ position: 'sticky', top: 70 }}>
                    <Card sx={{ width: '100%', p: 3, textAlign: 'center' }} elevation={15}>

                        <AvatarUploaderEditor
                            onChangeImage={setProfileCover}
                            defaultImage={profileCover}
                            onChangePreview={setProfilePreview}
                            defaultPreview={profilePreview}
                        />

                        <FormControlLabel
                            labelPlacement="start"
                            label={translate("webshop.form.prioritize")}
                            value={formik.values.featured}
                            name="featured"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            control={<Switch />}
                        />
                    </Card>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <Button variant="outlined" sx={{ borderRadius: '100px' }} startIcon={<SaveIcon />}>
                            {translate("webshop.form.saveDraft")}
                        </Button>
                        <Button variant="contained" sx={{ borderRadius: '100px' }}
                            disabled={!formik.isValid}
                            onClick={() => {
                                formik.handleSubmit();
                                changeStep(prev => prev + 1);
                            }}>
                            {translate('commons.next')}
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

interface DetailsProps {
    formik: FormikProps<NewShop>,
    carousel: (ImageInModalType & File)[]
    setCarousel: (f: (ImageInModalType & File)[]) => void,
    carouselPreview: FileWithPreview[],
    setCarouselPreview: (f: FileWithPreview[]) => void
}

function Details({ formik, setCarousel, carousel, carouselPreview, setCarouselPreview }: DetailsProps) {

    const { translate } = useLocales();

    return (
        <Card sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h6">
                {translate("commons.detail")}
            </Typography>
            <TextField
                label={translate("webshop.form.title")}
                name="title"
                value={formik.values.title}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && !!formik.errors.title}
                helperText={(formik.touched.title && !!formik.errors.title) && translate(formik.errors.title)}
                sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
            />
            <Box>
                <Typography sx={{ mb: 1 }} variant="subtitle2" color={"GrayText"}>{translate("webshop.form.shortDesc")} <span style={{ color: 'red' }}>*</span></Typography>
                <RichLocalizedInput
                    error={formik.touched.shortDescription && !!formik.errors.shortDescription}
                    helperText={translate(formik.errors.shortDescription?.toString())}
                    sx={{ px: 2, minHeight: '10vh' }}
                    defaultTabs={formik.values.shortDescription}
                    onChangeTabs={(tabs) => {

                        formik.setFieldValue('shortDescription', tabs, true).then(() => {
                            if (!formik.touched.shortDescription)
                                formik.setFieldTouched("shortDescription", true);
                        });
                    }}
                />
            </Box>
            {/* <TextField label="short description" multiline rows={2} /> */}
            <Box>
                <Typography sx={{ mb: 1 }} variant="subtitle2" color={"GrayText"}>{translate("webshop.form.longDesc")} <span style={{ color: 'red' }}>*</span></Typography>
                <RichLocalizedInput sx={{ px: 2, minHeight: '20vh' }}
                    defaultTabs={formik.values.longDescription}
                    error={formik.touched.longDescription && !!formik.errors.longDescription}
                    helperText={translate(formik.errors.longDescription?.toString())}
                    onChangeTabs={(tabs) => {
                        formik.setFieldValue('longDescription', tabs, true).then(() => {
                            if (!formik.touched.longDescription)
                                formik.setFieldTouched("longDescription", true);
                        });
                    }}
                />
            </Box>

            <Box>
                <Typography sx={{ mb: 1 }} variant="subtitle2" color={"GrayText"}>{translate("webshop.form.coverPhoto")}</Typography>

                <ImageUploaderEditor
                    defaultImages={carousel}
                    onChangeImages={setCarousel}
                    defaultPreviews={carouselPreview}
                    onChangePreviews={setCarouselPreview}
                />

            </Box>
        </Card>
    );
}

interface DurationProps {
    formik: FormikProps<NewShop>
}

function Duration({ formik }: DurationProps) {

    const { translate } = useLocales();

    return (
        <Card sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <Typography variant="h6">{translate("webshop.form.duration")}</Typography>
            <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">{translate("webshop.form.preview")}</Typography>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
                    <DateTimePicker

                        value={formik.values.previewDate ? new Date(formik.values.previewDate) : null}
                        name="previewDate"
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                        }}
                        onAccept={(val) => {
                            let date = "";
                            if (val)
                                date = val.toISOString();

                            formik.setFieldValue('previewDate', date, true);
                        }}
                        onChange={(fromDateValue, inputval) => {

                            if (!inputval.validationError && fromDateValue) {

                                const date = fromDateValue.toISOString();

                                formik.setFieldValue('previewDate', date, true);

                            }
                        }}
                        label={translate("webshop.form.startDate")}
                        sx={{ mt: 2, width: '100%' }}

                    />
                </Box>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle1">{translate("webshop.form.publishing")} <span style={{ color: 'red' }}>*</span></Typography>
                    <FormControlLabel
                        label={`${translate('permanent')}`}
                        name={translate("webshop.form.permanent")}
                        onChange={(e, checked) => {
                            formik.handleChange(e);
                            if (checked)
                                formik.setFieldValue('endDate', "", true);

                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.permanent}
                        control={<Switch />}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 3, mt: 2 }}>
                    <DateTimePicker
                        label={translate("webshop.form.startDate")}
                        value={formik.values.startDate ? new Date(formik.values.startDate) : null}
                        name="startDate"
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                        }}
                        slotProps={{
                            textField: {
                                error: formik.touched.startDate && !!formik.errors.startDate,
                                helperText: formik.touched.startDate && translate(formik.errors.startDate ?? ""),
                            },
                        }}
                        onAccept={(val) => {
                            let date = "";
                            if (val)
                                date = val.toISOString();

                            formik.setFieldValue('startDate', date, true).then(() => {
                                if (!formik.touched.startDate)
                                    formik.setFieldTouched("startDate", true);
                            });
                        }}
                        onChange={(fromDateValue, inputval) => {

                            if (!inputval.validationError && fromDateValue) {

                                const date = fromDateValue.toISOString();

                                formik.setFieldValue('startDate', date, true).then(() => {
                                    if (!formik.touched.startDate)
                                        formik.setFieldTouched("startDate", true);
                                });

                            }
                        }}
                        sx={{ width: '100%' }}
                    />
                    <DateTimePicker
                        label={translate("webshop.form.endDate")}
                        sx={{ width: '100%' }}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                        }}
                        slotProps={{
                            textField: {
                                error: formik.touched.endDate && !!formik.errors.endDate,
                                helperText: formik.touched.endDate && translate(formik.errors.endDate ?? ""),
                            },
                        }}
                        disablePast
                        disabled={formik.values.permanent}
                        value={formik.values.endDate ? new Date(formik.values.endDate) : null}
                        name="endDate"
                        onAccept={(val) => {
                            let date = "";
                            if (val)
                                date = val.toISOString();

                            formik.setFieldValue('endDate', date, true).then(() => {
                                if (!formik.touched.endDate)
                                    formik.setFieldTouched("endDate", true);
                            });
                        }}
                        onChange={(fromDateValue, inputval) => {

                            if (!inputval.validationError && fromDateValue) {

                                const date = fromDateValue.toISOString();

                                formik.setFieldValue('endDate', date, true).then(() => {
                                    if (!formik.touched.endDate)
                                        formik.setFieldTouched("endDate", true);
                                });

                            }
                        }}
                    />
                </Box>
            </Box>
        </Card>
    );
}

