import { Delete, NoPhotography } from "@mui/icons-material";
import { IconButton, Typography, Box } from "@mui/material";
import { GridColDef, DataGridPro } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { BasketItem, BasketParams, BasketShop } from "src/@types/basket";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { getPrice } from "src/utils/currency";
import { getLocalizedString } from "src/utils/CustomFieldManagment";
import { DataGridStyle } from "src/utils/DataGridStyle";
import RemoveProductCartModal from "./RemoveProductCartModal";
import { noData } from "src/components/empty-content/EmptyContent";

interface CartShopInfoProps {
    shop?: BasketShop
};

export default function CartShopInfo({ shop }: CartShopInfoProps) {

    const isDesktop = useResponsive('up', 'lg');

    const { translate, currentLang } = useLocales();

    const [removeParams, setRemoveParams] = useState<BasketParams>();

    const [open, setOpen] = useState(false);

    const COLUMNS: GridColDef<BasketItem>[] = [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'flex', alignItems: "center" }}>

                    {obj.row.media[0] ?
                        <img
                            alt={""}
                            src={obj.row.media[0]?.fileInfo[0]?.url ?? ""}
                            style={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                aspectRatio: "1",
                                objectFit: "cover",
                                border: "1px ridge"
                            }}
                        />
                        :
                        <Box
                            sx={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                border: "1px ridge",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: (theme) => theme.palette.divider
                            }}
                        >
                            <NoPhotography sx={{ width: "30px", height: "30px" }} />
                        </Box>

                    }

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                        <Typography variant='subtitle2' sx={{ mb: -0.5 }}>
                            {getLocalizedString(obj.row.name, currentLang)}
                        </Typography>
                        {obj.row.family &&
                            <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                                <b>{"[" + obj.row.family?.supplier?.code + "] - "}</b>{obj.row.family?.supplier?.description}
                            </Typography>
                        }
                    </Box>
                </Box>
        },
        {
            field: 'unitGrossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.unitGrossAmount)}
                        currency={obj.row.unitGrossAmount.currentCurrency}

                        fontSize={'0.875rem'}
                    />
                );
            }
        },

        {
            field: 'quantity',
            headerName: translate('commons.quantity'),
            flex: 0.5
        },
        {
            field: 'discountAmount',
            headerName: translate('commons.discountAmount'),
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 250 : undefined,

            renderCell: (obj) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                        <CurrencyAdapter
                            value={"-" + getPrice(obj.row.discountAmount)}
                            currency={obj.row.discountAmount.currentCurrency}

                            fontSize={'0.875rem'}
                        />
                        <Typography variant="subtitle2" color={'text.secondary'}>-{getPrice(obj.row.discountPercentage as any)}%</Typography>
                    </Box>
                );
            }
        },
        {
            field: 'netAmount',
            headerName: translate('webshop.detail.products.netAmount'),
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 250 : undefined,

            renderCell: (obj) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                        <CurrencyAdapter
                            value={getPrice(obj.row.netAmount)}
                            currency={obj.row.netAmount.currentCurrency}

                            fontSize={'0.875rem'}
                        />
                        <CurrencyAdapter
                            value={getPrice(obj.row.grossAmount)}
                            currency={obj.row.grossAmount.currentCurrency}
                            color={'text.secondary'}
                            sx={{ textDecoration: 'line-through' }}
                            fontSize={'0.875rem'}
                        />
                    </Box>
                );
            }
        },
        {
            field: "actions",
            align: "center",
            headerName: "",
            flex: 0.25,
            renderCell: (obj) =>
                <IconButton onClick={() => {

                    setRemoveParams({
                        bundleId: obj.row.id,
                        shopId: shop?.shopId ?? "",
                        quantity: obj.row.quantity
                    });

                    setOpen(true);
                }}>
                    <Delete />
                </IconButton>
        }
    ];

    return (
        <Box sx={{ p: 2 }}>
            <RemoveProductCartModal open={open} onClose={() => setOpen(false)} removeParams={removeParams} />
            <Box>
                <Typography variant="h6">{shop?.shopName}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>{shop?.price.quantity} {translate('basket.detail.item_plural')}</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
                <DataGridPro
                    rows={shop?.items ?? []}
                    columns={COLUMNS}
                    pagination={false}
                    sx={{ ...DataGridStyle, maxWidth: !isDesktop ? 500 : '100%', overflowX: 'auto' }}
                    hideFooterRowCount
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnSorting
                    disableColumnMenu
                    slots={{
                        noRowsOverlay: noData,
                        noResultsOverlay: noData,
                    }}
                />
            </Box>
            <Box sx={{ px: 1, display: 'flex', gap: 1, flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="body2">{translate('basket.detail.shopGrossAmount')}</Typography>
                    {shop && <CurrencyAdapter
                        value={getPrice(shop?.price.grossAmount)}
                        currency={shop?.price.grossAmount.currentCurrency}

                        fontSize={'0.875rem'}
                    />}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="body2">{translate('basket.detail.shopRegularDiscount')}</Typography>
                    {shop && <CurrencyAdapter
                        value={getPrice(shop?.price.regularDiscountAmount)}
                        currency={shop?.price.regularDiscountAmount.currentCurrency}

                        fontSize={'0.875rem'}
                    />}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="body2">{translate('basket.detail.shopAdditionalDiscount')}</Typography>
                    {shop && <CurrencyAdapter
                        value={getPrice(shop?.price.additionalDiscountAmount)}
                        currency={shop?.price.additionalDiscountAmount.currentCurrency}

                        fontSize={'0.875rem'}
                    />}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="subtitle1">{translate('basket.detail.totalShop')}</Typography>
                    {shop && <CurrencyAdapter
                        value={getPrice(shop?.price.netAmount)}
                        currency={shop?.price.netAmount.currentCurrency}

                        fontSize={'0.875rem'}
                    />}
                </Box>
            </Box>
        </Box>
    );
}

