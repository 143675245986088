import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Vehicle } from "src/@types/vehicle";
import Carousel, { CarouselArrowIndex } from "src/components/carousel";
import VideoPlayer from "./VideoPlayer";
import Image from '../../../../appComponents/image/index';
import { Media } from "src/@types/request";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const THUMB_SIZE = 64;

type StyledThumbnailsContainerProps = {
  length: number;
};

const StyledThumbnailsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'length',
})<StyledThumbnailsContainerProps>(({ length, theme }) => ({
  margin: '0 auto',
  position: 'relative',

  '& .slick-slide': {
    opacity: 0.48,
    '&.slick-current': {
      opacity: 1,
    },
    '& > div': {
      padding: '5px',
    },
  },

  ...(length === 1 && {
    maxWidth: THUMB_SIZE * 1 + 16,
  }),
  ...(length === 2 && {
    maxWidth: THUMB_SIZE * 2 + 32,
  }),
  ...((length === 3 || length === 4) && {
    maxWidth: THUMB_SIZE * 3 + 48,
  }),
  ...(length >= 5 && {
    maxWidth: THUMB_SIZE * 6,
  }),
  ...(length > 2 && {
    '&:before, &:after': {

      top: 0,
      zIndex: 9,
      content: "''",
      height: '100%',
      position: 'absolute',
      width: (THUMB_SIZE * 2) / 3,
    },
    '&:after': {
      right: 0,
      transform: 'scaleX(-1)',
    },
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  vehicle: Vehicle;
};

export default function ProductDetailsCarousel({ vehicle }: Props) {
  // const theme = useTheme();

  const carousel1 = useRef<Carousel | null>(null);

  const carousel2 = useRef<Carousel | null>(null);

  const [nav1, setNav1] = useState<Carousel>();

  const [nav2, setNav2] = useState<Carousel>();

  const [currentIndex, setCurrentIndex] = useState(0);

  const carouselSettings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const carouselSettings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: vehicle.media.images.length > 3 ? 3 : vehicle.media.images.length,
  };

  useEffect(() => {
    if (carousel1.current) {
      setNav1(carousel1.current);
    }
    if (carousel2.current) {
      setNav2(carousel2.current);
    }
  }, []);

  useEffect(() => {
    carousel1.current?.slickGoTo(currentIndex);
  }, [currentIndex]);

  const handlePrev = () => {
    carousel2.current?.slickPrev();
  };

  const handleNext = () => {
    carousel2.current?.slickNext();
  };

  const renderLargeImg = (
    <Box sx={{ mb: 3, borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
      <Carousel {...carouselSettings1} asNavFor={nav2} ref={carousel1}>
        {vehicle.media.images.map((img: any) => (
          <Image
            key={img.large}
            alt="product"
            src={img.large}
            ratio="1/1"
          />
        ))}
        {vehicle.media.video.map((video: any) => (
          <VideoPlayer
            key={video.large}
            src={video.mediaUrl}
            sx={{
              height: '100%'
            }} />
        ))}
      </Carousel>

      <CarouselArrowIndex
        index={currentIndex}
        total={vehicle.media.images.length + vehicle.media.video.length}
        onNext={handleNext}
        onPrevious={handlePrev}
      />
    </Box>
  );

  const renderThumbnails = (
    <StyledThumbnailsContainer length={vehicle.media.images.length}>
      <Carousel {...carouselSettings2} asNavFor={nav1} ref={carousel2}>
        {vehicle.media.images.concat(vehicle.media.video).map((img: any, index: number) => (
          <Image
            key={img.thumb}
            disabledEffect
            alt="thumbnail"
            src={img.thumb}
            sx={{
              width: THUMB_SIZE,
              height: THUMB_SIZE,
              objectFit: 'contain',
              borderRadius: 1.5,
              cursor: 'pointer',
              ...(currentIndex === index && {
                border: `solid 2px`,
              }),
            }}
          />
        ))}
      </Carousel>
    </StyledThumbnailsContainer>
  );

  return (
    <>
      <Box
        sx={{
          '& .slick-slide': {
            float: 'left',
          },
        }}
      >
        {renderLargeImg}
        {renderThumbnails}
      </Box>
    </>
  );
}

type GeneralDetailsCarouselProps = {
  media: Media[];
};

export function GeneralDetailsCarousel({ media }: GeneralDetailsCarouselProps) {
  // const theme = useTheme();

  const carousel1 = useRef<Carousel | null>(null);

  const carousel2 = useRef<Carousel | null>(null);

  const [nav1, setNav1] = useState<Carousel>();

  const [nav2, setNav2] = useState<Carousel>();

  const [currentIndex, setCurrentIndex] = useState(0);

  const carouselSettings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: false,
    centerPadding: '0px',

    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const carouselSettings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: media.length > 3 ? 3 : media.length,
  };

  useEffect(() => {
    if (carousel1.current) {
      setNav1(carousel1.current);
    }
    if (carousel2.current) {
      setNav2(carousel2.current);
    }
  }, []);

  useEffect(() => {
    carousel1.current?.slickGoTo(currentIndex);
  }, [currentIndex]);

  const handlePrev = () => {
    carousel2.current?.slickPrev();
  };

  const handleNext = () => {
    carousel2.current?.slickNext();
  };

  const renderLargeImg = (
    <Box sx={{ mb: 3, borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
      <Carousel {...carouselSettings1} asNavFor={nav2} ref={carousel1}  >
        {media.map((img) => (
          <>
            <img
              key={img.fileInfo[0].url}
              alt="product"
              src={img.fileInfo[0].url}
              style={{
                height: '35vh',
                objectFit: 'contain',
                width: '100%'
              }}
            />
          </>
        ))}
      </Carousel>

      <CarouselArrowIndex
        index={currentIndex}
        total={media.length}
        onNext={handleNext}
        onPrevious={handlePrev}
      />
    </Box>
  );

  const renderThumbnails = (
    <StyledThumbnailsContainer length={media.length}>
      <Carousel {...carouselSettings2} asNavFor={nav1} ref={carousel2}>
        {media.map((img, index: number) => (
          <Image
            key={img.fileInfo[0].url}
            disabledEffect
            alt="thumbnail"
            src={img.fileInfo[0].url}
            sx={{
              width: THUMB_SIZE,
              height: THUMB_SIZE,
              objectFit: 'contain',
              borderRadius: 1.5,
              cursor: 'pointer',
              ...(currentIndex === index && {
                border: `solid 2px`,
              }),
            }}
          />
        ))}
      </Carousel>
    </StyledThumbnailsContainer>
  );

  return (
    <>
      <Box
        sx={{
          '& .slick-slide': {
            float: 'left',
          },
        }}
      >
        {renderLargeImg}
        {renderThumbnails}
      </Box>
    </>
  );
}
