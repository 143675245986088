import axios, { AxiosResponse } from "axios";
import { Shop } from "./paths";
import { InfiniteScrollPagedResponse } from "src/@types/commons";
import { DiscountMatrixFilters, DiscountMatrixRowsFilters, DiscountMatrixRowsSearchResult, DiscountMatrixSearchResult } from "src/@types/discountMatrix";

const searchDiscountMatrixes = (options: DiscountMatrixFilters): Promise<AxiosResponse<InfiniteScrollPagedResponse<DiscountMatrixSearchResult>>> => {
    const url = Shop.admin.discountMatrix.list();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchDiscountMatrixRows = (options: DiscountMatrixRowsFilters, id: string): Promise<AxiosResponse<InfiniteScrollPagedResponse<DiscountMatrixRowsSearchResult>>> => {
    const url = Shop.admin.discountMatrix.detailRows(id);

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const enable = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.discountMatrix.enable(id);

    return axios.patch(url);

};

const disable = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.discountMatrix.disable(id);

    return axios.patch(url);

};

export {
    searchDiscountMatrixes,
    searchDiscountMatrixRows,
    enable,
    disable
};