import * as signalR from "@microsoft/signalr";
import axios from "axios";
import { Notification } from 'src/services/paths';

const tenantId = process.env.REACT_APP_CLIENT_TENANT_ID as string;

class Connector {

    private connection: signalR.HubConnection;

    public constructor(URL: string, token: Promise<string>) {

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL + `?tenant=${encodeURIComponent(tenantId)}`, {

                accessTokenFactory: () => token,
                transport: signalR.HttpTransportType.WebSockets,

            })
            .withAutomaticReconnect()
            .build();

        this.connection.start().catch((err) => console.error(err));
    }

    public setEvents(target: string, newHandler: ((payload: any) => void)) {
        //this "off" is necessary to prevent the same event from being loaded multiple times in particular conditions
        this.connection.off(target);
        this.connection.on(target, newHandler);
    }
}

export async function socketToken(userId: string): Promise<string> {

    const { data } = await axios.get(Notification.public.sockets.socketToken(userId));

    const token = userId + ":" + data.socketToken;

    //base64 encoding
    return btoa(token);
}

export default Connector;