import { DeleteForever } from "@mui/icons-material";
import { Box, Button, Card, Divider, Modal, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import { Media, Request } from "src/@types/request";
import { CurrencyTypes } from "src/@types/vehicle";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import MediaList from "src/components/mediaList/MediaList";
import MediaSlider from "src/components/mediaSlider/MediaSlider";
import ApprovedProductsDetails from "src/components/request-utils/ApprovedProductsDetails";
import DeliverToFrom from "src/components/request-utils/DeliverToFrom";
import MoreDetails from "src/components/request-utils/MoreDetails";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { brandOperations } from "src/redux/brand";
import { RootState, dispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import { hasPermissions } from "src/utils/user";
import ReturnLabel from "../request/detail/ReturnLabel";
import { useReactToPrint } from "react-to-print";
import { DocData } from "src/@types/orders";
import { createReturnLabelName } from "../request/detail/CustomerDeliveryDetail";

interface BacklogDeliveryDetailProps {
    request: Request,
    setConfirmed: (val: boolean) => void,
    setRefused: (val: boolean) => void,
    handlePickup: VoidFunction,
    handlePrint: VoidFunction,
    handleBackPrint: VoidFunction,
    handleAmend: VoidFunction,
    handlePaid: VoidFunction,
    handleLostClose: (lost?: boolean) => void,
    carrier: boolean,
    isAr?: boolean,
    documents?: DocData[],
    handleClaim?: VoidFunction,
    handleSubmit: (
        note: string,
        customFields: Record<string, string>,
        fees: {
            repairFee: number,
            returnFee: number,
            surveillanceFee: number,
            invoiceDataFee: number
        }) => void
}

const statusesForLost = ["ReadyForPickUp", "PickedUp", "Accepted", "WaitingForInvestigation", "RefusedByWarehouse", "RefusedReadyForPickUp", "RefusedPickedUp"];

export default function BacklogDeliveryDetail({ request, isAr, handleSubmit, setConfirmed, setRefused, handlePickup, carrier, handleBackPrint, handlePrint, handleAmend, documents, handlePaid, handleLostClose, handleClaim }: BacklogDeliveryDetailProps) {

    const { translate } = useLocales();

    const { organization } = useUserOrganizationContext();

    const { publicBrandList } = useSelector((state: RootState) => state.brand);

    const isDesktop = useResponsive("up", "sm");

    useEffect(() => {
        if (!publicBrandList) dispatch(brandOperations.getBrandList(false));
    }, [publicBrandList]);

    const isMediaImage = useMemo(() => request.hasMedia && request.requestType !== "Warranty", [request]);

    const componentRef = useRef(null);

    const [printed, setPrinted] = useState(false);

    const docList = useMemo(() => {
        if (documents) {

            let items = request.items.map(i => ({ code: i.product.externalId, order: i.orderId })).concat(request.exceededItems.map(i => ({ code: i.product.externalId, order: i.order?.id ? i.order.id : "" })));

            let docs = [];

            docs = items.map(v => {
                let doc = documents.find(d => d.partExternalId === v.code);

                return {
                    orderId: v.order || "",
                    invoiceExternalId: doc?.documentExternalId || "",
                    deliveryNoteExternalId: doc?.documentExternalId || ""
                };

            });

            return docs;
        }

        return [];

    }, [documents, request]);

    const originalDocName: string = document.title;

    const Print = useReactToPrint({
        contentRef: componentRef,
        documentTitle: createReturnLabelName(request),
        onBeforePrint: async () => {
            document.title = createReturnLabelName(request);
        },
        onAfterPrint: () => {
            if (!printed) {
                handlePrint();
                setPrinted(true);
            }
            document.title = originalDocName;
        }
    });

    const getFileLabel = useCallback((label: string) => {
        switch (label) {
            case "firstInvoice": return `${translate('request.form.firstInvoice')}`;
            case "secondInvoice": return `${translate('request.form.secondInvoice')}`;
            case "vehicleInfo": return `${translate('request.form.vehicleInfo')}`;
            case "firstRepairInvoice": return `${translate('request.form.invoiceRepair')}`;
            case "secondRepairInvoice": return `${translate('request.form.invoiceSecondRepair')}`;
            case "maddDoc": return `${translate('request.detail.maddLabel')}`;
        }

        return label;
    }, [translate]);

    const getBrandName = useCallback((brandId: string) => {
        if (publicBrandList) return publicBrandList.find((brand) => brand.id === brandId)?.name;

        return brandId;
    }, [publicBrandList]);

    const mediaFromFile = useMemo(() => {

        if (request.media && request.warranty) {
            return {
                media: request.media.map((file, index) => {
                    return {
                        section: getFileLabel(file.section || ""),
                        mediaType: file.mediaType,
                        fileInfo: file.fileInfo,
                        uploadDateInfo: isAr ? request.warranty?.invoices[index]?.date || "" : "",
                        originalFileName: file.originalFileName ?? (file.section || "")
                    } as Media;
                }),
                mediaData: request.media.map((file, index) => index === 2 ? (getBrandName(request.warranty!.vehicle!.brand) + " " + request.warranty!.vehicle!.model) : request.warranty?.invoices[(index > 2 ? index - 1 : index)]?.number || "")
            };
        }

        return null;

    }, [request, getFileLabel, getBrandName, isAr]);

    const showApprovedItems = useMemo(() => {

        const differentQuantities = !!(request.items.find((item) => item.approvedQuantity !== item.requiredQuantity));

        return request.approverNotes && request.items.length > 0 && differentQuantities;
    }, [request]);

    return (
        <Box>
            <Box sx={{ display: 'none' }}>
                <div ref={componentRef} style={{ paddingTop: '20px' }} >
                    <style type="text/css" media="print">{"@page { size: landscape; }"}</style>
                    <ReturnLabel request={request} docExternalId={docList} returnToCustomer />
                </div>
            </Box>

            {(hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation], organization.roles)) &&
                <Card sx={{ p: 2, mt: 2 }}>

                    {request.note && request.requestType !== "NewPart" &&
                        <>
                            {request.requestReason === "MissingParts" &&

                                <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.detail.wrongPart')}`}</Typography>}

                            {request.requestReason === "OriginalPackagingContainsWrongParts" &&
                                <>
                                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.messages.wrongPartAndDamage')}`}</Typography>
                                    <Typography sx={{ px: 3, mb: 1 }} >{translate('commons.product.itemExternalId')}: <b>{request.wrongProductExternalId ?? ""}</b></Typography>
                                </>
                            }
                            {request.requestReason !== "MissingParts" && request.requestReason !== "OriginalPackagingContainsWrongParts" &&

                                <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.form.damageDescription')}`}</Typography>}

                            <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.note}</Typography>
                        </>
                    }

                    {(request.hasMedia && isMediaImage) &&
                        <>
                            <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.form.damageDocuments')}`}</Typography>
                            <Box sx={{ p: 3, pt: 0, pb: isDesktop ? 3 : 0 }}>
                                <MediaSlider images={request.media} />
                            </Box>
                        </>
                    }

                    <MoreDetails request={request} />

                    {request.warranty &&
                        <>
                            {request.warranty.vehicle &&
                                <>
                                    <Divider />
                                    <Typography sx={{ p: 3, pb: 4 }} variant="subtitle1">{`${translate('request.anomalies.detail.vehicleInfo')}`}</Typography>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            columnGap: 3, rowGap: 4,
                                            p: 3, pt: 0,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                                        }}>
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.brand")}`}
                                            value={getBrandName(request.warranty.vehicle.brand)}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.model")}`}
                                            value={request.warranty.vehicle.model || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.vin")}`}
                                            value={request.warranty.vehicle.vin || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.plate")}`}
                                            value={request.warranty.vehicle.plate || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.registrationDate")}`}
                                            value={request.warranty.vehicle.registrationDate || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                    </Box>
                                </>
                            }

                            {request.warranty.note &&
                                <>
                                    <Divider />
                                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.issueDescription')}`}</Typography>
                                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty.note}</Typography>
                                </>
                            }

                            {request.warranty.diagnosis &&
                                <>
                                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.diagnosis')}`}</Typography>
                                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty.diagnosis}</Typography>
                                </>
                            }

                            {request.warranty.solution &&
                                <>
                                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.remedy')}`}</Typography>
                                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty.solution}</Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: isDesktop ? "row" : "column",
                                            columnGap: 3, rowGap: 4, px: 3
                                        }}
                                    >
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate('request.timeRepair')}`}
                                            value={request.warranty.hoursSpent?.toString() || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate('request.hourRateRepair')}`}
                                            value={request.warranty.ratePerHour?.withoutVat?.toString() || '—'}
                                            currentCurrency={request.warranty.ratePerHour?.currentCurrency as CurrencyTypes}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                    </Box>
                                </>
                            }
                        </>
                    }

                    {(request.hasMedia && !isMediaImage && mediaFromFile && mediaFromFile.mediaData) &&
                        <Box sx={{ mt: 3, p: 3, pt: 0, pb: isDesktop ? 3 : 0 }}>
                            <MediaList
                                media={mediaFromFile.media}
                                mediaData={mediaFromFile.mediaData}
                            />
                        </Box>
                    }

                    {showApprovedItems &&
                        <ApprovedProductsDetails
                            approvedProducts={request.items}
                            hideFirstDivider
                            sx={{ px: 3, pb: 2, pt: 2 }}
                        />
                    }

                    {!request.deliveryOrganization &&
                        <Box sx={{ mt: 2 }}>
                            <Divider />

                            <DetailsButtons
                                handlePaid={handlePaid}
                                handleLostClose={handleLostClose}
                                isAr={isAr}
                                request={request}
                                setConfirmed={setConfirmed}
                                setRefused={setRefused}
                                handlePickup={handlePickup}
                                handleBackPrint={handleBackPrint}
                                handlePrint={handlePrint}
                                carrier={carrier}
                                Print={Print}
                                handleSubmit={handleSubmit}
                                handleAmend={handleAmend}
                                handleClaim={handleClaim}
                            />
                        </Box>
                    }

                </Card>
            }

            {request.deliveryOrganization &&
                <Card sx={{ mt: 2 }}>

                    <DeliverToFrom
                        deliveryOrganization={{
                            name: request.deliveryOrganization.name,
                            address: request.deliveryOrganization.address,
                            contact: request.deliveryOrganization.contact?.businessPhone || ""
                        }}
                        vendor={{
                            name: request.vendor.name,
                            address: request.vendor.address,
                            contact: request.vendor.contact?.businessPhone || ""
                        }}
                    />

                    <Divider />

                    <DetailsButtons
                        handlePaid={handlePaid}
                        handleLostClose={handleLostClose}
                        isAr={isAr}
                        request={request}
                        setConfirmed={setConfirmed}
                        setRefused={setRefused}
                        handlePickup={handlePickup}
                        carrier={carrier}
                        handleBackPrint={handleBackPrint}
                        handlePrint={handlePrint}
                        Print={Print}
                        handleSubmit={handleSubmit}
                        handleAmend={handleAmend}
                        handleClaim={handleClaim}
                    />
                </Card>
            }

        </Box>
    );
}

//-------------------------------------------------------------------------------------------------------------------

function DetailsButtons({ request, handlePaid, setConfirmed, setRefused, handlePickup, carrier, Print, isAr, handleSubmit, handleAmend, handleLostClose, handleClaim }: BacklogDeliveryDetailProps & { Print: VoidFunction }) {

    const { translate } = useLocales();

    const isDesktop = useResponsive("up", "sm");

    const navigate = useNavigate();

    const [confirmLostClose, setConfirmLostClose] = useState<'lost' | 'close' | null>(null);

    const { filtersInUrl } = useSelector((state: RootState) => state.request);

    return (
        <Box
            sx={{
                justifyContent: 'space-between',
                ml: isDesktop ? 'auto' : 0,
                display: 'flex',
                gap: 1, p: 3,
                flexDirection: isDesktop ? 'default' : 'column-reverse'
            }}
        >
            <ConfirmModal
                open={!!confirmLostClose}
                isLost={confirmLostClose === "lost"}
                onConfirm={() => {
                    handleLostClose(confirmLostClose === "lost");
                    setConfirmLostClose(null);
                }}
                onClose={() => setConfirmLostClose(null)}
            />

            <Button
                variant="soft"
                sx={{ borderRadius: '100px' }}
                onClick={() => navigate(carrier ? PATH_DASHBOARD.request.carrier.list + filtersInUrl : PATH_DASHBOARD.request.vendor.list + filtersInUrl, { replace: true })}
            >
                {`${translate('request.goBack')}`}
            </Button>

            <Box sx={{ display: 'flex', gap: 2, flexDirection: isDesktop ? 'default' : 'column' }}>

                {(request.status === "Created") &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation]}>
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{ borderRadius: '100px' }}
                            onClick={() => { setRefused(true); }}
                            startIcon={<DeleteForever />}
                        >
                            {`${translate('commons.refuse')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

                {statusesForLost.includes(request.status) &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation]}>
                        <Button
                            variant="contained"
                            color="error"
                            sx={{ borderRadius: '100px' }}
                            onClick={() => setConfirmLostClose('lost')}
                        >
                            {translate('request.lostRequest')}
                        </Button>
                    </PermissionBasedGuard>
                }
                {request.status === "ReadyForPickUp" &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation]}>
                        <Button
                            variant="contained"
                            color="error"
                            sx={{ borderRadius: '100px' }}
                            onClick={() => setConfirmLostClose('close')}
                        >
                            {translate('request.closeRequest')}
                        </Button>
                    </PermissionBasedGuard>
                }

                {(request.status === "ReadyForPickUp" || request.status === "PickedUp" || request.status === "Arrived") &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorWarehouseValidation]}>
                        <Button
                            sx={{ borderRadius: '100px' }}
                            variant="contained"
                            onClick={() => navigate(PATH_DASHBOARD.request.vendor.warehouseRequest(request.id))}
                        >
                            {`${translate('request.warehouseManage')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

                {(request.status === "RefusedAvailableForPrinting") &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorWarehouseValidation]}>
                        <Button
                            sx={{ borderRadius: '100px' }}
                            variant="contained"
                            onClick={() => Print()}
                        >
                            {`${translate('commons.print')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

                {(request.status === "ReadyForPickUp" || request.status === "RefusedReadyForPickUp") &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp]}>
                        <Button
                            sx={{ borderRadius: '100px' }}
                            variant="contained"
                            onClick={() => handlePickup()}
                        >
                            {`${translate('request.carrierManage')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

                {(request.status === "Created" && (request.requestType === "Warranty" || request.requestType === "DamagedPart" || request.requestType === "Quality")) &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation]}>
                        <Button
                            sx={{ borderRadius: '100px' }}
                            variant="contained"
                            onClick={() => handleAmend()}
                        >
                            {`${translate('request.detail.amend')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

                {(request.status === "Created") &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation]}>
                        <Button
                            variant="contained"
                            sx={{ borderRadius: '100px' }}
                            onClick={() =>
                                request.requestType === "Warranty" && isAr ?

                                    handleSubmit("", {}, { repairFee: 0, invoiceDataFee: 0, returnFee: 0, surveillanceFee: 0 }) :
                                    setConfirmed(true)}
                        >
                            {`${translate('commons.confirm')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

                {(request.status === "Accepted") &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorEdit]} >
                        <Button
                            variant="contained"
                            sx={{ borderRadius: '100px' }}
                            onClick={() => handlePaid()}
                        >
                            {request.status === "Accepted" ? `${translate('request.detail.addCredit')}` : `${translate('request.detail.editCredit')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

                {
                    ((request.status === "PickedUp" || request.status === "ReadyForPickUp" || request.status === "Accepted") && (request.customerClaimNotes && !request.vendorClaimNotes)) &&
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorCloseClaim]}>
                        <Button variant="contained" sx={{ borderRadius: '100px' }} onClick={handleClaim}>
                            {`${translate('request.messages.claimClose')}`}
                        </Button>
                    </PermissionBasedGuard>
                }

            </Box>

        </Box>
    );
}

interface ConfirmModalProps {
    open: boolean,
    isLost?: boolean
    onClose: VoidFunction,
    onConfirm: VoidFunction
}

function ConfirmModal({ onClose, onConfirm, open, isLost }: ConfirmModalProps) {

    const isDesktop = useResponsive('up', 'lg');

    const { translate } = useLocales();

    return (
        <Modal
            open={open}
        >
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? '40vw' : '70vw',
                    heigth: '70vh',
                    py: isDesktop ? 4 : 2,
                }}
            >
                <Box sx={{ textAlign: "center", mb: 1 }}>

                    <Typography variant="h3">
                        {isLost ? `${translate('request.lostRequest')}` : `${translate('request.closeRequest')}`}
                    </Typography>

                    <Typography variant="body1" sx={{ my: 3 }}>
                        {isLost ? `${translate('request.messages.confirmLost')}` : `${translate('request.messages.confirmClose')}`}
                    </Typography>

                    <Button
                        variant="soft"
                        size={isDesktop ? "small" : "medium"}
                        onClick={() => onClose()}
                        sx={{ mt: 3, mr: { xs: 0, sm: 2 }, borderRadius: "100px" }}
                    >
                        {`${translate("commons.cancel")}`}
                    </Button>

                    <Button
                        variant="contained"
                        color={"error"}
                        size={isDesktop ? "small" : "medium"}
                        onClick={() => onConfirm()}
                        sx={{ mt: 3, ml: 2, borderRadius: "100px" }}
                    >
                        {`${translate("commons.confirm")}`}
                    </Button>
                </Box>
            </Card>
        </Modal>
    );
}