import { Box, Divider, SxProps, Theme, Typography } from "@mui/material";
import { useMemo } from "react";
import { Order } from "src/@types/orders";
import { Address } from "src/@types/organizations";
import { Request } from "src/@types/request";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import { useAuth } from "src/appHooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";

interface MoreDetailsProps {
    request?: Request,
    order?: Order,
    sx?: SxProps<Theme> | undefined
}

export default function MoreDetails({ request, order, sx }: MoreDetailsProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    const { user } = useAuth();

    const data: Request | Order = useMemo(() => order ? order : request!, [request, order]);

    const getAddress = (address: Address): string[] => {
        let addr = "";
        let city = "";
        let country = "";

        addr += address.address ? address.address + ", " : "";
        addr += address.district ? address.district + "" : "";
        city += address.zipCode ? address.zipCode + ", " : "";
        city += address.city ? address.city + "" : "";
        country += address.country ? address.country : "";

        return [addr, city, country];
    };

    return (
        <Box sx={{ ...sx }}>
            <Typography sx={{ p: 3, pb: 4 }} variant="subtitle1">{`${translate('commons.moreDetail')}`}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isDesktop ? "row" : "column",
                    columnGap: 3, rowGap: 4, px: 3
                }}
            >
                <GridLabeledText
                    align={"left"}
                    label={`${translate('request.customerCode')}`}
                    value={data.customer.externalId || '—'}
                    left={4.5}
                    right={7.5}
                    variant="body2"
                />
                <GridLabeledText
                    align={"left"}
                    label={`${translate("commons.company")}`}
                    value={data.customer.name || '—'}
                    left={4.5}
                    right={7.5}
                    variant="body2"
                />

            </Box>
            <Box sx={{ px: 3, py: 4 }}>
                <GridLabeledText
                    align={"left"}
                    label={`${translate("request.anomalies.detail.customerAddress")}`}
                    value={getAddress(data.customer.address).join()}
                    left={2.2}
                    right={9.8}
                    variant="body2"
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isDesktop ? "row" : "column",
                    columnGap: 3, rowGap: 4, px: 3, pb: 3
                }}
            >
                <GridLabeledText
                    align={"left"}
                    label={`${translate("request.anomalies.detail.requestCreator")}`}
                    value={order ? user.displayName : request!.createdBy.lastName + " " + request!.createdBy.firstName}
                    left={4.5}
                    right={7.5}
                    variant="body2"
                />
                <GridLabeledText
                    align={"left"}
                    label={`${translate("commons.warehouse")}`}
                    value={data.vendor.name}
                    left={4.5}
                    right={7.5}
                    variant="body2"
                />
            </Box>

            {(request?.requestType === "WrongShipment" && request?.requestReason === "SurplusDelivered") &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isDesktop ? "row" : "column",
                        columnGap: 3, rowGap: 4, px: 3, pb: 3
                    }}
                >
                    <GridLabeledText
                        align={"left"}
                        label={`${translate("request.detail.keepParts")}`}
                        value={request.deliveryOrganization ? `${translate("commons.no")}` : `${translate("commons.yes")}`}
                        left={2.2}
                        right={9.8}
                        variant="body2"
                    />
                </Box>
            }

            {(request && request.note && request.requestType !== "DamagedPart" && request.requestType !== "Quality") &&
                <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? "row" : "column",
                            px: 3, py: 1.5
                        }}
                    >
                        <GridLabeledText
                            align={"left"}
                            label={`${translate('commons.note')}`}
                            value={request && request.note}
                            left={2.2}
                            right={9.8}
                            variant="body2"
                        />
                    </Box>
                </>
            }
            {
                request?.acceptNotes?.note &&
                <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? "row" : "column",
                            px: 3, py: 1.5
                        }}
                    >
                        <GridLabeledText
                            align={"left"}
                            label={`${translate('request.warehouseNote')}`}
                            value={request && request?.acceptNotes.note}
                            left={2.2}
                            right={9.8}
                            variant="body2"
                        />
                    </Box>
                </>
            }
            {request && request.paymentNotes &&
                <>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? "row" : "column",
                            columnGap: 3, rowGap: 4, px: 3, pb: 3, my: 1
                        }}
                    >
                        <GridLabeledText
                            align={"left"}
                            label={`${translate('documents.detail.creditNoteN')}`}
                            value={request && request.paymentNotes.note}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                        <GridLabeledText
                            align={"left"}
                            label={`${translate('commons.createdOn')}`}
                            noSeconds
                            shortMonth
                            valueAsDate
                            date={request && new Date(request.paymentNotes.createdOn)}
                            left={4.5}
                            right={7.5}
                            fontWeight={'600'}
                            variant="body2"
                        />
                    </Box>
                </>
            }

            {(request && request.approverNotes && request.approverNotes.repairFee > 0) &&
                <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? "row" : "column",
                            px: 3, py: 1.5
                        }}
                    >
                        <GridLabeledText
                            align={"left"}
                            label={`${translate('request.repairFee')}`}
                            value={request?.approverNotes?.repairFee + "%"}
                            left={2.2}
                            right={9.8}
                            variant="body2"
                        />
                    </Box>
                </>
            }
        </Box>
    );
}