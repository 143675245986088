import { createAsyncThunk } from "@reduxjs/toolkit";
import * as basketServices from "src/services/basketServices";
import { BasketParams } from "src/@types/basket";
import { startLoading } from "./basket-slices";
//---- PUBLIC - BASKET ----------------------------------------------------------------------

const detailPublicBasket = createAsyncThunk(
    'basket/detailPublicBasket',
    async (_, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await basketServices.detailPublicBasket();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }

    }
);

const addItemPublicBasket = createAsyncThunk(
    'basket/addItemPublicBasket',
    async (options: BasketParams, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await basketServices.addItemPublicBasket(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }

    }
);

const removeItemPublicBasket = createAsyncThunk(
    'basket/removeItemPublicBasket',
    async (options: BasketParams, { rejectWithValue }) => {
        try {
            const { data } = await basketServices.removeItemPublicBasket(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }

    }
);

const basketOperations = {
    removeItemPublicBasket,
    addItemPublicBasket,
    detailPublicBasket
};

export default basketOperations;