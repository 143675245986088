import { AddShoppingCart } from "@mui/icons-material";
import { Box, Button, Card, Container, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { EntityType } from "src/@types/customField";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import Page from "src/appComponents/Page";
import useCurrency from "src/appHooks/useCurrency";
import useTenant from "src/appHooks/useTenant";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import IncrementerButton from "src/components/custom-input/IncrementerButton";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { dispatch, RootState } from "src/redux/store";
import { GeneralDetailsCarousel } from "src/sections/@dashboard/vehicle/details/ProductDetailsCarousel";
import CartShopPopover from "src/sections/@dashboard/webshop/publicShop/CartShopPopover";
import { convert } from "src/utils/currency";
import { getCustomFieldsDisplay, getLocalizedString } from "src/utils/CustomFieldManagment";
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { basketOperations } from "src/redux/basket";
import { PATH_DASHBOARD } from "src/routes/paths";
import { webshopOperations } from "src/redux/webshop";
import { useParams } from "react-router";
import { LoadingButton } from "@mui/lab";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import LoadingScreen from "src/appComponents/loading-screen";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { OrganizationPermissionTypes } from "src/@types/permissions";

export default function PublicProductDetail() {

    const { translate, currentLang } = useLocales();

    const { themeStretch, currency } = useSettingsContext();

    const { customFields } = useTenant();

    const { rates } = useCurrency();

    const [quantity, setQuantity] = useState(0);

    const { bundle, displayShop, isBundleLoading } = useSelector((state: RootState) => state.webshop);

    const { isBasketLoading } = useSelector((state: RootState) => state.basket);

    const hasMedia = bundle && bundle.items[0].media.length > 0;

    const hasCustomFields = !!bundle?.items[0]?.customFields;

    const { shopId, bundleId } = useParams();

    useEffect(() => {
        if (!bundle && bundleId)
            dispatch(webshopOperations.getShopBundle(bundleId));

    }, [bundle, bundleId]);

    useEffect(() => {
        if (!displayShop && shopId)
            dispatch(webshopOperations.getDisplayShop(shopId));
    }, [displayShop, shopId]);

    const displayFields = useMemo(() => {
        return getCustomFieldsDisplay(customFields, bundle?.items[0]?.customFields ?? {}, EntityType.SpareParts);
    }, [bundle, customFields]);

    const [customFieldTab, setCustomFieldTab] = useState(Object.keys(displayFields)[0] ?? "");

    const prices = useMemo(() => {

        if (bundle) {
            const gross = convert(bundle.price.grossAmount.withoutVat ?? 0, bundle.price.grossAmount.currentCurrency, currency.label, rates).toFixed(2);

            const discounted = convert(bundle.price.unitDiscountedAmount.withoutVat ?? 0, bundle.price.unitDiscountedAmount.currentCurrency, currency.label, rates).toFixed(2);

            return {
                gross,
                discounted
            };
        }
    }, [bundle, currency.label, rates]);

    const handleAddCart = async () => {
        if (bundle && displayShop) {
            await dispatch(basketOperations.addItemPublicBasket({
                bundleId: bundle.bundleId,
                quantity,
                shopId: displayShop.id
            })).unwrap();

            dispatch(setSuccessMessage({ text: translate('basket.messages.itemsAdded') }));
        }
    };

    return (
        <Page title={translate("menu.management.webshop.productDetail.title")}>
            {(isBundleLoading) ?
                <LoadingScreen />
                :
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={translate("menu.management.webshop.productDetail.title")}
                        links={[
                            { name: `${translate("commons.home")}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("commons.list")}`, href: PATH_DASHBOARD.webshopPublic.list },
                            { name: displayShop?.title, href: PATH_DASHBOARD.webshopPublic.detail(displayShop?.id ?? "") },
                            { name: bundle?.items[0].name },
                        ]}
                        action={
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Buy]}>
                                <CartShopPopover />
                            </PermissionBasedGuard>
                        }
                    />

                    <Box>
                        <Grid container spacing={3} >
                            {!!bundle?.items[0].media.length &&
                                <Grid item md={6}>
                                    <GeneralDetailsCarousel media={bundle?.items[0].media ?? []} />
                                </Grid>
                            }
                            {!bundle?.items[0].media.length &&
                                <Grid item md={6}>
                                    <NoPhotographyIcon sx={{ width: '100%', height: '20vh', objectFit: 'contain' }} />
                                </Grid>
                            }
                            <Grid item md={!hasMedia ? 6 : 12}>
                                <Box>
                                    <Typography variant="overline" color="textSecondary" >
                                        {bundle?.items[0].externalId}
                                    </Typography>
                                    <Typography variant="h5" >
                                        {bundle?.items[0].name}
                                    </Typography>
                                    <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'} gutterBottom>
                                        <b>{"[" + bundle?.items[0].family?.supplier?.code + "] - "}</b>{bundle?.items[0].family?.supplier?.description}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Typography variant="h4" color='textSecondary' style={{ textDecoration: 'line-through' }}>
                                            {prices?.gross} {currency.symbol}
                                        </Typography>
                                        <Typography variant="h4" gutterBottom>
                                            {prices?.discounted} {currency.symbol}
                                        </Typography>
                                    </Box>

                                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Buy]}>

                                        <Divider />
                                        <Box sx={{ display: 'flex', gap: 2, mt: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="subtitle2" sx={{ pb: 2 }}>
                                                {translate('commons.quantity')}
                                            </Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center' }}>
                                                <IncrementerButton
                                                    disabledDecrease={quantity <= 0}
                                                    disabledIncrease={quantity >= (bundle?.availability.availableQuantity ?? 0)}
                                                    quantity={quantity}
                                                    onIncrease={() => setQuantity(prev => ++prev)}
                                                    onDecrease={() => setQuantity(prev => --prev)}
                                                />
                                                <Typography variant="caption" color='textSecondary'>
                                                    {translate('statuses.available')}: {bundle?.availability.availableQuantity}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                            <LoadingButton
                                                loading={isBasketLoading}
                                                variant="contained"
                                                color="warning"
                                                fullWidth
                                                onClick={() => handleAddCart()}
                                                disabled={quantity <= 0}
                                                startIcon={<AddShoppingCart />}
                                                sx={{ borderRadius: '100px', py: 1 }}
                                            >
                                                {translate('basket.detail.add')}
                                            </LoadingButton>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                disabled={quantity <= 0}
                                                sx={{ borderRadius: '100px', py: 1 }}
                                            >
                                                {translate('basket.detail.buyNow')}

                                            </Button>
                                        </Box>

                                    </PermissionBasedGuard>

                                </Box>
                            </Grid>

                        </Grid>
                        {hasCustomFields &&
                            <Card sx={{ mt: 3 }}>
                                <Tabs value={customFieldTab} onChange={(_, v) => setCustomFieldTab(v)} sx={{ bgcolor: 'background.neutral', px: 2, pt: 1 }}>
                                    {
                                        Object.keys(displayFields).map((key, index) => (
                                            <Tab key={index} label={key} value={key} />
                                        ))
                                    }
                                </Tabs>
                                <Divider />
                                <Box sx={{
                                    display: 'grid',
                                    gap: 2,
                                    gridTemplateColumns: { lg: '1fr 1fr' },
                                    p: 3
                                }}>
                                    {
                                        bundle?.items[0].customFields && Object.entries(displayFields[customFieldTab]).map(([key, value]) => (

                                            <GridLabeledText
                                                align={"left"}
                                                key={key}
                                                label={getLocalizedString(value.label, currentLang)}
                                                value={value.value ?? "—"}
                                                left={4.5}
                                                right={7.5}
                                                variant="body2"
                                            />

                                        ))
                                    }
                                </Box>
                            </Card>
                        }

                    </Box>
                </Container>
            }
        </Page>
    );
}