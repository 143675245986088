import { Container } from "@mui/system";
import { useParams } from "react-router";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Box, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReturnDetail from "src/sections/@dashboard/request/detail/ReturnDetail";
import Tracking from "src/sections/@dashboard/request/detail/Tracking";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { requestOperations } from "src/redux/request";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { ordersOperations } from "src/redux/orders";
import { Invoice, DeliveryNote } from "src/@types/orders";

export type DocsIds = {
    orderId: string,
    invoiceId: string | null | undefined,
    deliveryNoteId: string | null | undefined
};

export default function RequestDetail() {

    const { translate, currentLang } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { id } = useParams();

    const { request, isLoading } = useSelector((state: RootState) => state.request);

    const { isOrderLoading } = useSelector((state: RootState) => state.orders);

    const [docsIds, setDocsIds] = useState<DocsIds[]>([]);

    const getOrders = useCallback(async (orders: Set<string>) => {

        const orderArr = Array.from(orders);

        const promises = orderArr.map(order => {
            return dispatch(ordersOperations.getOrder(order)).unwrap();
        });

        const docsId = (await Promise.all(promises)).map((val) => ({
            orderId: val.id,
            deliveryNoteId: val.deliveryNoteId,
            invoiceId: val.invoiceId
        }));

        setDocsIds(docsId);

    }, []);

    useEffect(() => {

        if (request && request.id === id) {

            const orderSet = new Set([...request.items.map(i => i.orderId), ...request.missingItems.map(i => i.orderId)]);

            getOrders(orderSet);

        }
    }, [getOrders, id, request]);

    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const [deliveryNotes, setDeliveryNotes] = useState<DeliveryNote[]>([]);

    useEffect(() => {
        if (!invoices.length && !deliveryNotes.length && docsIds.length) {
            const invoiceSet = new Set(docsIds.filter((i) => !!i.invoiceId).map((v) => v.invoiceId));

            const deliveryNoteSet = new Set(
                docsIds.filter((i) => !!i.deliveryNoteId).map((v) => v.deliveryNoteId)
            );

            invoiceSet.forEach((inv) => {
                if (!invoices.find((i) => i.id === inv))
                    dispatch(ordersOperations.getInvoice(inv!))
                        .unwrap()
                        .then((inv) => {
                            setInvoices((prev) => {
                                //check for duplicates maybe unnecessary
                                const updatedArray = prev.concat([inv]);

                                const idSet = new Set(updatedArray.map((obj) => obj.id));

                                const ret = Array.from(idSet).map(
                                    (id) => updatedArray.find((obj) => obj.id === id)!
                                );

                                return ret;
                            });
                        });
            });

            deliveryNoteSet.forEach((del) => {
                if (!deliveryNotes.find((i) => i.id === del))
                    dispatch(ordersOperations.getDeliveryNote(del!))
                        .unwrap()
                        .then((deliv) => {
                            setDeliveryNotes((prev) => {
                                //check for duplicates maybe unnecessary
                                const updatedArray = prev.concat([deliv]);

                                const idSet = new Set(updatedArray.map((obj) => obj.id));

                                const ret = Array.from(idSet).map(
                                    (id) => updatedArray.find((obj) => obj.id === id)!
                                );

                                return ret;
                            });
                        });
            });
        }
    }, [deliveryNotes, docsIds, invoices]);

    const [currentTab, setCurrentTab] = useState("returnDetails");

    useEffect(() => {

        if (id && request?.id !== id)
            dispatch(requestOperations.getRequest({ id: id }));

    }, [id, currentLang, request?.id]);

    const TABS = useMemo(() => [{
        value: 'returnDetails',
        label: `${translate('request.returnDetails')}`,
        component: request && <ReturnDetail request={request} docsIds={docsIds} invoices={invoices} deliveryNotes={deliveryNotes} />
    },
    ...(request && request.requestReason !== "MissingParts" && request.deliveryOrganization ? [{
        value: 'tracking',
        label: `${translate('request.tracking')}`,
        component: <Tracking request={request} />
    }] : [])
    ], [translate, request, docsIds, invoices, deliveryNotes]);

    return (
        <Page title={`${translate("request.request")}: ${translate("request.detail.title")}`}>

            {isLoading || isOrderLoading ? <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={`${translate('menu.management.request.title')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('menu.management.request.title')}`, href: PATH_DASHBOARD.request.customer.list },
                            { name: `${translate('returns.filter.idRequest')} ${request?.prettyName}` }
                        ]}
                    />
                    <Tabs
                        value={currentTab}
                        onChange={(_, val) => setCurrentTab(val)}
                    >
                        {TABS.map((tab, index) =>
                            <Tab key={index} value={tab.value} label={tab.label} />
                        )}

                    </Tabs>

                    {TABS.map((tab, ind) =>
                        tab.value === currentTab &&
                        <Box key={ind}>
                            {tab.component}
                        </Box>
                    )}

                </Container>
            }
        </Page>
    );
}