import { Box, Container, Typography } from "@mui/material";
import { Request } from "src/@types/request";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DateZone from "src/appComponents/DateZone";
import { Logo } from "src/appComponents/logo";
import { useLocales } from "src/locales";
import { useBarcode } from "next-barcode";
import useTenant from "src/appHooks/useTenant";
import { useCallback } from "react";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";

interface ReturnLabelProps {
    request: Request
    docExternalId: {
        orderId: string,
        invoiceExternalId: string,
        deliveryNoteExternalId: string
    }[],
    returnToCustomer?: boolean
}

export default function ReturnLabel({ request, docExternalId, returnToCustomer }: ReturnLabelProps) {

    const { translate, currentLang } = useLocales();

    const { customFields } = useTenant();

    const customFieldName = useCallback((id: string) => {

        return getCustomFieldLabel(customFields, id, currentLang);

    }, [currentLang, customFields]);

    const { inputRef } = useBarcode({
        value: request.barcode,
        options: { displayValue: true }
    });

    const confirmFields = ['request.confirmOrigin', 'request.confirmCarrier', 'request.confirmDestination'];

    return (
        <Container sx={{ px: 2 }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>

                <Box id='LEFT CONTAINER'
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        transform: 'rotate(-90deg)',
                        flexShrink: 'inherit',
                        width: '20%',
                        mr: 8,
                        justifyContent: 'space-evenly'
                    }}
                >
                    <canvas ref={inputRef} style={{ maxWidth: '300%' }} />
                </Box>

                <Box sx={{ display: 'grid', width: '80%' }}>

                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
                        id='RIGHT CONTAINER'
                    >
                        <Box
                            sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', height: '100%' }}
                            id='FIRST ROW'
                        >
                            <Box sx={{ border: '3.50px black dotted', width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        color: 'black',
                                        fontSize: 30,
                                        fontFamily: 'Public Sans',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        wordWrap: 'break-word'
                                    }}
                                >
                                    {returnToCustomer ? translate(`request.anomalies.detail.return`).toString() : translate(`request.${request.requestType}`).toString()}</Typography>
                            </Box>

                            {returnToCustomer &&
                                <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, width: '50%' }}>
                                    <Box sx={{ border: '0.50px black solid', p: 2 }} >
                                        <Typography sx={{
                                            fontSize: 12.5,
                                            fontFamily: 'Public Sans',
                                            fontWeight: '700',
                                            textTransform: 'uppercase',
                                            wordWrap: 'break-word'
                                        }}>
                                            {`${translate('request.form.returnReason')}`}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ border: '0.50px black solid', p: 2 }}>
                                        {
                                            <Typography
                                                sx={{
                                                    color: '#303030',
                                                    fontSize: 12.5,
                                                    fontFamily: 'Public Sans',
                                                    fontWeight: '400',
                                                    textTransform: 'uppercase',
                                                    wordWrap: 'break-word'
                                                }}
                                            >
                                                {`${translate(`request.${request.requestType}`)}`}
                                            </Typography>
                                        }
                                    </Box>
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"] &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }} >
                                            <Typography sx={{
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '700',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {customFieldName("stellantis-europe-returnrequestapprover-icar-request")}
                                            </Typography>
                                        </Box>}
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"] &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }}>
                                            <Typography sx={{
                                                color: '#303030',
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"]}
                                            </Typography>
                                        </Box>}
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"] &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }} >
                                            <Typography sx={{
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '700',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {customFieldName("stellantis-europe-returnrequestapprover-sapcsps-code")}
                                            </Typography>
                                        </Box>}
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"] &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }}>
                                            <Typography sx={{
                                                color: '#303030',
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"]}
                                            </Typography>
                                        </Box>}
                                    {request.requestType === "Warranty" &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }} >
                                            <Typography sx={{
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '700',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {`${translate('request.detail.dossier')}`}
                                            </Typography>
                                        </Box>}
                                    {request.requestType === "Warranty" &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }}>
                                            <Typography sx={{
                                                color: '#303030',
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {request.warranty && (request.warranty.invoices.length > 1 ? request.approverNotes?.customFields["invoice"] + "" : request.warranty.invoices[0].number)}
                                            </Typography>
                                        </Box>}
                                </Box>
                            }
                            {!returnToCustomer && request.requestType !== "Core" && request.requestType !== "Warranty" &&
                                <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, width: '50%' }} >
                                    <Box sx={{ border: '0.50px black solid', p: 2 }} >
                                        <Typography sx={{
                                            fontSize: 12.5,
                                            fontFamily: 'Public Sans',
                                            fontWeight: '700',
                                            textTransform: 'uppercase',
                                            wordWrap: 'break-word'
                                        }}>
                                            {`${translate('commons.action')}`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ border: '0.50px black solid', p: 2 }}>
                                        <Typography sx={{
                                            color: '#303030',
                                            fontSize: 12.5,
                                            fontFamily: 'Public Sans',
                                            fontWeight: '400',
                                            textTransform: 'uppercase',
                                            wordWrap: 'break-word'
                                        }}>
                                            {`${translate(`request.${request.approverNotes && (request.approverNotes.approvedType[0].toLowerCase() + request.approverNotes.approvedType.substring(1))}`)}`}
                                        </Typography>
                                    </Box>
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"] &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }} >
                                            <Typography sx={{
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '700',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {customFieldName("stellantis-europe-returnrequestapprover-icar-request")}
                                            </Typography>
                                        </Box>}
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"] &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }}>
                                            <Typography sx={{
                                                color: '#303030',
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"]}
                                            </Typography>
                                        </Box>}
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"] &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }} >
                                            <Typography sx={{
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '700',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {customFieldName("stellantis-europe-returnrequestapprover-sapcsps-code")}

                                            </Typography>
                                        </Box>}
                                    {request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"] &&
                                        <Box sx={{ border: '0.50px black solid', p: 2 }}>
                                            <Typography sx={{
                                                color: '#303030',
                                                fontSize: 12.5,
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                textTransform: 'uppercase',
                                                wordWrap: 'break-word'
                                            }}>
                                                {request.approverNotes.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"]}
                                            </Typography>
                                        </Box>}

                                </Box>}
                            {
                                !returnToCustomer && request.requestType === "Warranty" &&

                                <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, width: '50%' }} >
                                    <Box sx={{ border: '0.50px black solid', p: 2 }} >
                                        <Typography sx={{
                                            fontSize: 12.5,
                                            fontFamily: 'Public Sans',
                                            fontWeight: '700',
                                            textTransform: 'uppercase',
                                            wordWrap: 'break-word'
                                        }}>
                                            {`${translate('request.detail.dossier')}`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ border: '0.50px black solid', p: 2 }}>
                                        <Typography sx={{
                                            color: '#303030',
                                            fontSize: 12.5,
                                            fontFamily: 'Public Sans',
                                            fontWeight: '400',
                                            textTransform: 'uppercase',
                                            wordWrap: 'break-word'
                                        }}>
                                            {request.warranty && (request.warranty.invoices.length > 1 ? request.approverNotes?.customFields["invoice"] + "" : request.warranty.invoices[0].number)}
                                        </Typography>
                                    </Box>
                                </Box>
                            }

                        </Box>

                        <Box
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            id='SECOND ROW'
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 auto' }}>

                                <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', flex: 1, p: 1, pb: '90px', gap: 1 }}>
                                    <Typography sx={{

                                        fontFamily: 'Public Sans',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        wordWrap: 'break-word'
                                    }}>
                                        {`${translate(`request.partReference`)}`}
                                    </Typography>

                                    {request && request.items.map((item, index) => {
                                        return (
                                            <Typography
                                                key={index}
                                                sx={{
                                                    color: '#303030',
                                                    fontFamily: 'Public Sans',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                {item.product.externalId}
                                            </Typography>);
                                    })}
                                    {request && request.exceededItems.map((item, index) => {
                                        return (
                                            <Typography
                                                key={index}
                                                sx={{
                                                    color: '#303030',
                                                    fontFamily: 'Public Sans',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                {item.product.externalId}
                                            </Typography>);
                                    })}
                                </Box>

                                {request.requestType === "Core" &&
                                    <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', flex: 1, p: 1, pb: '90px', gap: 1 }}>
                                        <Typography sx={{

                                            fontFamily: 'Public Sans',
                                            fontWeight: '700',
                                            textTransform: 'uppercase',
                                            wordWrap: 'break-word'
                                        }}>
                                            {`${translate(`request.detail.depositRef`)}`}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: '#303030',
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                wordWrap: 'break-word'
                                            }}>
                                            {request && request.items.map((item) => {
                                                return item.product.customFields['stellantis-europe-spareparts-deposit-number'] || "";
                                            })}

                                        </Typography>

                                    </Box>
                                }
                                <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', flex: 1, p: 1, pb: '90px', gap: 1 }}>
                                    <Typography sx={{

                                        fontFamily: 'Public Sans',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        wordWrap: 'break-word'
                                    }}>
                                        {`${translate(`commons.description`)}`}
                                    </Typography>

                                    {request && request.items.map((item, index) => {
                                        return (
                                            <Typography
                                                key={index}
                                                sx={{
                                                    color: '#303030',
                                                    fontFamily: 'Public Sans',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                {item.product.name}
                                            </Typography>
                                        );
                                    })}
                                    {request && request.exceededItems.map((item, index) => {
                                        return (
                                            <Typography
                                                key={index}
                                                sx={{
                                                    color: '#303030',
                                                    fontFamily: 'Public Sans',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                {item.product.name}
                                            </Typography>
                                        );
                                    })}

                                </Box>

                                <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', p: 1, pb: '90px', gap: 1, flex: '0.5' }}>
                                    <Typography sx={{
                                        fontFamily: 'Public Sans',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        wordWrap: 'break-word'
                                    }}>
                                        {`${translate(`commons.quantity`)}`}
                                    </Typography>

                                    {request && request.items.map((item) =>
                                        <Typography
                                            key={item.product.id}
                                            sx={{
                                                color: '#303030',
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                wordWrap: 'break-word'
                                            }}>
                                            {(item.requiredQuantity || item.approvedQuantity)}
                                        </Typography>
                                    )}
                                    {request && request.exceededItems.map((item) =>
                                        <Typography
                                            key={item.product.id}
                                            sx={{
                                                color: '#303030',
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                wordWrap: 'break-word'
                                            }}>
                                            {(item.quantity || 0)}
                                        </Typography>
                                    )}
                                </Box>

                                <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', p: 1, pb: '90px', gap: 1, flex: '1' }}>
                                    <Typography sx={{

                                        fontFamily: 'Public Sans',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        // wordWrap: 'break-word'
                                    }}>
                                        {`${translate(`documents.filter.docRef`)}`}
                                    </Typography>

                                    {request && request.items.map((item, index) => {
                                        const doc = docExternalId.find(d => d.orderId === item.orderId);

                                        return (<Typography
                                            key={index}
                                            sx={{
                                                color: '#303030',
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                wordWrap: 'break-word'
                                            }}>
                                            {doc ? (doc.invoiceExternalId || doc.deliveryNoteExternalId) : ""}
                                        </Typography>);
                                    })}
                                    {request && request.exceededItems.map((item, index) => {
                                        const doc = docExternalId.find(d => d.orderId === item.order?.id);

                                        return (<Typography
                                            key={index}
                                            sx={{
                                                color: '#303030',
                                                fontFamily: 'Public Sans',
                                                fontWeight: '400',
                                                wordWrap: 'break-word'
                                            }}>
                                            {doc ? (doc.invoiceExternalId || doc.deliveryNoteExternalId) : ""}
                                        </Typography>);
                                    })}
                                </Box>

                                <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', p: 1, gap: 3, flex: '0.75', alignItems: 'center' }}>
                                    <Typography sx={{

                                        fontFamily: 'Public Sans',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        wordWrap: 'break-word'
                                    }}>
                                        {`${translate(`request.big`)}`}
                                    </Typography>
                                    {(request.items[0]?.bigAndHeavy || request.missingItems[0]?.bigAndHeavy) && <CheckCircleIcon sx={{ fontSize: '60px' }} />}
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 0', width: '100%' }}>

                                <Addresses returnToCustomer={returnToCustomer} request={request} />

                            </Box>
                        </Box>

                        <Box
                            sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 auto' }}
                            id='THIRD ROW'
                        >
                            {confirmFields.map((field, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        border: '0.50px black solid',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1, p: 1, pb: '60px'
                                    }}
                                >
                                    <Typography sx={{

                                        fontFamily: 'Public Sans',
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                        wordWrap: 'break-word'
                                    }}>
                                        {`${translate(field)}`}
                                    </Typography>

                                </Box>))}

                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 0', gap: 1 }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', gap: 1, fontFamily: 'Public Sans',
                                fontWeight: '500',
                                textTransform: 'uppercase',
                                lineHeight: 18,
                                wordWrap: 'break-word'
                            }}>
                                <Typography>{`${translate('commons.validationDate')}: `} </Typography>
                                <DateZone date={request.createdOn} onlyDate />
                            </Box>
                            <Logo sx={{ height: '30px' }} disabledLink />
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', gap: 1, fontFamily: 'Public Sans',
                                fontWeight: '500',
                                textTransform: 'uppercase',
                                lineHeight: 18,
                                wordWrap: 'break-word'
                            }}>
                                <Typography>{`${translate('commons.printDate')}: `}</Typography>
                                <DateZone date={new Date()} onlyDate />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container >);
}

interface AddressesProps {
    request: Request,
    returnToCustomer?: boolean
}

function Addresses({ request, returnToCustomer }: AddressesProps) {

    const { translate, currentLang } = useLocales();

    const { customFields } = useTenant();

    const [urgent, normal] = [request.deliveryOrganization?.customFields["stellantis-europe-organization-urgent-tour"], request.deliveryOrganization?.customFields["stellantis-europe-organization-normal-tour"]];

    const tour = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography><b>{translate('request.detail.tour')}:</b></Typography>
            {normal &&
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography>{getCustomFieldLabel(customFields, "stellantis-europe-organization-normal-tour", currentLang)}: </Typography>
                    <Typography>{normal}</Typography>
                </Box>
            }
            {urgent &&
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography>{getCustomFieldLabel(customFields, "stellantis-europe-organization-urgent-tour", currentLang)}: </Typography>
                    <Typography>{urgent}</Typography>
                </Box>
            }
        </Box>);

    return <>
        {request.deliveryOrganization &&
            <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', flex: 1, p: 2 }}>

                <Typography sx={{
                    fontSize: 12,
                    fontFamily: 'Public Sans',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    wordWrap: 'break-word',
                    mb: 1
                }}>
                    {`${translate(`commons.from`)}`}
                </Typography>

                <Typography
                    sx={{
                        color: '#303030',
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        wordWrap: 'break-word',

                    }}>
                    <b>{!returnToCustomer ? (request.deliveryOrganization.externalId) : (request.vendor.externalId)}</b>
                </Typography>
                <Typography
                    sx={{
                        color: '#303030',
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                        mb: 1
                    }}>
                    <b>{!returnToCustomer ? (request.deliveryOrganization.name) : (request.vendor.name)}</b>
                </Typography>
                <Typography
                    sx={{
                        color: '#303030',
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                    }}>
                    {returnToCustomer ? request.vendor.address.address : request.deliveryOrganization.address.address}
                </Typography>
                <Typography
                    sx={{
                        color: '#303030',
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                        mb: 2
                    }}>
                    {returnToCustomer ?
                        request.vendor.address.zipCode +
                        ", " + request.vendor.address.district +
                        ", " + request.vendor.address.city +
                        ", " + request.vendor.address.country
                        :
                        request.deliveryOrganization.address.zipCode +
                        ", " + request.deliveryOrganization.address.district +
                        ", " + request.deliveryOrganization.address.city +
                        ", " + request.deliveryOrganization.address.country}
                </Typography>
                {returnToCustomer && (urgent || normal) &&
                    tour
                }
                {request.note &&
                    <Typography>
                        {`${translate('commons.note')}: ` + (request.note || "")}
                    </Typography>}
            </Box>}
        {request.deliveryOrganization &&
            <Box sx={{ border: '0.50px black solid', display: 'flex', flexDirection: 'column', flex: 1, p: 2 }}>
                <Typography sx={{
                    fontSize: 12,
                    fontFamily: 'Public Sans',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    wordWrap: 'break-word',
                    mb: 1
                }}>
                    {`${translate(`commons.shipTo`)}`}
                </Typography>
                <Typography
                    sx={{
                        color: '#303030',
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                        mb: 1
                    }}>
                    <b>{returnToCustomer ? (request.deliveryOrganization.externalId) : (request.vendor.externalId)}</b>
                </Typography>
                <Typography
                    sx={{
                        color: '#303030',
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                        mb: 1
                    }}>
                    <b>{returnToCustomer ? (request.deliveryOrganization.name) : (request.vendor.name)}</b>
                </Typography>
                <Typography
                    sx={{
                        color: '#303030',
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                    }}>
                    {returnToCustomer ?
                        request.deliveryOrganization.address.address :
                        request.vendor.address.address}
                </Typography>
                <Typography
                    sx={{
                        color: '#303030',
                        fontFamily: 'Public Sans',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                        mb: 2
                    }}>
                    {returnToCustomer ?
                        request.deliveryOrganization.address.zipCode +
                        ", " + request.deliveryOrganization.address.district +
                        ", " + request.deliveryOrganization.address.city +
                        ", " + request.deliveryOrganization.address.country :

                        request.vendor.address.zipCode +
                        ", " + request.vendor.address.district +
                        ", " + request.vendor.address.city +
                        ", " + request.vendor.address.country}
                </Typography>
                {!returnToCustomer && (urgent || normal) &&
                    tour
                }
            </Box>}
    </>;
}