import { Box, Button, Card, Container, Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { ChevronLeft, Edit } from "@mui/icons-material";
import CartShopInfo from "src/sections/@dashboard/webshop/publicShop/CartShopInfo";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { useEffect } from "react";
import { basketOperations } from "src/redux/basket";
import { Basket } from "src/@types/basket";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import { getPrice } from "src/utils/currency";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import LoadingScreen from "src/appComponents/loading-screen";

export default function WebshopCheckout() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { basket, isBasketLoading } = useSelector((state: RootState) => state.basket);

    useEffect(() => {
        if (!basket)
            dispatch(basketOperations.detailPublicBasket());

    }, [basket]);

    return (
        <Page title={translate("menu.management.webshop.checkout.title")}>
            {isBasketLoading ?
                <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={translate("menu.management.webshop.checkout.title")}
                        links={[
                            { name: `${translate("commons.home")}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("commons.list")}`, href: PATH_DASHBOARD.webshopPublic.list },
                            { name: translate("menu.management.webshop.checkout.title") },
                        ]}
                    />
                    <Grid container spacing={3} >
                        <Grid item md={8}>
                            <OrderItems basket={basket} />
                        </Grid>
                        <Grid item md={4}>
                            <OrderSummary basket={basket} />
                        </Grid>
                    </Grid>
                </Container>
            }
        </Page>
    );
}

function OrderItems({ basket }: { basket: Basket | null }) {

    const navigate = useNavigate();

    const { translate } = useLocales();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Card sx={{ p: 2 }}>
                <TextField label={translate('basket.checkout.customerOrderRef')} fullWidth />
            </Card>

            {basket?.items.map((shop) =>
                <Card key={shop.shopId}>
                    <CartShopInfo shop={shop} />
                </Card>
            )}

            <Box>
                <Button
                    onClick={() => navigate(PATH_DASHBOARD.webshopPublic.list)}
                    variant="text"
                    color="inherit"
                    startIcon={<ChevronLeft />}
                >
                    {translate('basket.checkout.continueShopping')}
                </Button>
            </Box>

        </Box>
    );
}

function OrderSummary({ basket }: { basket: Basket | null }) {

    const { translate } = useLocales();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, position: 'sticky', top: 40 }}>
            <Card sx={{ p: 2 }}>

                <Typography variant="h6" sx={{ mb: 3 }}>{translate('basket.checkout.summary')}</Typography>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{translate("basket.checkout.totalGross")}</Typography>
                        {basket && <CurrencyAdapter variant="body2" value={getPrice(basket.price.grossAmount)} currency={basket.price.grossAmount.currentCurrency} />}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{translate("basket.checkout.totalRegularDiscount")}</Typography>
                        {basket && <CurrencyAdapter variant="body2" value={-getPrice(basket.price.regularDiscountAmount)} currency={basket.price.regularDiscountAmount.currentCurrency} />}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{translate("basket.checkout.totalAdditionalDiscount")}</Typography>
                        {basket && <CurrencyAdapter variant="body2" value={-getPrice(basket.price.additionalDiscountAmount)} currency={basket.price.additionalDiscountAmount.currentCurrency} />}
                    </Box>

                </Box>
                <Divider sx={{ my: 2 }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="subtitle1" >{translate("documents.documentHeaders.total")}</Typography>
                    {basket &&
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            <CurrencyAdapter variant="subtitle1" value={getPrice(basket.price.netAmount)} currency={basket.price.additionalDiscountAmount.currentCurrency} />

                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <CurrencyAdapter variant="body2" color={'text.secondary'} sx={{ textDecoration: 'line-through' }} value={getPrice(basket.price.grossAmount)} currency={basket.price.grossAmount.currentCurrency} />
                                <Typography variant="body2" color={'text.secondary'}>(-{getPrice(basket.price.discountPercentage as any)}%)</Typography>
                            </Box>

                            <Typography sx={{ mt: 1 }} variant="body2" >({translate('basket.messages.vatIncluded')})</Typography>
                        </Box>
                    }
                </Box>

            </Card>

            <Card sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 3 }}>{translate("basket.checkout.deliveryOptions")}</Typography>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography>Manuel Cooper</Typography>
                        <Button variant="text" startIcon={<Edit />}>{translate('commons.edit')}</Button>
                    </Box>
                    <Typography variant="body2">1147 Rohan Drive Suite 819 - Burlington, VT / 82021</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>904-966-2836</Typography>
                </Box>
                <Divider sx={{ my: 3 }} />
                <RadioGroup sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControlLabel value="standard" control={<Radio />} label={
                        <Box>
                            <Typography variant="subtitle2">
                                Standard delivery
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Delivered on Monday, August 12
                            </Typography>
                        </Box>
                    }
                    />
                    <FormControlLabel value="fast" control={<Radio />} label={
                        <Box>
                            <Typography variant="subtitle2">
                                Fast delivery
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Delivered on Monday, August 5
                            </Typography>
                        </Box>}
                    />
                </RadioGroup>
            </Card>

            <Button variant="contained" color="primary" sx={{ borderRadius: 100 }}>
                {translate("basket.checkout.complete")}
            </Button>

        </Box>
    );
}

