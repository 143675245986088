import { Tooltip, IconButton, ButtonGroup } from "@mui/material";
import { ViewTypes } from "src/@types/list";
import SvgColor from "src/components/svg-color";
import { useLocales } from "src/locales";
import { ViewList } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';

interface ViewSwitchComponentProps {
    view: ViewTypes,
    onChangeView: (view: ViewTypes) => void
}

export default function ViewSwitchComponent({ view, onChangeView }: ViewSwitchComponentProps) {

    const { translate } = useLocales();

    return (
        <ButtonGroup
            sx={{
                height: "46px",
                border: "1px",
                borderStyle: "groove",
                borderColor: (theme) => theme.palette.divider,
                borderRadius: "10px"
            }}
        >
            <Tooltip title={`${translate('list.tooltip.gridView')}`}>
                <IconButton
                    onClick={() => onChangeView("Grid")}
                    sx={{
                        color: (theme) => view === "Grid" ? theme.palette.primary.main : theme.palette.grey[400],
                        width: "46px",
                        height: "46px",
                        px: "4px",
                        borderRadius: 0,
                        ":hover": {
                            backgroundColor: (theme) => alpha(theme.palette.primary.lighter, 0.35),
                            color: (theme) => view === "Grid" ? theme.palette.primary.main : theme.palette.primary.light,
                            borderRadius: "10px 0 0 10px"
                        }
                    }}
                >
                    <SvgColor src={'/assets/icons/utils/ic_view_grid.svg'} />
                </IconButton>
            </Tooltip>

            <Tooltip title={`${translate('list.tooltip.listView')}`}>
                <IconButton
                    onClick={() => onChangeView("List")}
                    sx={{
                        color: (theme) => view === "List" ? theme.palette.primary.main : theme.palette.grey[400],
                        width: "46px",
                        height: "46px",
                        px: "4px",
                        borderRadius: 0,
                        ":hover": {
                            backgroundColor: (theme) => alpha(theme.palette.primary.lighter, 0.35),
                            color: (theme) => view === "List" ? theme.palette.primary.main : theme.palette.primary.light,
                            borderRadius: "0 10px 10px 0"
                        }
                    }}
                >
                    <ViewList sx={{ width: "1.25em", height: "1.25em" }} />
                </IconButton>
            </Tooltip>
        </ButtonGroup>
    );
}