import { Box, Card, Divider, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { GreyCounter } from "./CustomerStep";
import StepButtons from "src/components/buttons/StepButtons";
import InfiniteAutocomplete, { AltInfiniteAutocomplete } from "src/appComponents/InfiniteAutocomplete";
import { TermCondition, TermConditionSearchResult } from "src/@types/termCondition";
import { dispatch, RootState } from "src/redux/store";
import { termConditionOperations } from "src/redux/termCondition";
import { MarkdownStyle } from "../../inbox/detail/InboxDetail";
import Markdown from "src/components/markdown";
import { getLocalizedString } from "src/utils/CustomFieldManagment";
import { useLocales } from "src/locales";
import { BasketRuleSearchResult, NewShop } from "src/@types/webshop";
import { useSelector } from "react-redux";
import { webshopOperations } from "src/redux/webshop";

interface TermsConditionStepProps {
    state: NewShop,
    changeStep: Dispatch<SetStateAction<number>>
    onSubmit: VoidFunction,
    onChange: Dispatch<{
        value: Partial<NewShop>
        type: 'update' | 'reset'
    }>
}

export default function TermsConditionStep({ changeStep, onSubmit, onChange, state }: TermsConditionStepProps) {

    const { translate, currentLang } = useLocales();

    const { termCondition, isLoading } = useSelector((state: RootState) => state.terms);

    const { webshop, isBasketRuleLoading } = useSelector((state: RootState) => state.webshop);

    const [selectedTerms, setSelectedTerms] = useState<TermCondition | null>(() => {
        if (termCondition && termCondition.id === webshop?.termAndCondition?.id)
            return termCondition;

        return null;
    });

    return (
        <Card sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" >{translate("menu.management.basketRules.title")}</Typography>
            </Box>

            <Box sx={{ my: 3 }}>
                <AltInfiniteAutocomplete<BasketRuleSearchResult>
                    searchCallBack={(params) => dispatch(webshopOperations.searchBasketRules({ filters: params, check: false })).unwrap()}
                    renderInput={(params) => <TextField {...params} label={translate("webshop.form.searchTemplateName")} />}
                    getOptionLabel={(option) => (typeof option !== 'string' && option.name) || ""}
                    loading={isBasketRuleLoading}
                    isOptionEqualToValue={(option, value) => option.id === value.id}

                    onChange={(_, val) => {
                        if (val) {
                            onChange({ value: { basketRuleId: val.id }, type: 'update' });
                        }
                    }}
                    renderOption={((props, option) => (
                        <Box {...props} component={'li'} key={option.id} >
                            <Typography sx={{ mr: 2 }}>{option.name}</Typography>
                        </Box>
                    ))}
                />
                {/* <Box sx={{ display: 'flex', gap: 2 }}>
                    <Typography>
                        {basketRuleList[0]?.name}
                    </Typography>
                </Box> */}
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" >{translate("menu.management.termsCondition.title")}</Typography>

            </Box>
            {<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                <Typography variant="subtitle2">{translate("webshop.form.selectTemplate")}</Typography>
                <InfiniteAutocomplete<TermConditionSearchResult, false, false, true>

                    searchCallBack={(params) => dispatch(termConditionOperations.searchTermConditions({ ...params, entityType: 'Shop' })).unwrap()}
                    renderInput={(params) => <TextField {...params} label={translate("webshop.form.searchTemplateName")} />}
                    getOptionLabel={(option) => (typeof option !== 'string' && option.name) || ""}
                    filterOptions={(options) => options.filter(v => v.enabled)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    loading={isLoading}
                    value={selectedTerms}
                    onChange={(_, term) => {
                        if (term)
                            dispatch(termConditionOperations.getTermCondition((term as TermConditionSearchResult).id))
                                .unwrap().then((t) => {
                                    setSelectedTerms(t);
                                    onChange({ type: 'update', value: { termAndConditionTemplate: t.id } });
                                });
                    }}
                    renderOption={((props, option) => (
                        <Box {...props} component={'li'} key={option.id} >
                            <Typography sx={{ mr: 2 }}>{option.name}</Typography>

                        </Box>
                    ))}
                />
                {!selectedTerms ? <GreyCounter label={translate("webshop.messages.noTemplate")} /> :
                    <Box sx={{
                        p: 5,
                        borderRadius: '8px',
                        border: '2px solid',
                        borderColor: theme => theme.palette.grey[400],
                        bgcolor: theme => theme.palette.grey[200],
                        maxHeight: '50vh',
                        overflowY: 'auto'
                    }}>
                        <Typography variant="subtitle2" sx={{ display: "inline-block", whiteSpace: "pre-line" }}>
                            <MarkdownStyle>
                                <Markdown children={getLocalizedString(selectedTerms.text, currentLang)} />
                            </MarkdownStyle>
                        </Typography>
                    </Box>
                }
            </Box>}
            {/*
                manualTerms &&
                <Box sx={{ mt: 2 }}>
                    <RichEditor sx={{ p: 2 }} />
                </Box>
            } */}
            <Divider sx={{ my: 3 }} />
            <StepButtons disabled={!selectedTerms} changeStep={changeStep} confirm onSave={() => onSubmit()} />
        </Card>
    );
}