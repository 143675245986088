import { createSlice } from "@reduxjs/toolkit";
import { Basket } from "src/@types/basket";
import { ErrorResponse } from "src/@types/commons";
import basketOperations from "./basket-operations";

interface BasketReducer {
    error: ErrorResponse,
    basket: Basket | null,
    isBasketLoading: boolean
}

const initialState: BasketReducer = {
    error: null,
    basket: null,
    isBasketLoading: false
};

const BasketSlice = createSlice({
    name: "basket",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(basketOperations.detailPublicBasket.fulfilled, (state, action) => {
                state.basket = action.payload;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.detailPublicBasket.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.addItemPublicBasket.fulfilled, (state, action) => {
                state.basket = action.payload;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.addItemPublicBasket.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.removeItemPublicBasket.fulfilled, (state, action) => {
                state.basket = action.payload;
                state.isBasketLoading = false;
            })
            .addCase(basketOperations.removeItemPublicBasket.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isBasketLoading = false;
            })
            ;
    },
    reducers: {
        startLoading(state) {
            state.isBasketLoading = true;
        },
        resetBasket(state) {
            state.basket = null;
        }
    }
});

export const {
    startLoading,
    resetBasket
} = BasketSlice.actions;

export default BasketSlice.reducer;