import { Box, Typography } from "@mui/material";
import WidgetsIcon from '@mui/icons-material/Widgets';
import HeightIcon from '@mui/icons-material/Height';
import HomeIcon from '@mui/icons-material/Home';
import { useLocales } from "src/locales";
import { LocalShipping } from "@mui/icons-material";

export const LabelDetailStyle = {
    display: 'flex',
    border: '1px solid',
    borderRadius: '8px',
    borderColor: '#919eab3d',
    py: 1, px: 2, gap: 1,
    alignItems: 'center'
};
//-----------------------------------------------------------------------

interface QuantityDetailProps {
    quantity: number
}

export function QuantityDetail({ quantity }: QuantityDetailProps) {

    return (
        <Typography
            variant="subtitle2"
            sx={{ ...LabelDetailStyle }}
        >
            <WidgetsIcon />
            X{quantity}
        </Typography>
    );
}

//-----------------------------------------------------------------------

export function BigAndHeavyDetail() {

    const { translate } = useLocales();

    return (
        <Typography
            variant="subtitle2"
            sx={{ ...LabelDetailStyle }}
        >
            <HeightIcon />
            {`${translate(`request.big`)}`}
        </Typography>
    );
}

//-----------------------------------------------------------------------

interface ApprovedTypeDetailProps {
    approvedType: string
}

export function ApprovedTypeDetail({ approvedType }: ApprovedTypeDetailProps) {

    const { translate } = useLocales();

    return (
        <Typography
            variant="subtitle2"
            sx={{ cursor: 'pointer', ...LabelDetailStyle }}
        >
            <HomeIcon />
            {`${translate(`request.${approvedType[0].toLowerCase() + approvedType.slice(1)}`)}`}
        </Typography>
    );
}

//-----------------------------------------------------------------------

interface ContainerNameDetailProps {
    containerCode: string
}

export function ContainerNameDetail({ containerCode }: ContainerNameDetailProps) {

    const { translate } = useLocales();

    return (
        <Box
            sx={{ ...LabelDetailStyle }}
        >
            <LocalShipping />
            <Box>
                <Typography variant={"subtitle2"}>
                    {translate(`request.containerCode`)}
                </Typography>
                <Typography variant={"subtitle2"}>
                    {containerCode}
                </Typography>
            </Box>
        </Box>
    );
}