import { CustomField, EntityType } from "src/@types/customField";
import { convert } from "./currency";
import { Currency, Lang } from "src/config";
import { CurrencyTypes } from "src/@types/vehicle";
import { Rate } from "src/@types/currency";
import { LocalizedString } from "src/@types/commons";

export type HandleCustomFieldsType = {
    event: any,
    //----------------------------------------
    setValue: any,
    setError: any,
    clearErrors: any,
    customFields: CustomField[],
    setErrorInCustomfields: (value: boolean) => void,
    //----------------------------------------
    dateValue?: string,
    dateFieldName?: string,
    currentCurrency?: Currency,
    rates?: Rate[]
}

//Manages customfields update and validation
export const handleCustomFields = ({
    event,
    setValue,
    setError,
    clearErrors,
    customFields,
    setErrorInCustomfields,
    dateValue,
    dateFieldName,
    currentCurrency,
    rates
}: HandleCustomFieldsType) => {

    const customField = customFields.find(x => x.id === event.target.id)!;

    let checkRegex: boolean = false;

    let newVal = "";

    if (dateValue && dateFieldName) newVal = dateValue;
    else {
        newVal = (customField.type !== "Checkbox") ? event.target.value : (event.target.checked ? "enabled" : "disabled");

        if (customField.type === "Currency" && currentCurrency && rates) {
            newVal = Math.round(convert(Number(event.target.value), currentCurrency.label, CurrencyTypes.EUR, rates)).toString();
        }
    }

    setValue(`customFields.${customField.id}`, newVal);

    if (customField.required) {
        if (customField.type === 'Checkbox') {
            if (newVal === "enabled") clearErrors(`customFields.${customField.id}`);
            else {
                setError(`customFields.${customField.id}`, { type: 'required', message: '' });
                setErrorInCustomfields(true);
            }
        } else if (!newVal || newVal === "") {
            setError(`customFields.${customField.id}`, { type: 'required', message: '' });
            setErrorInCustomfields(true);
        }
        else {
            checkRegex = true;
            clearErrors(`customFields.${customField.id}`);
        }
    }

    if (customField.regEx && checkRegex) {
        const regEx = new RegExp(customField.regEx.slice(1, -1));

        if (regEx && newVal && !regEx.test(newVal)) {
            setError(`customFields.${customField.id}`, { type: 'regExError', message: '' });
            setErrorInCustomfields(true);
        } else clearErrors(`customFields.${customField.id}`);
    }
};

export function getLocalizedString(localizedString: LocalizedString[], currentLang: Lang): string {
    if (!localizedString || localizedString.length <= 0)
        return "—";

    let val = localizedString.find(v => v.culture === currentLang.value)?.text;

    if (val)
        return val;

    return localizedString[0].text || "—";
}

export function getCustomFieldLabel(customFields: CustomField[], id: string, currentLang: Lang): string {
    let custom = customFields.find(cus => cus.id === id);

    if (!custom)
        return "—";

    return getLocalizedString(custom.name, currentLang);

}

type CustomFieldDisplay = {
    //section
    [key: string]: {
        //id
        [key: string]: {
            label: LocalizedString[],
            value: string
        }
    }
}

export function getCustomFieldsDisplay(customFields: CustomField[], customFieldsToShow: Record<string, string>, entityType: EntityType): CustomFieldDisplay {

    let parsed = customFields.filter(cus => cus.entityType === entityType && cus.enabled);

    let customFieldsToDisplay: CustomFieldDisplay = {};

    Object.entries(customFieldsToShow).forEach(([key, value]) => {
        let customField = parsed.find(cus => cus.id === key);

        if (customField) {
            customFieldsToDisplay[customField.section] = {

                ...customFieldsToDisplay[customField.section],

                [customField.id]: {
                    label: customField.name,
                    value: value
                },
            };
        }
    });

    return customFieldsToDisplay;
}