import { Container, Step, StepLabel, Stepper } from "@mui/material";
import { cloneDeep } from "lodash";
import { useReducer, useState } from "react";
import { useParams } from "react-router";
import { GroupSearchResult } from "src/@types/group";
import { BlackWhiteList, DEFAULT_NEW_SHOP, GroupShop, NewShop, Shop } from "src/@types/webshop";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch } from "src/redux/store";
import { webshopOperations } from "src/redux/webshop";
import { PATH_DASHBOARD } from "src/routes/paths";
import CustomerStep from "./CustomerStep";
import DetailStep from "./DetailStep";
import DiscountModelStep from "./DiscountModelStep";
import StockAvailabilityStep from "./StockAvailabilityStep";
import TermsConditionStep from "./TermsConditionStep";
import { handleUpload } from "src/appUtils/UploadHandler";
import LoadingScreen from "src/appComponents/loading-screen";
import { FileWithPreview, FileWithResizeInfo, FileWithSection, ImageInModalType } from "src/@types/media";
import { convert } from "src/utils/currency";
import useCurrency from "src/appHooks/useCurrency";
import { CurrencyTypes } from "src/@types/vehicle";

interface WebshopWizardProps {
    webshop?: Shop
}

type Action = {
    value: Partial<NewShop>
    type: 'update' | 'reset'
};

function reduce(state: NewShop, payload: Action) {
    switch (payload.type) {
        case 'update':
            return { ...state, ...payload.value };
        case 'reset':
            return { ...DEFAULT_NEW_SHOP, ...payload.value };
        default:
            return state;
    }
}

const STEPS = [
    "webshop.form.step.details",
    "webshop.form.step.customers",
    "webshop.form.step.discountModels",
    "webshop.form.step.stockAvailability",
    "webshop.form.step.terms",
    "webshop.form.step.complete",
];

const parseGroup = (group: GroupShop): GroupSearchResult => {

    return {
        groupId: group.groupId,
        displayName: group.groupName,
        enabled: true,
        numberOfOrganizations: group.numberOfOrganizationInGroups,
        numberOfUsers: group.numberOfUserInGroups,
        createdOn: "",
        groupType: "None",
        organizationId: "",
        status: "Active"
    };
};

export default function WebshopWizard({ webshop }: WebshopWizardProps) {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'lg');

    const { currency } = useSettingsContext();

    const { rates } = useCurrency();

    const [step, setStep] = useState(0);

    const { id: isEdit } = useParams();

    const [shop, setShop] = useReducer(reduce, (webshop as NewShop | undefined) ?? DEFAULT_NEW_SHOP, (arg) => {

        //fix first yup check
        const clone = cloneDeep(arg);

        if (isEdit) {
            clone.longDescription.forEach((v) => v.normalText = v.text);
            clone.shortDescription.forEach((v) => v.normalText = v.text);

            clone.maxBackorderQuantity = webshop?.backorderQuantity ?? null;
        }

        return clone;
    });

    const [groupList, setGroupList] = useState<GroupSearchResult[]>(() => {
        if (isEdit && webshop) {
            return webshop.shopAccess.groups.map((group) => parseGroup(group)) as GroupSearchResult[];
        }

        return [];
    });

    const [profileCover, setProfileCover] = useState<(ImageInModalType & File)[]>(() => {
        const files: (ImageInModalType & File)[] = webshop?.media.filter(i => i.section === "profile").map((image) => {
            const f = new File([], image.mediaId ?? "");

            Object.assign(f, { preview: image.fileInfo[0].url ?? "", aspect: 1 });

            return f as (ImageInModalType & File);
        }) ?? [];

        return files;
    });

    const [profilePreview, setProfilePreview] = useState<FileWithPreview[]>(() => {
        const files: FileWithPreview[] = webshop?.media.filter(i => i.section === "profile").map((image) => {
            const f = new File([], image.mediaId ?? "");

            Object.assign(f, { preview: image.fileInfo[0].url ?? "" });

            return f as FileWithPreview;
        }) ?? [];

        return files;
    });

    const [coverImages, setCoverImages] = useState<(ImageInModalType & File)[]>(() => {

        const files: (ImageInModalType & File)[] = webshop?.media.filter(i => i.section === "cover").map((image) => {
            const f = new File([], image.mediaId ?? "");

            Object.assign(f, { preview: image.fileInfo[0].url ?? "", aspect: 16 / 9 });

            return f as (ImageInModalType & File);
        }) ?? [];

        return files;
    });

    const [coverImagesPreview, setCoverImagesPreview] = useState<FileWithPreview[]>(() => {

        const files: FileWithPreview[] = webshop?.media.filter(i => i.section === "cover").map((image) => {
            const f = new File([], image.mediaId ?? "");

            Object.assign(f, { preview: image.fileInfo[0].url ?? "" });

            return f as FileWithPreview;
        }) ?? [];

        return files;
    });

    const [productFile, setProductFile] = useState<FileWithSection[]>([]);

    const [imageUploadLoading, setImageUploadLoading] = useState(false);

    const submit = async () => {

        let out = cloneDeep(shop) as Record<string, any>;

        let idShop = isEdit ?? "";

        //set section and cropinfo 
        const images = coverImages.map(i => {
            return Object.assign(i, {
                section: 'cover',
                imageResizeParameters: i.crop ? {
                    width: i.originalWidth,
                    height: i.originalHeight,
                    cropInfo: i.crop
                } : null
            });

        }).concat(profileCover.map(i => {
            return Object.assign(i, {
                section: 'profile',
                imageResizeParameters: i.crop ? {
                    width: i.originalWidth,
                    height: i.originalHeight,
                    cropInfo: i.crop
                } : null
            });
        })).filter(i => i.size > 0) as FileWithResizeInfo[];

        //replace empty string with null
        for (const key in out) {
            if (out.hasOwnProperty(key) && out[key] === "") {
                out[key] = null;
            }
        }

        //send in eur
        if ((out as NewShop).discountModel?.multiLevel?.thresholds) {
            (out as NewShop).discountModel?.multiLevel?.thresholds.forEach((level) => {

                level.toAmount = +convert(level.toAmount, currency.label, CurrencyTypes.EUR, rates).toFixed(2);
                level.fromAmount = +convert(level.fromAmount, currency.label, CurrencyTypes.EUR, rates).toFixed(2);
            });
        }

        try {

            if (!isEdit) {
                idShop = (await dispatch(webshopOperations.createShop(out as NewShop)).unwrap()).id;
            }
            else {
                await dispatch(webshopOperations.updateShop({ webShop: out as NewShop, id: isEdit })).unwrap();
            }

            if (images.length > 0 || productFile.length > 0) {
                setImageUploadLoading(true);
                await handleUpload(productFile, idShop, 'Shop', undefined, 'Bundle');
                await handleUpload(images, idShop, "Shop", (v) => setImageUploadLoading(v));
            }

            dispatch(setSuccessMessage({ text: translate('webshop.messages.webshopSuccess'), returnTo: PATH_DASHBOARD.webshop.list }));
        }
        catch {
            setImageUploadLoading(false);
        }
    };

    const handleLists = (lists: {
        groupList: GroupSearchResult[],
        blackList: BlackWhiteList[],
        whiteList: BlackWhiteList[]
    }) => {
        setGroupList(lists.groupList);

        setShop({ type: 'update', value: { shopAccess: { blackList: lists.blackList, whiteList: lists.whiteList, groupIds: lists.groupList.map(v => v.groupId) } } });
    };

    const STEPS_COMPONENT = [
        <DetailStep
            key={0}
            changeStep={setStep}
            onSubmit={setShop}
            state={shop}
            carousel={coverImages}
            setCarousel={setCoverImages}
            carouselPreview={coverImagesPreview}
            setCarouselPreview={setCoverImagesPreview}
            profileCover={profileCover}
            setProfileCover={setProfileCover}
            profilePreview={profilePreview}
            setProfilePreview={setProfilePreview}
        />,
        <CustomerStep key={1} changeStep={setStep} onSubmit={handleLists} groupList={groupList} blackWhiteLists={{ black: shop?.shopAccess.blackList ?? [], white: shop?.shopAccess.whiteList ?? [] }} />,
        <DiscountModelStep key={2} changeStep={setStep} onSubmit={setShop} state={shop} />,
        <StockAvailabilityStep key={3} changeStep={setStep} onFileChange={(f) => setProductFile(f)} />,
        <TermsConditionStep key={4} state={shop} changeStep={setStep} onSubmit={() => submit()} onChange={setShop} />
    ];

    return (
        <>
            {imageUploadLoading ? <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={!isEdit ? translate("menu.management.webshop.admin.new") : translate("menu.management.webshop.admin.edit")}
                        links={[
                            { name: `${translate("commons.home")}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("menu.management.webshop.admin.title")}`, href: PATH_DASHBOARD.webshop.list },
                            { name: isEdit ? translate(`commons.edit`) : translate(`commons.new`) },
                        ]}
                    />
                    <Stepper
                        sx={{ mb: 2, px: isDesktop ? 5 : 2, }}
                        activeStep={step}
                        alternativeLabel
                    >
                        {STEPS.map((st) => (
                            <Step key={st}>
                                <StepLabel>{translate(st)}</StepLabel>
                            </Step>
                        ))}

                    </Stepper>
                    {STEPS_COMPONENT[step]}
                </Container>}
        </>
    );
}