import { Box, Card, Divider, Stack, Switch, TextField, Typography } from '@mui/material';
import { RequestNew } from 'src/@types/request';
import { useLocales } from 'src/locales';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useState } from 'react';
import { OrderDetailItem } from 'src/@types/orders';
import { OrganizationSearchResult } from 'src/@types/organizations';
import { DeliveryAddressAutocomplete } from './DetailNewParts';
import IncrementalInput from 'src/utils/IncrementalInput';
import { SparePartSearchResult } from 'src/@types/spareParts';
import { maxQuantityMissing } from 'src/services/requestsServices';

interface DetailWrongProps {
  request: RequestNew,
  onChange: (name: string, value: any, item?: boolean) => void,
  available: number,
  defaultAddress: OrganizationSearchResult,
  isVendor: boolean,
  organization: string,
  parts: OrderDetailItem[] | null,
  keep: boolean,
  exceededParts: (SparePartSearchResult & { quantity: number })[]
}

export default function DetailWrong({ request, onChange, available, defaultAddress, isVendor, organization, parts, keep, exceededParts }: DetailWrongProps) {

  const { translate } = useLocales();

  const [otherPart, setOtherPart] = useState(keep);

  const [keepThePart, setKeepThePart] = useState(keep);

  const [otherParts, setOtherParts] = useState<(SparePartSearchResult & { quantity: number })[]>(exceededParts);

  const [bigAndHeavy, setBigAndHeavy] = useState(request.items[0]?.bigAndHeavy || false);

  const [availableQty, setAvailableQty] = useState<{ id: string, qty: number }[]>([]);

  useEffect(() => {
    if (request.requestReason === "MissingParts") {

      request.items.forEach((i) => maxQuantityMissing({ orderId: i.orderId!, productId: i.productId })
        .then(v => setAvailableQty(prev => prev.concat([{ id: i.productId, qty: v.data.quantity }]))));
    }

  }, []);

  const handleBig = (val: boolean) => {
    setBigAndHeavy(val);

    let exceeded = request.items.map(item => ({ ...item, bigAndHeavy: val }));

    onChange("", exceeded, true);
  };

  const handleQuantity = (ind: number, quantity: number, other?: boolean) => {

    if (other) {
      setOtherParts((prev) => {
        let arr = [...prev];
        arr[ind].quantity =
          quantity > 0 ? quantity : arr[ind].quantity;

        onChange("exceed", arr);

        return arr;
      });

      let { exceededItems } = request;

      exceededItems[ind].quantity = quantity;

      onChange("exceededItems", exceededItems);
    }
    else {
      let { items } = request;

      items[ind].quantity = quantity;

      onChange("", items, true);

      if (request.requestReason === "SurplusDelivered")
        setOtherParts((prev) => {
          let arr = [...prev];
          arr[ind].quantity =
            quantity > 0 ? quantity : arr[ind].quantity;

          return arr;
        });
    }
  };

  useEffect(() => {
    if (keepThePart && request.requestReason === "SurplusDelivered") {
      onChange("deliveryCompanyId", [null, ""]);

      handleBig(false);
    }

    onChange("keep", keepThePart);
  }, [keepThePart]);

  return (
    <>
      {request.requestReason === 'SurplusDelivered' && (
        <Card sx={{ my: 2, width: '100%', flexDirection: 'column', gap: 2, display: 'flex', p: 2 }}>

          <Typography variant="subtitle1">{`${translate('request.form.complete')}`}</Typography>

          {otherParts && otherParts.map((p, ind) => (
            <Box key={ind} sx={{ m: 3, display: 'flex', width: '100%', flexDirection: 'row' }}>
              <Box sx={{ mr: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
                <SettingsIcon />
                <Typography>
                  {p.externalId ? p.externalId + ' - ' : ''}
                  {p.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  mr: 3,
                  ml: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'end',
                }}
              >

                <Typography color={'text.secondary'} variant="caption" sx={{ pr: 2 }}>
                  {`${translate('request.form.qtyReceived')}`}
                </Typography>

                <IncrementalInput
                  onChange={(_, val) => handleQuantity(ind, +val)}
                  defaultValue={
                    request.items.find(v => v.productId === p.id)?.quantity || 0
                  }
                  disabled={request.requestType === 'Core'}
                />
              </Box>
            </Box>
          ))}

          <Divider sx={{ my: 2 }} />

          <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Stack sx={{ mb: 2, mx: 2, mr: 'auto' }}>
              <Typography variant="h6">{`${translate('request.messages.keepPart')}`}</Typography>
            </Stack>
            <Box sx={{ mb: 2, mx: 2, ml: 'auto' }}>
              <Switch
                checked={keep}
                name="keepThePart"
                onChange={(e) => setKeepThePart(e.target.checked)}
              />
            </Box>
          </Box>

          {!keepThePart &&
            <>
              <Divider />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  borderRadius: '8px',
                  border: '1px solid',
                  borderColor: '#E2E2E2',
                }}
              >
                <Stack sx={{ m: 2, mr: 'auto' }}>
                  <Typography variant="h6">{`${translate('request.big')}`}</Typography>
                  <Typography variant="body2">{`${translate('request.messages.bigDesc')}`}</Typography>
                </Stack>
                <Box sx={{ m: 2, ml: 'auto' }}>
                  <Switch
                    value={bigAndHeavy}
                    name="bigAndHeavy"
                    onChange={(e) => handleBig(e.target.checked)}
                  />
                </Box>
              </Box>
            </>
          }

          {!keepThePart &&
            <DeliveryAddressAutocomplete onChange={onChange} defaultAddress={defaultAddress} organization={organization} isVendor={isVendor} />
          }

        </Card>
      )}

      {request.requestReason === 'MissingParts' && (
        <Card sx={{ my: 2, width: '100%', flexDirection: 'column', gap: 2, display: 'flex', p: 2 }}>

          <Typography variant="subtitle1">{`${translate('request.form.complete')}`}</Typography>

          {parts && parts.map((p, ind) => (
            <Box key={ind} sx={{ m: 3, display: 'grid', width: '100%', gridTemplateColumns: '0.8fr 1.2fr 1fr' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <SettingsIcon />
                <Typography>
                  {p.externalId ? p.externalId + ' - ' : ''}
                  {p.name}
                </Typography>
              </Box>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <TextField
                  value={request.customFields ? request.customFields["missing-items-parcel-" + p.itemIndex + "-" + p.id] || "" : ""}
                  sx={{
                    width: '70%',
                    '& .MuiInputLabel-asterisk': { color: 'error.main' }
                  }}
                  label={`${translate('request.form.parcelRef')}`}
                  required
                  onChange={(e) => {
                    onChange("customField", ["missing-items-parcel-" + p.itemIndex + "-" + p.id, e.target.value]);
                  }}
                />
              </Box>
              <Box
                sx={{
                  mr: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  width: '100%'
                }}
              >

                <Typography color={'text.secondary'} variant="caption" sx={{ pr: 2 }}>
                  {`${translate('request.form.qtyNotReceived')}`} <span style={{ color: 'red' }}>*</span> : {availableQty.find(i => i.id === p.id)?.qty || 0}
                </Typography>

                <IncrementalInput
                  maxValue={availableQty.find(i => i.id === p.id)?.qty || 0}
                  onChange={(_, val) => handleQuantity(ind, +val)}
                  defaultValue={
                    available > 0 ?
                      (request.requestType === 'Core' ? 1 : request.items[ind].quantity) : 0
                  }
                  disabled={request.requestType === 'Core'}
                />

              </Box>
            </Box>
          ))}

          <Box>
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Stack sx={{ mb: 2, mx: 2, mr: 'auto' }}>
                <Typography variant="h6">{`${translate('request.messages.anotherPart')}`}</Typography>
              </Stack>
              <Box sx={{ mb: 2, mx: 2, ml: 'auto' }}>
                <Switch onChange={(e) => {
                  setOtherPart(e.target.checked);
                  onChange("keep", e.target.checked);
                }} checked={keep} />
              </Box>
            </Box>
            {otherPart && (
              <TextField
                label={`${translate('request.detail.insertWrong')}`}
                fullWidth
                name='note'
                value={request.note}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            )}
          </Box>

          <Divider />
          <Box>
            <DeliveryAddressAutocomplete onChange={onChange} defaultAddress={defaultAddress} isVendor={isVendor} organization={organization} />
          </Box>
        </Card>
      )}
    </>
  );
}
