import { Box, Button, Card, Modal, Typography } from "@mui/material";
import { BasketParams } from "src/@types/basket";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { basketOperations } from "src/redux/basket";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch } from "src/redux/store";

interface RemoveProductCartModalProps {
    removeParams?: BasketParams,
    open: boolean,
    onClose: VoidFunction
}

export default function RemoveProductCartModal({ onClose, open, removeParams }: RemoveProductCartModalProps) {

    const isDesktop = useResponsive('up', 'lg');

    const { translate } = useLocales();

    const confirmDelete = async () => {
        try {
            if (removeParams?.shopId && removeParams.bundleId) {
                await dispatch(basketOperations.removeItemPublicBasket(removeParams)).unwrap();

                dispatch(setSuccessMessage({ text: translate('basket.messages.itemsRemoved') }));
            }
        }
        finally {
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? '25vw' : '70vw',
                    p: isDesktop ? 4 : 2,
                }}
            >
                <Box sx={{ textAlign: "center" }}>

                    <Box component="img" src="/assets/illustrations/illustration_empty_mail.svg" sx={{ width: "45%", m: "auto" }} />

                    <Typography variant="h6" sx={{ mt: 3 }}>
                        {translate(`basket.messages.confirmRemoval`)}
                    </Typography>

                    <Typography variant='body1'>

                        {translate(`basket.messages.removalQuestion`)}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>

                        <Button
                            variant="contained"
                            size={isDesktop ? "small" : "medium"}
                            onClick={() => onClose()}
                            sx={{ mt: 3, borderRadius: "100px" }}
                        >
                            {`${translate('commons.close')}`}
                        </Button>

                        <Button
                            variant="contained"
                            size={isDesktop ? "small" : "medium"}
                            onClick={() => confirmDelete()}
                            color="error"
                            sx={{ mt: 3, borderRadius: "100px" }}
                        >
                            {translate('commons.remove')}
                        </Button>

                    </Box>
                </Box>
            </Card>
        </Modal>
    );
}