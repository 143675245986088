import { Box, Card, Divider, Drawer, FormControlLabel, IconButton, Switch, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Order, OrderDetailItem } from "src/@types/orders";
import useTable from "src/appHooks/useTable";
import { noData } from "src/components/empty-content/EmptyContent";
import { useLocales } from "src/locales";
import { CloseIcon } from "src/theme/overrides/CustomIcons";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { useMemo, useState } from "react";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useAuth } from "src/appHooks/useAuth";
import { PriceAmount } from "src/@types/currency";

interface OrderDetailItemsProps {
    order: Order
}

export default function OrderDetailItems({ order }: OrderDetailItemsProps) {

    const { translate } = useLocales();

    const { user } = useAuth();

    const { dense, onChangeDense } = useTable();

    const [open, setOpen] = useState(false);

    const [currentItem, setCurrentItem] = useState<OrderDetailItem>();

    const columns: GridColDef[] = [{
        field: 'name',
        headerName: `${translate('commons.name')}`,
        flex: 1.25,
        headerAlign: 'left',
        disableColumnMenu: true,
        sortable: false
    },
    {
        field: 'quantity',
        headerName: `${translate('commons.quantity')}`,
        flex: 1.25,
        headerAlign: 'left',
        disableColumnMenu: true,
        sortable: false
    },
    {
        field: 'priority',
        headerName: `${translate('request.priority')}`,
        flex: 1.25,
        headerAlign: 'left',
        disableColumnMenu: true,
        sortable: false
    },
    {
        field: 'regularDiscountPercentage',
        headerName: `${translate('orders.detail.regularDiscountPercentage')}`,
        flex: 1.25,
        headerAlign: 'left',
        disableColumnMenu: true,
        sortable: false,
        renderCell: (e) => {
            return <Typography>{e.value ? (e.value * 100).toFixed(2) + " %" : '—'}</Typography>;
        }
    },
    {
        field: 'arrow',
        headerName: ``,
        headerAlign: 'left',
        flex: 0.5,
        renderCell: () => <ArrowOutwardIcon />,
        sortable: false
    }];

    return (
        <Card>
            <Box paddingBottom={1}>
                <Drawer
                    onClose={() => { setOpen(false); }}
                    PaperProps={{ sx: { width: '60%' } }}
                    open={open}
                    anchor='right'
                >
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2, alignItems: 'center' }}>
                            <Typography variant="h5">
                                {`${translate('orders.detail.itemDetail')}`}
                            </Typography>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Divider />
                        <ItemDetail currentItem={currentItem!} showPurchasePrice={!!user.organizations.find((v) => v.organizationId === order.vendor.id)} />
                    </Box>
                </Drawer>

                <DataGrid
                    rows={order.items}
                    columns={columns}
                    density={dense ? 'compact' : 'standard'}
                    disableColumnResize
                    slots={{
                        noRowsOverlay: noData,
                        footer: () => (
                            <Box sx={{ position: 'relative', mb: 7 }} >
                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={`${translate('commons.dense')}`}
                                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }} />
                            </Box>
                        )
                    }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    onRowClick={(e) => {
                        setCurrentItem(order.items.find(v => v.id === e.id));
                        if (!window.getSelection()?.toString())
                            setOpen(true);
                    }}
                    sx={{ ...DataGridStyle, maxHeight: 640 }}
                />

            </Box>
        </Card>
    );
}

function ItemDetail({ currentItem, showPurchasePrice }: { currentItem: OrderDetailItem, showPurchasePrice: boolean }) {

    const { translate } = useLocales();

    const PARAGRAPHS = useMemo(() => {
        return [
            {
                name: "purchasePrice",
                value: currentItem.purchasePrice
            },
            {
                name: "cataloguePrice",
                value: currentItem.cataloguePrice
            },
            {
                name: "grossAmount",
                value: currentItem.grossAmount
            },
            {
                name: "netAmount",
                value: currentItem.netAmount
            },
            {
                name: "regularDiscountAmount",
                value: currentItem.regularDiscountAmount
            },
            {
                name: "extraDiscountAmount",
                value: currentItem.extraDiscountAmount
            },
            {
                name: "totalDiscountAmount",
                value: currentItem.totalDiscountAmount
            },
        ];

    }, [currentItem]);

    return (
        <Box padding={3}>
            <Typography variant="h6">{currentItem.name}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('commons.quantity')}`}
                    value={currentItem.quantity.toString() || '—'}
                    left={7}
                    right={5}
                />
                <GridLabeledText
                    label={`${translate('request.priority')}`}
                    value={currentItem.priority || '—'}
                />
                <GridLabeledText
                    label={`${translate('orders.detail.regularDiscountPercentage')}`}
                    value={currentItem.regularDiscountPercentage ? (currentItem.regularDiscountPercentage * 100).toFixed(2) + " %" : '—'}
                    left={7}
                    right={5}
                />
                <GridLabeledText
                    label={`${translate('orders.detail.forcedDiscount')}`}
                    value={currentItem.forcedDiscount.toString() || '—'}
                />
                <GridLabeledText
                    label={`${translate('orders.detail.specialPrice')}`}
                    value={currentItem.specialPrice.toString() || '—'}
                    left={7}
                    right={5}
                />
            </Box>
            <Divider sx={{ my: 2 }} />

            {PARAGRAPHS.map((paragraph) =>
                paragraph.name === "purchasePrice" ?
                    (showPurchasePrice &&
                        <ItemDetailParagraph
                            key={paragraph.name}
                            name={paragraph.name}
                            amountValue={paragraph.value}
                        />
                    ) : (
                        <ItemDetailParagraph
                            key={paragraph.name}
                            name={paragraph.name}
                            amountValue={paragraph.value}
                        />
                    )
            )}

        </Box>
    );
}

function ItemDetailParagraph({ name, amountValue }: { name: string, amountValue: PriceAmount }) {

    const { translate } = useLocales();

    if (!amountValue)
        return <></>;

    return (
        <>
            <Typography variant="overline">{`${translate(`orders.${name}`)}`}</Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'grid',
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <GridLabeledText
                    label={`${translate('orders.detail.withoutVat')}`}
                    value={amountValue.withoutVat ? amountValue.withoutVat.toString() : '—'}
                    currentCurrency={amountValue.currentCurrency}
                />
                <GridLabeledText
                    label={`${translate('orders.detail.withVat')}`}
                    value={amountValue.withVat ? amountValue.withVat.toString() : '—'}
                    currentCurrency={amountValue.currentCurrency}
                />
            </Box>
            <Divider sx={{ my: 2 }} />
        </>
    );
} 