import { CustomField } from "./customField";

export type TenantType = {
    id: string;
    hostnames: string[];
    title: string;
    company: CompanyType;
    countries: string[];
    timezoneInfo: string;
    wallpapers: string[];
    emailNotification: EmailNotificationType;
    analytics: AnalyticsType;
    licenses: LicenseTypes[];
    customFields: CustomField[];
    refreshCustomFields: () => Promise<void>;
    startFrom: Date,
    endTo: Date,
    status: number
};

export type AnalyticsType = {
    enabled: boolean;
    script: string;
}

export type CompanyType = {
    name: string;
    vat: string;
    address: string;
    zipcode: string;
    city: string;
    phone: string;
    country: string;
}

export type EmailNotificationType = {
    replyTo: string;
    displayName: string;
    username: string;
    password: string;
}

export enum LicenseTypes {
    Core = "Core",
    Rv = "RvMatrix",
    ProductVehicle = "ProductVehicle",
    ProductSparePart = "ProductSparePart",
    WebShop = "Shop",
    Report = "Report",
    Shipping = "Shipping",
    Group = "Group",
    Notification = "Notification"
}