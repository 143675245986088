import { ClusterPermissionsList, LicensePermissionMap, OrganizationPermissionsList, Permission, PermissionCategory } from "src/@types/permissions";
import { TenantType, LicenseTypes } from "src/@types/tenant";
import { hasLicense } from "./tenant";
import { lokalizableRoleStr } from "src/pages/dashboard/role/utils";

export function getOrgPermissionListObj(tenant: TenantType, t: (text: unknown, options?: any) => string) {

    let permissionsList: Permission[] = [];

    //iterate for every license
    Object.values(LicenseTypes).forEach((license) => {

        //check if tenat has license
        if (hasLicense(tenant, license)) {

            //for each category mapped to the license (generally 1 or 2) add all associated permissions to the list
            LicensePermissionMap[license].forEach(type => {
                permissionsList = permissionsList
                    .concat(OrganizationPermissionsList[type]
                        .map(perm => ({
                            id: perm,
                            label: t(`role.permission.${lokalizableRoleStr(perm)}.title`),
                            type
                        })));
            });
        }
    });

    return permissionsList;
}

export function getClusterPermissionListObj(tenant: TenantType, t: (text: unknown, options?: any) => string) {
    let permissions: Permission[] = [];

    Object.entries(ClusterPermissionsList).forEach(([category, permlist]) => {
        if (category === "Notifications" && !hasLicense(tenant, LicenseTypes.Notification))
            return;

        permlist.forEach(v => {
            permissions.push({
                id: v,
                label: t(`role.permission.${lokalizableRoleStr(v)}.title`),
                type: category as PermissionCategory
            });
        });
    });

    return permissions;
}

export function getPermissionCategories(tenant: TenantType): PermissionCategory[] {
    let categories: PermissionCategory[] = [];

    Object.values(LicenseTypes).forEach((license) => {
        if (hasLicense(tenant, license))
            LicensePermissionMap[license].forEach(type => categories.push(type));
    });

    return categories;
}