import { Button, Container, MenuItem } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useNavigate, } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import { isEqual, omit } from 'lodash';
import { convertArrayToSelectOptions, FilterListType } from 'src/@types/list';
import Iconify from 'src/components/iconify';
import { DEFAULT_DISCOUNT_MATRIX_FILTERS, DiscountMatrixFilters, DiscountMatrixSearchResult, EnableDisableDiscountMatrixProps } from 'src/@types/discountMatrix';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { setFiltersInUrl } from 'src/redux/discountMatrix/discountMatrix-slices';
import { discountMatrixOperations } from 'src/redux/discountMatrix';
import DateZone from 'src/appComponents/DateZone';

export default function DiscountMatrixList() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isLoading, discountMatrixList, totalCount, filtersInUrl } = useSelector((state: RootState) => state.discountMatrix);

    const extraSearchFiltersChecks = useCallback((searchFilters: DiscountMatrixFilters) => {

        switch (searchFilters.statusSidebar) {
            case "all":
                searchFilters.enabled = undefined;
                break;
            case "Enabled":
                searchFilters.enabled = true;
                break;
            case "Disabled":
                searchFilters.enabled = false;
                break;
        };

        return searchFilters;
    }, []);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: DiscountMatrixFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_DISCOUNT_MATRIX_FILTERS[field])
        , []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((filters: { filters: DiscountMatrixFilters, check: boolean }) => {
        const cleanedFilters: DiscountMatrixFilters = omit(filters.filters, "statusSidebar") as DiscountMatrixFilters;

        filters.filters = cleanedFilters;

        dispatch(discountMatrixOperations.searchDiscountMatrixes(filters));
    }, []);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: FilterListType[] = useMemo(() => [
        {
            name: "name",
            label: translate(`commons.name`),
            type: "TextField"
        },
        {
            name: "statusSidebar",
            label: translate('commons.status'),
            type: "Select",
            options: convertArrayToSelectOptions(["Enabled", "Disabled"], translate, "statuses.")
        }
    ], [translate]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.termsCondition.detailTab(params.row.id, "en"));
        }
    };

    const handleEnableDisable = useCallback(async (id: string, action: "enable" | "disable") => {

        const options: EnableDisableDiscountMatrixProps = {
            id: id,
            action: action
        };

        let x = await dispatch(discountMatrixOperations.enableDisableDiscountMatrix(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "enable" ? 'commons.enabled' : 'commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, translate]);

    const COLUMNS: GridColDef<DiscountMatrixSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('commons.name'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: true,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.createdOn)}
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'enabled',
            headerName: translate('commons.enabled'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Label
                        color={obj.row.enabled ? 'success' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {obj.row.enabled ? translate("commons.enabled") : translate("commons.disabled")}
                    </Label>
                );
            }
        },
        {
            field: 'isDefault',
            headerName: translate('termsCondition.form.default'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Iconify
                        icon={'eva:checkmark-fill'}
                        sx={{
                            width: 25,
                            height: 25,
                            color: 'success.main',
                            ...(!obj.row.default && { color: (theme) => theme.palette.grey[300] })
                        }}
                    />
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleEnableDisable={handleEnableDisable}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, actualRow, handleEnableDisable]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={`${translate(`menu.management.discountMatrix.title`)}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate(`menu.management.discountMatrix.title`)}`}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate(`discountMatrix.title`) }
                    ]}
                    action={
                        <Button
                            variant={"contained"}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                            onClick={() => navigate(PATH_DASHBOARD.discountMatrix.new)}
                            sx={{ borderRadius: "100px" }}
                        >
                            {translate('discountMatrix.upload')}
                        </Button>
                    }
                />

                <InfiniteScrollGenericList
                    list={discountMatrixList}
                    isLoading={isLoading}
                    totalCount={totalCount}
                    defaultFilters={DEFAULT_DISCOUNT_MATRIX_FILTERS}
                    filtersInSidebar={filtersInSidebar}
                    datagridColumns={COLUMNS}
                    updateCheckField={updateCheckField}
                    extraSearchFiltersChecks={extraSearchFiltersChecks}
                    context={"DiscountMatrix"}
                    setActualRow={setActualRow}
                    handleCellClick={handleCellClick}
                    customSearchFunc={customSearch}
                    filtersInUrl={filtersInUrl}
                    setFiltersInUrl={setFiltersInUrl}
                    listDescription={translate('discountMatrix.subtitle')}
                    resetList={() => { }}
                />

            </Container>
        </Page >
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: GridRenderCellParams<DiscountMatrixSearchResult, any, any, GridTreeNodeWithRender>,
    currentRow: any,
    handleEnableDisable: (id: string, action: "enable" | "disable") => Promise<void>,
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleEnableDisable }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && (object.id as string) === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.TermsAndCondition_EnableDisable]}>
                        <MenuItem
                            onClick={() => {
                                if (object.row.enabled) handleEnableDisable(object.id as string, "disable");
                                else handleEnableDisable(object.id as string, "enable");

                                handleCloseMenu();
                            }}
                            sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                        >
                            {!object.row.enabled ? `${translate("commons.enable")}` : `${translate("commons.disable")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}