import { INFINITE_SCROLL_GENERIC_LIST_FILTERS, InfiniteScrollGenericListFilters, SequenceToken } from "./commons";

//---- DISCOUNT MATRIX -----------------------------------------------------------------

export interface DiscountMatrixSearchResult extends SequenceToken {
    discountMatrixId: string,
    name: string,
    default: boolean,
    enabled: boolean,
    createdOn: Date
}

export interface DiscountMatrixFilters extends InfiniteScrollGenericListFilters {
    [key: string]: any
    name?: string,
    enabled?: boolean | undefined,
    statusSidebar?: "Enabled" | "Disabled" | "all"
}

export const DEFAULT_DISCOUNT_MATRIX_FILTERS: DiscountMatrixFilters = {
    ...INFINITE_SCROLL_GENERIC_LIST_FILTERS,
    name: "",
    enabled: undefined,
    statusSidebar: "all"
};

export type EnableDisableDiscountMatrixProps = {
    id: string,
    action: "enable" | "disable"
}

//---- DISCOUNT MATRIX - ROWS -----------------------------------------------------------------

export type DiscountCodeType = typeof DiscountCodeArr[number];

export const DiscountCodeArr = ["", "DiscountCode", "MarketingFamily", "Niche", "Origin"] as const;

export type OrderPriorityType = typeof OrderPriorityArr[number];

export const OrderPriorityArr = ["", "None", "Stock", "Urgent"] as const;

export interface DiscountMatrixRowsSearchResult extends SequenceToken {
    score: number,
    id: string,
    rowIndex: number,
    brandId: string,
    customerExternalId: string,
    customerCategoryCode: string,
    customerCategoryDescription: string,
    discountScaleCode: string,
    discountScaleDesignation: string,
    discountType: DiscountCodeType,
    saleDiscountCode: string,
    familySupplierCode: string,
    orderPriority: OrderPriorityType,
    regularDiscountPercentage: number,
    additionalDiscountPercentage: number
}

export interface DiscountMatrixRowsFilters extends InfiniteScrollGenericListFilters {
    [key: string]: any
    brands: string[],
    customerExternalId: string,
    discountScaleCode: string,
    customerCategoryCode: string,
    saleDiscountCode: string,
    familySupplierCode: string,
    orderPriority: string
}

export const DEFAULT_DISCOUNT_MATRIX_ROWS_FILTERS: DiscountMatrixRowsFilters = {
    ...INFINITE_SCROLL_GENERIC_LIST_FILTERS,
    brands: [],
    customerExternalId: "",
    discountScaleCode: "",
    customerCategoryCode: "",
    saleDiscountCode: "",
    familySupplierCode: "",
    orderPriority: ""
};