import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Box, FormControlLabel, Switch, TablePagination, Divider, Typography, MenuItem } from '@mui/material';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import useTable from 'src/appHooks/useTable';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import { DEFAULT_DOCUMENTS_FILTERS, DocType, DocumentFilters, MAX_PRICE_DOC_FILTER } from 'src/@types/orders';
import { ordersOperations } from 'src/redux/orders';
import { isArray, isEqual, isObject, remove } from 'lodash';
import { GridCellParams, GridSortModel, DataGrid } from '@mui/x-data-grid';
import { noData } from 'src/components/empty-content/EmptyContent';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { fileThumb } from 'src/components/file-thumbnail';
import DateZone from 'src/appComponents/DateZone';
import { useSettingsContext } from 'src/components/settings';
import { convert, getPrice } from 'src/utils/currency';
import useCurrency from 'src/appHooks/useCurrency';
import { CurrencyTypes } from 'src/@types/vehicle';
import useResponsive from 'src/hooks/useResponsive';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { detailGeneric } from 'src/services/organizationsService';
import { Currency } from 'src/config';
import { LicenseTypes } from 'src/@types/tenant';
import ReportCreationModal from 'src/sections/@dashboard/report/ReportCreationModal';
import { InvoiceReportFilters } from 'src/@types/report';
import { reportsOperations } from 'src/redux/reports';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import DocumentsFilterSidebar from 'src/sections/@dashboard/documents/filters/DocumentsFilterSidebar';
import DocumentsFilterSummary from 'src/sections/@dashboard/documents/filters/DocumentsFilterSummary';
import DocumentsGeneralFilters from 'src/sections/@dashboard/documents/filters/DocumentsGeneralFilters';
import LicenseGuard from 'src/guards/LicenseGuard';

const BASIC_FILTER_OPTIONS = {
  pageIndex: 0,
  pageSize: 10,
  sortField: '',
  sortAscending: false,
};

interface OrganizationDocumentsProps {
  organizationID?: string;
  isVendor: boolean,
}

export default function DocumentsList({ organizationID, isVendor }: OrganizationDocumentsProps) {

  const {
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    dense,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage
  } = useTable();

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const { translate, currentLang } = useLocales();

  const { organizationId } = useUserOrganizationContext();

  const { isDocumentLoading: isLoading, documentList, documentTotalCount: totalCount, documentPageIndex: pageIndex, documentPageSize: pageSize } = useSelector((state: RootState) => state.orders);

  const [filters, setFilters] = useState<DocumentFilters>({ ...DEFAULT_DOCUMENTS_FILTERS });

  const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

  const [openSidebar, setOpenSidebar] = useState(false);

  const [resetForm, setResetForm] = useState(true);

  const [resetFormElement, setResetFormElement] = useState('');

  //---- MANAGING CURRENCY CHANGE - START ----//
  const { currency: currentCurrency } = useSettingsContext();

  const [oldCurrency, setOldCurrency] = useState<Currency>(currentCurrency);

  const { rates } = useCurrency();

  const MAX_PRICE_CONVERTED = useMemo(() => Math.round(convert(MAX_PRICE_DOC_FILTER, CurrencyTypes.EUR, currentCurrency.label, rates)), [currentCurrency, rates]);
  //---- MANAGING CURRENCY CHANGE - START ----//

  const methods = useForm({
    defaultValues: {
      ...DEFAULT_DOCUMENTS_FILTERS,
      maxAmount: MAX_PRICE_CONVERTED
    } as DocumentFilters,
  });

  const reportFilters: InvoiceReportFilters = useMemo(() => ({
    all: filters.all || null,
    customFields: filters.customFields || null,
    externalId: filters.externalId || null,
    requestType: filters.requestType || null,
    status: filters.status as string[] || null,
    customerId: filters.customerId || !isVendor ? organizationId : null,
    maxAmount: filters.maxAmount ? {
      withoutVat: filters.maxAmount,
      currentCurrency: currentCurrency.label
    } : null,
    minAmount: filters.minAmount ? {
      withoutVat: filters.minAmount,
      currentCurrency: currentCurrency.label
    } : null,
    vendorId: filters.organizationId || isVendor ? organizationId : null,
    tags: filters.tags || null
  }), [filters, currentCurrency, isVendor, organizationId]);

  const { reset, getValues, watch, setValue } = methods;

  var formValues = watch();

  const getMinMaxPriceConverted = useCallback((from: any, to: any, values: any) => {
    const min = values[0] ? Math.round(convert(values[0], from, to, rates)) : values[0];

    const max = Math.round(convert(values[1], from, to, rates));

    return [min, max];
  }, [rates]);

  useEffect(() => {
    dispatch(reportsOperations.getTemplates("document"));
  }, []);

  useEffect(() => {
    if (oldCurrency.label !== currentCurrency.label) {

      const [min, max] = getMinMaxPriceConverted(oldCurrency.label, currentCurrency.label, [formValues.minAmount, formValues.maxAmount]);

      const valuesFormatted = {
        ...formValues,
        minAmount: min,
        maxAmount: max
      };

      if (JSON.stringify(valuesFormatted) !== JSON.stringify(formValues)) {
        setValue('minAmount', min);
        setValue('maxAmount', max);
        setOldCurrency(currentCurrency);
      }

    }
  }, [currentCurrency, oldCurrency, rates, getMinMaxPriceConverted, formValues, setValue]);

  //---- IS DEFAULT - START ----//
  // Checks if there are some filters selected
  const keysToRemove: string[] = useMemo(() => ['pageIndex', 'pageSize', 'sortField', 'sortAscending', 'from', 'to', 'all'], []);

  const keyRemover = useCallback((key: string) => {
    return !keysToRemove.includes(key);
  }, [keysToRemove]);

  const isDefault = useCallback((filter: DocumentFilters, controller?: DocumentFilters) => {

    const check = controller ? controller : { ...DEFAULT_DOCUMENTS_FILTERS, maxAmount: MAX_PRICE_CONVERTED };

    const found = remove(Object.keys(check), keyRemover)
      .find((element) => {
        if (filter[element]) {
          if (isArray(filter[element]) && isArray(check[element])) {
            return !isEqual(filter[element], check[element]);
          } else if (isObject(filter[element]) && isObject(check[element])) {

            const strFilters = JSON.stringify(filter[element], Object.keys(filter[element]).sort());

            const strDefaultFilters = JSON.stringify(check[element], Object.keys(check[element]).sort());

            return strFilters !== strDefaultFilters;
          } else {

            if (element === "maxAmount" && oldCurrency.label !== currentCurrency.label) {

              return Math.round(convert(filter[element]!, oldCurrency.label, currentCurrency.label, rates)) !== check[element]!;

            }

            return filter[element] !== check[element];
          }
        }

        return false;
      });

    return (!found);
  }, [keyRemover, MAX_PRICE_CONVERTED, currentCurrency, oldCurrency, rates]);
  //---- IS DEFAULT - END ----//

  //---- CLOSE AND OPEN SIDEBAR FUNC ----//
  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    if (resetForm) {
      reset({ ...DEFAULT_DOCUMENTS_FILTERS, maxAmount: MAX_PRICE_CONVERTED });
    }
    setOpenSidebar(false);
  };

  //---- CLEAR FROM SUMMARY FUNC ----//
  const handleClearFromSummary = (section: string) => {
    setResetFormElement(section);
    if (isDefault(formValues)) {
      setResetForm(true);
    }
  };

  //---- FILTERS IN URL GET/SET START ----//
  const location = useLocation();

  const [firstRender, setFirstRender] = useState(true);

  const check = useCallback((filters: any, param: any) => {

    if (param === "minAmount") {
      const check = filters[param] / 1000;

      if (check > DEFAULT_DOCUMENTS_FILTERS.minAmount!) return true;

      return false;

    } else if (param === "maxAmount") {
      const check = filters[param] / 1000;

      if (filters[param] !== MAX_PRICE_CONVERTED && check < MAX_PRICE_CONVERTED) return true;

      return false;

    }

    return filters[param] && (isArray(filters[param]) ? filters[param].length > 0 : filters[param] !== DEFAULT_DOCUMENTS_FILTERS[param]);
  }, [MAX_PRICE_CONVERTED]);

  const updateFiltersInUrl = useCallback((filters: any) => {

    let queryString = Object.keys(filters).filter((x) => check(filters, x))
      .map((key) => {
        if (isArray(filters[key]) || isObject(filters[key]))
          return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]).replace(/stellantis-europe-/g, ""))}`;

        return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
      })
      .join('&');

    if (queryString) queryString = "#" + queryString;

    navigate(location.pathname + queryString, { replace: true });

  }, [location, navigate, check]);

  const formatFromK = useCallback((value: any, type: string) => {

    if (value) {
      const numbX = Number(value);

      if (numbX) {
        return Math.round(convert(numbX / 1000, CurrencyTypes.EUR, currentCurrency.label, rates));
      }

      if (type === "maxAmount") return MAX_PRICE_CONVERTED;

      return DEFAULT_DOCUMENTS_FILTERS[type];
    }

    return null;
  }, [MAX_PRICE_CONVERTED, currentCurrency.label, rates]);

  const getPageAndSize = useCallback(() => {
    if (firstRender) {
      return [pageSize, pageIndex];
    }
    else return [rowsPerPage, page];
  }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

  const getFiltersFromUrl = useCallback(() => {

    const { hash } = location;

    let searchFilters = {};

    const [pageSizeFunc, pageIndexFunc] = getPageAndSize();

    if (hash) {

      const cleanedHash = hash.slice(1);

      const decodedQuery = decodeURIComponent(cleanedHash);

      const searchParams = new URLSearchParams(decodedQuery);

      searchFilters = {
        pageIndex: pageIndexFunc,
        pageSize: pageSizeFunc,
        sortField: orderBy || null,
        sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
        all: searchParams.get('all'),
        from: searchParams.get('from'),
        to: searchParams.get('to'),
        externalId: searchParams.get('externalId'),
        documentType: searchParams.get('documentType'),
        minAmount: formatFromK(searchParams.get('minAmount'), 'minAmount'),
        maxAmount: formatFromK(searchParams.get('maxAmount'), 'maxAmount'),
        customerId: searchParams.get('customerId'),
        tags: searchParams.get('tags') ? JSON.parse(searchParams.get('tags')!) : null,
        customFields: searchParams.get('customFields') ? JSON.parse(searchParams.get('customFields')!
          .replace(/invoice/g, "stellantis-europe-invoice")
          .replace(/deliverynote/g, "stellantis-europe-deliverynote")
          .replace(/creditnote/g, "stellantis-europe-creditnote")) : null
      };

    } else {
      searchFilters = {
        pageIndex: pageIndexFunc,
        pageSize: pageSizeFunc,
        sortField: orderBy || null,
        sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
      };
    }

    searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

    return searchFilters as DocumentFilters;

  }, [formatFromK, location, order, orderBy, getPageAndSize]);
  //---- FILTERS IN URL GET/SET END ----//

  //---- FETCH DATA FUNC ----//
  // Gets all filter values ​​other than the default ones and puts them in the url
  const fetchData = useCallback(async (values: DocumentFilters) => {

    var searchFilters: any = {};

    if (JSON.stringify(values) === JSON.stringify(DEFAULT_DOCUMENTS_FILTERS)) {
      searchFilters = BASIC_FILTER_OPTIONS;
    } else {
      searchFilters = {
        pageIndex: page,
        pageSize: rowsPerPage,
        sortField: orderBy,
        sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
        all: values.all !== DEFAULT_DOCUMENTS_FILTERS.all ? values.all : null,
        externalId: values.externalId !== DEFAULT_DOCUMENTS_FILTERS.externalId ? values.externalId : null,
        documentType: values.documentType !== DEFAULT_DOCUMENTS_FILTERS.documentType ? values.documentType : null,
        customerId: values.customerId !== DEFAULT_DOCUMENTS_FILTERS.customerId ? values.customerId : null,
        from: values.from !== DEFAULT_DOCUMENTS_FILTERS.from ? values.from : null,
        to: values.to !== DEFAULT_DOCUMENTS_FILTERS.to ? values.to : null,
        tags: JSON.stringify(values.tags) !== JSON.stringify(DEFAULT_DOCUMENTS_FILTERS.tags) ? values.tags : null,
        minAmount: values.minAmount! <= DEFAULT_DOCUMENTS_FILTERS.minAmount! ? DEFAULT_DOCUMENTS_FILTERS.minAmount : values.minAmount! * 1000,
        maxAmount: values.maxAmount! >= MAX_PRICE_CONVERTED ? MAX_PRICE_CONVERTED : values.maxAmount! * 1000,
        customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_DOCUMENTS_FILTERS.customFields) ? values.customFields : null
      };
    }

    updateFiltersInUrl(searchFilters);
  }, [order, orderBy, page, rowsPerPage, updateFiltersInUrl, MAX_PRICE_CONVERTED]);

  //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
  // This function is used to call APIs and get Documents list
  const getVendorAndCustomerId = useCallback((filtersFromUrl: DocumentFilters) => {
    //IF THIS LIST IS SHOWN IN SOME ORGANIZATION DETAIL
    if (organizationID) {
      return {
        ...filtersFromUrl,
        ...(isVendor ? {} : { organizationId: organizationID }),
      };
    }

    return filtersFromUrl;
  }, [isVendor, organizationID]);

  const onSearch = useCallback(async (filtersFromUrl: DocumentFilters) => {

    const filtersFromUrlCopy = JSON.parse(JSON.stringify(filtersFromUrl));

    if (filtersFromUrlCopy.minAmount) filtersFromUrlCopy.minAmount = Math.round(convert(filtersFromUrlCopy.minAmount, currentCurrency.label, CurrencyTypes.EUR, rates)) * 1000;

    if (filtersFromUrlCopy.maxAmount) filtersFromUrlCopy.maxAmount = Math.round(convert(filtersFromUrlCopy.maxAmount, currentCurrency.label, CurrencyTypes.EUR, rates)) * 1000;

    updateFiltersInUrl(filtersFromUrlCopy);

    if (filtersFromUrlCopy.from && filtersFromUrlCopy.to) {
      filtersFromUrlCopy.from = new Date(filtersFromUrlCopy.from || "").toISOString();
      filtersFromUrlCopy.to = new Date(new Date(filtersFromUrlCopy.to || "").setHours(24, 59, 59)).toISOString();
    }

    const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrlCopy.customFields || {})
      .map(([k, val]) => ({
        key: "customFields." + k,
        value: val
      }))
      .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

    const updatedFiltersFromUrl = { ...filtersFromUrlCopy, ...customFieldsFromUrl };

    delete updatedFiltersFromUrl.customFields;

    if (isVendor)
      dispatch(ordersOperations.searchVendorDocument(getVendorAndCustomerId(updatedFiltersFromUrl)));
    else dispatch(ordersOperations.searchCustomerDocument(getVendorAndCustomerId(updatedFiltersFromUrl)));

    if (filtersFromUrl.customerId && filtersFromUrl.customerId !== getValues("customer")?.id) {
      const { data } = await detailGeneric(filtersFromUrl.customerId);

      setValue("customer", data);
    }

    if (firstRender) setFirstRender(false);

  }, [getVendorAndCustomerId, isVendor, firstRender, updateFiltersInUrl, getValues, setValue, currentCurrency.label, rates]);
  //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

  //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
  // This hook is used to call onSearch when filters or language are changed
  const isOrderDiff = useCallback((filterToCheck: DocumentFilters, controller: DocumentFilters) => {

    const differencesToController = Object.keys(filterToCheck).filter((filter) => filterToCheck[filter] !== controller[filter]);

    if (differencesToController.includes("sortField") || differencesToController.includes("sortAscending")) return true;

    const differencesToFilter = Object.keys(controller).filter((filter) => controller[filter] !== filterToCheck[filter]);

    return differencesToFilter.includes("sortField") || differencesToFilter.includes("sortAscending");
  }, []);

  useEffect(() => {

    let searchFilters = getFiltersFromUrl();

    const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

    const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

    if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label) {

      if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;
      setFilters(searchFilters as DocumentFilters);
      onSearch(searchFilters as DocumentFilters);
      setLastUsedLang(currentLang.label);
      if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
      if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
    }
  }, [location, page, rowsPerPage, orderBy, order, currentLang, filters, getFiltersFromUrl, lastUsedLang, onSearch, setPage, setRowsPerPage, isOrderDiff, firstRender]);
  //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

  //---- FILTERS SEARCH FUNC ----//
  // Used for search buttons in filters
  const handleSearchFilters = () => {
    fetchData(getValues());
    setResetForm(false);
    setOpenSidebar(false);
    setPage(0);
  };

  //---- FILTERS RESET - START ----//
  // Used for full clear buttons in filters
  const handleResetAllFilter = () => {
    if (openSidebar) {
      handleCloseSidebar();
    }
    setPage(0);
    setResetForm(true);
    fetchData({ ...DEFAULT_DOCUMENTS_FILTERS, maxAmount: MAX_PRICE_CONVERTED });
    reset({ ...DEFAULT_DOCUMENTS_FILTERS, maxAmount: MAX_PRICE_CONVERTED });
  };

  const handleResetSingleFilter = (fieldName: string, value?: any) => {
    if (isArray(formValues[fieldName])) {
      const index = formValues[fieldName].indexOf(value);

      if (index > -1) {
        formValues[fieldName].splice(index, 1);
        setValue(fieldName, formValues[fieldName]);
        handleClearFromSummary(fieldName);
        fetchData(formValues);
      }
    } else if (isObject(formValues[fieldName])) {

      delete (formValues[fieldName] as Record<string, string>)[value];

      setValue(fieldName, formValues[fieldName]);
      handleClearFromSummary(fieldName);
      fetchData(formValues);

    } else {
      const fieldsToRemove = [];

      if (fieldName === 'amount') {
        fieldsToRemove.push('minAmount', 'maxAmount');
      } else {
        fieldsToRemove.push(fieldName);
      }

      fieldsToRemove.forEach((element: string) => {
        if (element === 'maxAmount') {
          formValues[element] = MAX_PRICE_CONVERTED;
          setValue(element, MAX_PRICE_CONVERTED);
        } else {
          formValues[element] = DEFAULT_DOCUMENTS_FILTERS[element];
          setValue(element, DEFAULT_DOCUMENTS_FILTERS[element]);
        }
        handleClearFromSummary(element);
        fetchData(formValues);
      });
    }
  };
  //---- FILTERS RESET - END ----//

  //---- HANDLE TABLE START ----//
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const [actualRow, setActualRow] = useState<any>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleCellClick = (params: GridCellParams<any>) => {

    setActualRow(params);

    if (params.field !== 'options') {
      if (isVendor) navigate(PATH_DASHBOARD.orders.vendor.documents.detail(params.id.toString(), params.row.type.toString()));
      else navigate(PATH_DASHBOARD.orders.customer.documents.detail(params.id.toString(), params.row.type.toString()));
    }
  };

  const handleSort = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      setOrderBy(sortModel[0].field);
      setOrder(sortModel[0].sort!);
    } else {
      setOrderBy('');
      setOrder('asc');
    }
  };

  const getHeight = () => {
    let height: string | number = "auto";

    if (!dense || documentList.length === 0) {
      if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
      else height = rowsPerPage === 5 ? 440 : 700;
    }

    return height;
  };

  const getMaxHeight = () => {
    return isDesktop ? 650 : 700;
  };

  const handleLogs = useCallback((id: string, type: DocType) => {
    if (isVendor) navigate(PATH_DASHBOARD.orders.vendor.documents.logs(id, type));
    else navigate(PATH_DASHBOARD.orders.customer.documents.logs(id, type));
  }, [navigate, isVendor]);

  const COLUMNS: any[] = [
    {
      field: 'externalId',
      headerName: `${translate('documents.tableHeaders.docReference')}`,
      flex: isDesktop ? 1.15 : undefined,
      minWidth: !isDesktop ? 185 : undefined,
      renderCell: (obj: any) => {
        return (
          <>
            <Box
              component="img"
              src={fileThumb(obj.row.type)}
              sx={{
                width: 32,
                height: 32,
                flexShrink: 0,
                mr: 1,
              }}
            />
            {obj.row.externalId}
          </>
        );
      },
    },
    {
      field: 'type',
      headerName: `${translate('documents.tableHeaders.docType')}`,
      flex: isDesktop ? 1.1 : undefined,
      minWidth: !isDesktop ? 150 : undefined,
      valueGetter: (params: string) => {
        return `${translate(`documents.filter.${params}`)}`;
      },
    },
    {
      field: 'customerName',
      headerName: `${translate('documents.tableHeaders.customerName')}`,
      flex: isDesktop ? 1.5 : undefined,
      minWidth: !isDesktop ? 170 : undefined,
      renderCell: (obj: any) => {
        return (
          <Typography variant='body2' sx={{ wordWrap: 'break-word !important', whiteSpace: 'pre-line !important' }}>
            {obj.row.customerName}
          </Typography>
        );
      },
    },
    {
      field: 'vendorName',
      headerName: `${translate('documents.tableHeaders.vendorName')}`,
      flex: isDesktop ? 1.5 : undefined,
      minWidth: !isDesktop ? 170 : undefined,
      renderCell: (obj: any) => {
        return (
          <Typography variant='body2' sx={{ wordWrap: 'break-word !important', whiteSpace: 'pre-line !important' }}>
            {obj.row.vendorName}
          </Typography>
        );
      },
    },
    {
      field: 'createdOn',
      headerName: `${translate('documents.tableHeaders.docDate')}`,
      flex: isDesktop ? 1.2 : undefined,
      minWidth: !isDesktop ? 180 : undefined,
      renderCell: (obj: any) => {
        return <DateZone date={obj.row.createdOn} noSeconds shortMonth variant={'body2'} />;
      },
    },
    {
      field: 'grossAmount',
      headerName: `${translate('orders.tableHeaders.grossAmount')}`,
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 130 : undefined,
      renderCell: (obj: any) => {
        return (
          <CurrencyAdapter
            value={getPrice(obj.row.grossAmount)}
            currency={obj.row.grossAmount.currentCurrency}
            noVatAsterisk={!!obj.row.grossAmount.withoutVat}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'totalDiscountAmount',
      headerName: `${translate('orders.tableHeaders.discountAmount')}`,
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 130 : undefined,
      renderCell: (obj: any) => {
        return (
          <CurrencyAdapter
            value={getPrice(obj.row.totalDiscountAmount)}
            currency={obj.row.totalDiscountAmount.currentCurrency}
            noVatAsterisk={!!obj.row.totalDiscountAmount.withoutVat}
            fontSize={'0.875rem'}
          />
        );

      },
    },
    {
      field: 'netAmount',
      headerName: `${translate('orders.tableHeaders.netAmount')}`,
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 130 : undefined,
      renderCell: (obj: any) => {
        return (
          <CurrencyAdapter
            value={getPrice(obj.row.netAmount)}
            currency={obj.row.netAmount.currentCurrency}
            noVatAsterisk={!!obj.row.netAmount.withoutVat}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'options',
      headerName: ``,
      flex: isDesktop ? 0.2 : undefined,
      maxWidth: !isDesktop ? 50 : undefined,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (obj: any) => {
        return (
          <OptionsComponent
            openMenu={openMenu}
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
            handleLogs={handleLogs}
            object={obj}
            currentRow={actualRow}
            isVendor={isVendor}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (totalCount <= rowsPerPage * page) onChangePage(null, 0);
  }, [onChangePage, page, rowsPerPage, totalCount]);
  //---- HANDLE TABLE END ----//

  return (
    <>
      <Card>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 3.5 }, pb: 2, mt: 2 }}>
          <Box>
            {!organizationID && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                {`${translate('documents.messages.subtitle')}`}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>

            <LicenseGuard license={LicenseTypes.Report}>
              <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Report_View]}>
                <ReportCreationModal allFilters={reportFilters} reportType={"document"} />
              </PermissionBasedGuard>
            </LicenseGuard>

            <Box sx={{ display: 'flex' }}>
              <FormProvider methods={methods}>
                <DocumentsFilterSidebar
                  isDefault={isDefault}
                  isOpen={openSidebar}
                  onFilter={handleSearchFilters}
                  onOpen={handleOpenSidebar}
                  onClose={handleCloseSidebar}
                  onResetAll={handleResetAllFilter}
                  reset={resetForm}
                  resetFormElement={resetFormElement}
                  filters={{ ...formValues, ...getFiltersFromUrl() }}
                  setResetFormElement={setResetFormElement}
                  MAX_PRICE_CONVERTED={MAX_PRICE_CONVERTED}
                  isVendor={isVendor}
                />
              </FormProvider>
            </Box>
          </Box>
        </Box>

        <FormProvider methods={methods}>
          <DocumentsGeneralFilters
            filters={{ ...formValues, ...(getFiltersFromUrl()) }}
            onFilter={handleSearchFilters}
            resetAll={handleResetAllFilter}
          />
        </FormProvider>

        <DocumentsFilterSummary
          filters={openSidebar ? DEFAULT_DOCUMENTS_FILTERS : { ...formValues, ...getFiltersFromUrl() }}
          isShowReset={!isDefault({ ...formValues, ...getFiltersFromUrl() }) && !openSidebar}
          onResetAll={handleResetAllFilter}
          onResetFilter={handleResetSingleFilter}
          MAX_PRICE_CONVERTED={MAX_PRICE_CONVERTED}
        />

        <Divider />

        <Box>
          <DataGrid
            rows={documentList}
            columns={COLUMNS}
            pagination
            paginationModel={{
              page: page,
              pageSize: rowsPerPage
            }}
            density={(dense && documentList.length > 0) ? 'compact' : 'standard'}
            sortingMode={'server'}
            onSortModelChange={handleSort}
            loading={isLoading}
            disableColumnResize
            slots={{
              noRowsOverlay: noData,
              footer: () => (
                <Box
                  sx={{
                    position: 'relative',
                    width: { xs: '90vw', md: 'auto' }
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 30]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                    sx={{
                      overflow: "hidden",
                      "& .MuiTablePagination-input": {
                        ml: { xs: 0.5, md: "default" },
                        mr: { xs: 3.5, md: "default" }
                      }
                    }}
                  />

                  <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label={`${translate('commons.dense')}`}
                    sx={{
                      px: { xs: 0, sm: 3 },
                      py: { xs: 0, sm: 1.5 },
                      pb: { xs: 1.5, sm: 0 },
                      mx: 0,
                      top: 0,
                      justifyContent: 'center',
                      width: { xs: '90vw', sm: 'auto' },
                      position: { sm: 'absolute' },
                    }}
                  />
                </Box>
              ),
            }}
            disableColumnMenu
            pageSizeOptions={[5, 10, 15, 30]}
            disableRowSelectionOnClick
            onCellClick={(param) => {
              if (!window.getSelection()?.toString())
                handleCellClick(param);
            }}
            sx={{
              ...DataGridStyle,
              cursor: 'pointer',
              height: getHeight(),
              maxHeight: getMaxHeight()
            }}
          />
        </Box>
      </Card>

      <Typography sx={{ textAlign: 'right', mt: 3, mr: 1 }} fontSize={'13px'}>
        {`${translate('orders.messages.vatAdvice')}`}
      </Typography>
    </>
  );
}

type OptionsComponentProps = {
  openMenu: HTMLElement | null;
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseMenu: () => void;
  handleLogs: (id: string, type: DocType) => void;
  object: any;
  currentRow: any;
  isVendor: boolean
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, object, currentRow, isVendor }: OptionsComponentProps) {

  const { translate } = useLocales();

  return (
    <TableMoreMenu
      showMenu={currentRow && object.id === currentRow.id}
      open={openMenu}
      onOpen={(event) => handleOpenMenu(event)}
      onClose={() => handleCloseMenu()}
      actions={
        <>
          {handleLogs && (
            <PermissionBasedGuard
              permissions={
                isVendor
                  ? [OrganizationPermissionTypes.WebShop_Orders_VendorView]
                  : [OrganizationPermissionTypes.WebShop_Orders_CustomerView]
              }
            >
              <MenuItem
                onClick={() => {
                  handleLogs(object.id, object.row.type);
                  handleCloseMenu();
                }}
              >
                {`${translate('commons.logs')}`}
              </MenuItem>
            </PermissionBasedGuard>
          )}
        </>
      }
    />
  );
}
