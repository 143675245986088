import { ChevronLeft, ShoppingCart } from "@mui/icons-material";
import { alpha, Badge, Box, Button, Card, Divider, Drawer, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Label from "src/components/label";
import { bgBlur } from "src/utils/cssStyles";
import CartShopInfo from "./CartShopInfo";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { basketOperations } from "src/redux/basket";
import { useSettingsContext } from "src/components/settings";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import { getPrice } from "src/utils/currency";
import { useLocales } from "src/locales";
import { resetBasket } from "src/redux/basket/basket-slices";

export default function CartShopPopover() {

    const Ref = useRef<number | null>(null);

    // The state for our timer
    const [timer, setTimer] = useState("00:00");

    const [openPopover, setOpenPopover] = useState(false);

    const { basket } = useSelector((state: RootState) => state.basket);

    const totalItems = useMemo(() => {
        let total = 0;

        basket?.items.forEach(s => {
            total += s.price.quantity;
        });

        return total;
    }, [basket]);

    useEffect(() => {
        if (!basket)
            dispatch(basketOperations.detailPublicBasket());
    }, [basket]);

    const getTimeRemaining = (e: string) => {
        const total = Date.parse(e) - Date.parse(new Date().toISOString());

        const seconds = Math.floor((total / 1000) % 60);

        const minutes = Math.floor(
            (total / 1000 / 60) % 60
        );

        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = useCallback((e: string) => {
        let { total, minutes, seconds } = getTimeRemaining(e);

        if (total >= 0) {
            setTimer(
                `${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds}`
            );
        } else {
            setTimer("00:00");
            if (Ref.current) clearInterval(Ref.current);
            dispatch(resetBasket());
        }

    }, []);

    const clearTimer = useCallback((expiry: string) => {

        if (Ref.current) clearInterval(Ref.current);

        const id = window.setInterval(() => startTimer(expiry), 1000);

        Ref.current = id;
    }, [startTimer]);

    useEffect(() => {
        if (basket)
            clearTimer(basket.expiresAt);
    }, [basket, clearTimer]);

    return (
        <>
            <Card sx={{ cursor: 'pointer', width: basket?.isExpired === false ? '8rem' : 'auto' }} onClick={() => setOpenPopover(true)}>
                <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
                    <Badge badgeContent={totalItems} color="error">
                        <ShoppingCart />
                    </Badge>
                    {basket?.isExpired === false && <Box sx={{ width: '100%' }}>
                        <Label color="default" sx={{ width: 'fit-content' }}>
                            {" -" + timer}
                        </Label>
                    </Box>}
                </Box>
            </Card>
            <CartLight openPopover={openPopover} setOpenPopover={setOpenPopover} />
        </>
    );
}

interface CartLightProps {
    openPopover: boolean;
    setOpenPopover: (e: boolean) => void;
}

function CartLight({ openPopover, setOpenPopover }: CartLightProps) {

    const { translate } = useLocales();

    const theme = useTheme();

    const navigate = useNavigate();

    const { basket } = useSelector((state: RootState) => state.basket);

    const { currency } = useSettingsContext();

    return (
        <Drawer
            anchor="right"
            open={openPopover}
            onClose={() => setOpenPopover(false)}
            slotProps={{ backdrop: { invisible: true } }}
            PaperProps={{
                sx: {
                    bgcolor: 'transparent',
                    ...bgBlur({ color: theme.palette.background.default, opacity: 0.85 }),
                    width: '55vw',
                    boxShadow: (theme) =>
                        `-24px 12px 40px 0 ${alpha(
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[500]
                                : theme.palette.common.black,
                            0.16
                        )}`,
                },
            }}
        >
            <Box sx={{ py: 3, overflowY: 'auto' }}>
                {basket?.items.map((shop) => <CartShopInfo key={shop.shopId} shop={shop} />)}
            </Box>

            {!basket?.items.length &&
                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: 2, m: 'auto', flexDirection: 'column' }}>
                    <ShoppingCart sx={{ width: '100%', height: '8vh' }} />
                    <Typography variant="h5">
                        {translate('basket.messages.emptyCart')}
                    </Typography>
                </Box>
            }

            <Box sx={{ bgcolor: (theme) => theme.palette.background.neutral, p: 2, mt: 'auto' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{translate('basket.detail.subTotal')}</Typography>
                        {basket && <CurrencyAdapter variant="body2" value={getPrice(basket.price.grossAmount)} currency={basket.price.grossAmount.currentCurrency} />}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{translate('basket.detail.totalDiscount')}</Typography>
                        {basket && <CurrencyAdapter variant="body2" value={getPrice(basket.price.discountAmount)} currency={basket.price.discountAmount.currentCurrency} />}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{translate('basket.detail.totalThresholdDiscount')}</Typography>

                        {basket && <CurrencyAdapter variant="body2" value={1} currency={currency.label} />}
                    </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Typography variant="subtitle1" >{translate("documents.documentHeaders.total")}</Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', alignItems: 'end' }}>
                        {basket && <CurrencyAdapter variant="subtitle1" value={getPrice(basket.price.netAmount)} currency={basket.price.netAmount.currentCurrency} />}
                        <Typography variant="body2" >({translate('basket.messages.vatIncluded')})</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button
                        variant="text"
                        sx={{ color: 'inherit' }}
                        startIcon={<ChevronLeft />}
                        onClick={() => setOpenPopover(false)}
                    >
                        {translate('commons.close')}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: '100px' }}
                        disabled={!basket?.items.length}
                        onClick={() => navigate(PATH_DASHBOARD.webshopPublic.checkout)}
                    >
                        {translate("basket.checkout.title")}
                    </Button>

                </Box>
            </Box>
        </Drawer >
    );
}

