function formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, encodeURI(val[index]));
    }

    return str;
}

function path(root: string, sublink: string, segments?: string[]): string {

    let url = `${root}${sublink}`;

    if (segments)
        url = formatString(url, ...segments);

    return url;
}

const NOTIFICATION_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/notification";

const CORE_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/core";

const CURRENCY_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/currency";

const RV_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/rv";

const PRODUCT_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/product";

const ORDERS_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/order";

const REPORT_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/report";

const SHIPPING_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/shipping";

const GROUP_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/group";

const SHOP_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/shop";

const MEDIA_HOST_API = process.env.REACT_APP_HOST_API_KEY + "/media";

export const Notification = {
    admin: {
        notification: {
            createNotification: () => path(NOTIFICATION_HOST_API, '/v1/admin/notifications'),
            search: () => path(NOTIFICATION_HOST_API, '/v1/admin/notifications'),
            editNotification: (id: string) => path(NOTIFICATION_HOST_API, '/v1/admin/notifications/{0}', [id]),
            detail: (id: string) => path(NOTIFICATION_HOST_API, '/v1/admin/notifications/{0}', [id])
        }
    },
    public: {
        inbox: {
            messages: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox'),
            messagesDetail: (id: string) => path(NOTIFICATION_HOST_API, '/v1/public/inbox/{0}', [id]),
            delete: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox'),
            markAsRead: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox/mark-as-read'),
            markAsUnRead: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox/mark-as-unread'),
            markAsStarred: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox/mark-as-starred'),
            markAsUnStarred: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox/mark-as-unstarred'),
            markAsImportant: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox/mark-as-important'),
            markAsUnImportant: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox/mark-as-unimportant'),
            labelsAndStatistics: () => path(NOTIFICATION_HOST_API, '/v1/public/inbox/statistics'),

        },
        settings: {
            getSettings: () => path(NOTIFICATION_HOST_API, '/v1/public/notificationSettings'),
            updateSettings: () => path(NOTIFICATION_HOST_API, '/v1/public/notificationSettings')
        },
        sockets: {
            socketToken: (id: string) => path(NOTIFICATION_HOST_API, "/v1/public/sockets/{0}", [id]),
            inbox: () => path(NOTIFICATION_HOST_API, '/sockets/inbox'),
            report: () => path(NOTIFICATION_HOST_API, '/sockets/report'),
            returnRequest: () => path(NOTIFICATION_HOST_API, '/sockets/returnRequest'),
            group: () => path(NOTIFICATION_HOST_API, '/sockets/group')
        },
    }
};

export const Media = {
    admin: {
        generateUpload: () => path(MEDIA_HOST_API, '/v1/admin/media/generateUploadUrl'),
        confirmUpload: () => path(MEDIA_HOST_API, '/v1/admin/media/confirmFileUpload')
    }
};

export const Currency = {
    admin: {
        rate: () => path(CURRENCY_HOST_API, '/v1/admin/rate'),
    }
};

export const Rv = {
    admin: {
        matrixTemplate: {
            createTemplate: () => path(RV_HOST_API, '/v1/admin/matrixTemplates'),
            getPNO: () => path(RV_HOST_API, '/v1/admin/smart-europe/manufacturerCode'),
            getPNOPrice: () => path(RV_HOST_API, '/v1/admin/smart-europe/catalogPrices'),
            search: () => path(RV_HOST_API, '/v1/admin/matrixTemplates'),
            searchTags: () => path(RV_HOST_API, '/v1/admin/matrixTemplates/tag/suggest'),
            detail: (id: string) => path(RV_HOST_API, '/v1/admin/matrixTemplates/{0}', [id]),
            enable: (id: string) => path(RV_HOST_API, '/v1/admin/matrixTemplates/{0}/enable', [id]),
            disable: (id: string) => path(RV_HOST_API, '/v1/admin/matrixTemplates/{0}/disable', [id]),
        },
        rvMatrix: {
            preview: () => path(RV_HOST_API, '/v1/admin/matrix/preview'),
            create: () => path(RV_HOST_API, '/v1/admin/matrix'),
            update: () => path(RV_HOST_API, '/v1/admin/matrix'),
            detail: (id: string) => path(RV_HOST_API, '/v1/admin/matrix/{0}', [id]),
            search: () => path(RV_HOST_API, '/v1/admin/matrix'),
            searchTags: () => path(RV_HOST_API, '/v1/admin/matrix/tag/suggest'),
            fromFile: () => path(RV_HOST_API, '/v1/admin/matrix/fromFile'),
            getExcelRvMatrix: (id: string) => path(RV_HOST_API, '/v1/admin/matrix/{0}/excel', [id]),
            enable: (id: string) => path(RV_HOST_API, '/v1/admin/matrix/{0}/enable', [id]),
            disable: (id: string) => path(RV_HOST_API, '/v1/admin/matrix/{0}/disable', [id]),
            submit: (id: string) => path(RV_HOST_API, '/v1/admin/matrix/{0}/submit', [id]),
            downloadExcel: (id: string) => path(RV_HOST_API, id),
            matrixStatuses: () => path(RV_HOST_API, '/v1/admin/matrix/statistics'),
            manufacturerStatistics: () => path(RV_HOST_API, '/v1/admin/matrix/manufacturer/statistics'),
            logs: (id: string) => path(RV_HOST_API, '/v1/admin/matrix/{0}/logs', [id]),
            approve: (id: string) => path(RV_HOST_API, '/v1/admin/matrix/{0}/approve', [id]),
            reject: (id: string) => path(RV_HOST_API, '/v1/admin/matrix/{0}/reject', [id])
        }
    }
};

export const Shop = {
    admin: {
        basketRule: {
            list: () => path(SHOP_HOST_API, '/v1/admin/basketRule'),
            detail: (basketRuleId: string) => path(SHOP_HOST_API, '/v1/admin/basketRule/{0}', [basketRuleId]),
            create: () => path(SHOP_HOST_API, '/v1/admin/basketRule'),
            edit: (basketRuleId: string) => path(SHOP_HOST_API, '/v1/admin/basketRule/{0}', [basketRuleId]),
            logs: (basketRuleId: string) => path(SHOP_HOST_API, '/v1/admin/basketRule/{0}/logs', [basketRuleId]),
            statistics: () => path(SHOP_HOST_API, '/v1/admin/basketRule/statistics'),
            enable: (basketRuleId: string) => path(SHOP_HOST_API, '/v1/admin/basketRule/{0}/enable', [basketRuleId]),
            disable: (basketRuleId: string) => path(SHOP_HOST_API, '/v1/admin/basketRule/{0}/disable', [basketRuleId]),
            active: (basketRuleId: string) => path(SHOP_HOST_API, '/v1/admin/basketRule/{0}/active', [basketRuleId])
        },
        shop: {
            list: () => path(SHOP_HOST_API, '/v1/admin/shop'),
            display: (id: string) => path(SHOP_HOST_API, '/v1/admin/shop/spareParts/{0}/display', [id]),
            detail: (id: string) => path(SHOP_HOST_API, '/v1/admin/shop/spareParts/{0}', [id]),
            create: () => path(SHOP_HOST_API, '/v1/admin/shop/spareParts'),
            edit: (shopId: string) => path(SHOP_HOST_API, '/v1/admin/shop/spareParts/{0}', [shopId]),
            logs: (shopId: string) => path(SHOP_HOST_API, '/v1/admin/shop/{0}/logs', [shopId]),
            statistics: () => path(SHOP_HOST_API, '/v1/admin/shop/statistics'),
            enable: (shopId: string) => path(SHOP_HOST_API, '/v1/admin/shop/{0}/enable', [shopId]),
            disable: (shopId: string) => path(SHOP_HOST_API, '/v1/admin/shop/{0}/disable', [shopId]),
            duplicate: (shopId: string) => path(SHOP_HOST_API, '/v1/admin/shop/{0}/duplicate', [shopId]),
            archive: (shopId: string) => path(SHOP_HOST_API, '/v1/admin/shop/{0}/archive', [shopId]),
            product: {
                list: (id: string) => path(SHOP_HOST_API, '/v1/admin/shop/spareParts/{0}/bundles', [id]),
            }
        },
        discountMatrix: {
            list: () => path(SHOP_HOST_API, '/v1/admin/discountMatrix'),
            enable: (discountMatrixId: string) => path(SHOP_HOST_API, '/v1/admin/discountMatrix/{0}/enable', [discountMatrixId]),
            disable: (discountMatrixId: string) => path(SHOP_HOST_API, '/v1/admin/discountMatrix/{0}/disable', [discountMatrixId]),
            detailRows: (discountMatrixId: string) => path(SHOP_HOST_API, '/v1/admin/discountMatrix/{0}/rows', [discountMatrixId])
        }
    },
    public: {
        basket: {
            detail: () => path(SHOP_HOST_API, '/v1/public/basket'),
            addItem: () => path(SHOP_HOST_API, '/v1/public/basket/addItem'),
            removeItem: () => path(SHOP_HOST_API, '/v1/public/basket/removeItem'),
        },
        shop: {
            list: () => path(SHOP_HOST_API, '/v1/public/shop'),
            product: {
                list: () => path(SHOP_HOST_API, '/v1/public/bundle', []),
                detail: (bundleId: string) => path(SHOP_HOST_API, '/v1/public/bundle/{0}', [bundleId])
            }
        }
    }
};

export const Product = {
    admin: {
        spareParts: {
            vendor: {
                statistic: () => path(PRODUCT_HOST_API, '/v1/admin/sparePart/vendor/statistics'),
                search: () => path(PRODUCT_HOST_API, '/v1/admin/sparePart/vendor/search'),
                searchTags: () => path(PRODUCT_HOST_API, '/v1/admin/sparePart/tag/suggest'),
            },
            customer: {
                statistic: () => path(PRODUCT_HOST_API, '/v1/admin/sparePart/customers/statistics'),
                search: () => path(PRODUCT_HOST_API, '/v1/admin/sparePart/customers/search'),
                searchTags: () => path(PRODUCT_HOST_API, '/v1//sparePart/customers/suggest/tags'),
            },
            searchCategories: () => path(PRODUCT_HOST_API, '/v1/admin/sparePart/categories'),
            searchLogs: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/sparePart/{0}/logs', [id]),
            detail: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/sparePart/{0}/display', [id]),
            enable: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/sparePart/{0}/enable', [id]),
            disable: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/sparePart/{0}/disable', [id]),
            productStatistic: (organizationId: string) => path(PRODUCT_HOST_API, '/v1/admin/sparePart/{0}/statistics', [organizationId]),
            customFieldSuggest: () => path(PRODUCT_HOST_API, '/v1/admin/sparePart/customField/suggest')
        },
        vehicle: {
            detail: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/{0}', [id]),
            display: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/{0}/display', [id]),
            create: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/'),
            update: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/'),
            enable: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/{0}/enable', [id]),
            disable: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/{0}/disable', [id]),
            logs: (id: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/{0}/logs', [id]),
            search: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/vendor/search'),
            statistics: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/vendor/statistics'),
            searchTags: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/tag/suggest'),
            searchBrand: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/brand/suggest'),
            searchModel: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/model/suggest'),
            searchModelCode: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/model/code/suggest'),
            searchVersion: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/version/suggest'),
            searchCarline: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/carline/suggest'),
            getMediaUploadUrl: (vehicleId: string, mediaType: string, filename: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/{0}/{1}/{2}', [vehicleId, mediaType, filename]),
            removeMedia: (vehicleId: string) => path(CORE_HOST_API, '/v1/admin/vehicles/{0}/removeMedia', [vehicleId]),
            getContractualData: (vehicleId: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/contractualData/{0}', [vehicleId]),
            getContractualDataLogs: (vehicleId: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/contractualData/{0}/logs', [vehicleId]),
            postContractualData: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/contractualData'),
            putContractualData: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/contractualData'),
            getLogistics: (vehicleId: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/logisticData/{0}', [vehicleId]),
            getLogisticsLogs: (vehicleId: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/logisticData/{0}/logs', [vehicleId]),
            postLogistics: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/logisticData'),
            putLogistics: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/logisticData'),
            getCommercialData: (vehicleId: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/commercialData/{0}', [vehicleId]),
            getCommercialDataLogs: (vehicleId: string) => path(PRODUCT_HOST_API, '/v1/admin/vehicles/commercialData/{0}/logs', [vehicleId]),
            postCommercialData: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/commercialData'),
            putCommercialData: () => path(PRODUCT_HOST_API, '/v1/admin/vehicles/commercialData'),
        }
    }
};

export const Orders = {
    admin: {
        orders: {
            vendor: {
                search: () => path(ORDERS_HOST_API, '/v1/admin/orders/vendor/search'),
                searchDocument: () => path(ORDERS_HOST_API, '/v1/admin/orderDocuments/vendor/search'),
            },
            customer: {
                search: () => path(ORDERS_HOST_API, '/v1/admin/orders/customer/search'),
                searchDocument: () => path(ORDERS_HOST_API, '/v1/admin/orderDocuments/customer/search'),
            },
            searchTags: () => path(ORDERS_HOST_API, '/v1/admin/orders/tag/suggest'),
            searchDocumentsTags: () => path(ORDERS_HOST_API, '/v1/admin/orderDocuments/tag/suggest'),
            detail: (id: string) => path(ORDERS_HOST_API, '/v1/admin/orders/{0}', [id]),
            searchOrderLogs: (id: string) => path(ORDERS_HOST_API, '/v1/admin/orders/{0}/logs', [id]),
            deliveryNoteDetail: (id: string) => path(ORDERS_HOST_API, '/v1/admin/deliveryNotes/{0}', [id]),
            invoiceDetail: (id: string) => path(ORDERS_HOST_API, '/v1/admin/invoices/{0}', [id]),
            creditNoteDetail: (id: string) => path(ORDERS_HOST_API, '/v1/admin/creditNotes/{0}', [id]),
            searchInvoiceLogs: (id: string) => path(ORDERS_HOST_API, '/v1/admin/invoices/{0}/logs', [id]),
            searchDeliveryNoteLogs: (id: string) => path(ORDERS_HOST_API, '/v1/admin/deliveryNotes/{0}/logs', [id]),
            searchCreditNoteLogs: (id: string) => path(ORDERS_HOST_API, '/v1/admin/creditNotes/{0}/logs', [id])
        },
        returns: {
            customer: {
                statistics: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/customer/statistics'),
                search: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/customer/search'),
                searchItems: () => path(ORDERS_HOST_API, '/v1/admin/orders/customer/items/search')
            },
            vendor: {
                statistics: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/vendor/statistics'),
                search: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/vendor/search'),
                searchItems: () => path(ORDERS_HOST_API, '/v1/admin/orders/vendor/items/search')
            },
            carrier: {
                statistics: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/carrier/statistics'),
                search: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/carrier/search'),
            },
            detail: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}', [id]),
            alert: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/alert'),
            create: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests'),
            update: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}', [id]),
            maxQuantity: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/MaximumQuantityForReturnRequest'),
            maxQuantityMissing: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/maximumQuantityForMissingRequest'),
            delete: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}', [id]),
            approve: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/Approve', [id]),
            rejectByWarehouse: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/rejectByWarehouse', [id]),
            rejectByBackoffice: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/rejectByBackoffice', [id]),
            printed: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/Printed', [id]),
            pickedUp: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/PickedUp', [id]),
            warehouseAccept: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/Accept', [id]),
            detailBarcode: (barcode: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/barcode/{0}', [barcode]),
            refused: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/refuse', [id]),
            refusedPrinted: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/refusedPrinted', [id]),
            refusedPickedUp: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/refusedPickedUp', [id]),
            returnToCustomer: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/returnToCustomer', [id]),
            closeAnomaly: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/closeAnomaly', [id]),
            searchLogs: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/logs', [id]),
            paid: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/paid', [id]),
            mediaDelete: (id: string, mediaId: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/media/{1}', [id, mediaId]),
            labelRestore: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/labelRestore', [id]),
            approveRestore: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/approveRestore', [id]),
            toBeAmended: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/toBeAmended', [id]),
            customerSurveillance: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/surveillance/{0}/vendor/search', [id]),
            customerClaim: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/------------', [id]),
            close: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/close', [id]),
            lost: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/lost', [id]),
            openClaim: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/claim/open', [id]),
            closeClaim: (id: string) => path(ORDERS_HOST_API, '/v1/admin/returnRequests/{0}/claim/close', [id]),
            rules: {
                detail: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules'),
                updatePart: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules/part'),
                updateCategory: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules/category'),
                updateInvoice: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules/invoice'),
                updateFrequency: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules/frequency'),
                updateSurvelliance: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules/surveillance'),
                updatePeriod: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules/period'),
                updateMinimunAmount: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules/minimumAmount'),
                getLogs: () => path(ORDERS_HOST_API, '/v1/admin/returnRequests/rules/logs'),
            }
        }
    }
};

export const Report = {
    admin: {
        specific: {
            get: (id: string, isScheduled: boolean, type: string, category: string) => path(REPORT_HOST_API, `/v1/admin/${category}/${type}/${isScheduled ? "scheduled" : "recurring"}/{0}`, [id]),
            create: (isScheduled: boolean, type: string, category: string) => path(REPORT_HOST_API, `/v1/admin/${category}/${type}/${isScheduled ? "scheduled" : "recurring"}`),
            update: (id: string, type: string) => path(REPORT_HOST_API, `/v1/admin/order/${type}/recurring/{0}`, [id]),
            enable: (id: string, type: string) => path(REPORT_HOST_API, `/v1/admin/order/${type}/recurring/{0}/enable`, [id]),
            disable: (id: string, type: string) => path(REPORT_HOST_API, `/v1/admin/order/${type}/recurring/{0}/disable`, [id]),
            fields: (type: string) => path(REPORT_HOST_API, `/v1/admin/order/${type}/fields`),
            templates: (type: string) => path(REPORT_HOST_API, `/v1/admin/order/{0}/templates`, [type]),
            containerFields: () => path(REPORT_HOST_API, '/v1/admin/container/fields'),
            containerTemplates: () => path(REPORT_HOST_API, '/v1/admin/container/templates')
        },
        general: {
            search: (isScheduled: boolean) => path(REPORT_HOST_API, `/v1/admin/${isScheduled ? "scheduled" : "recurring"}`),
            statistics: (isScheduled: boolean) => path(REPORT_HOST_API, `/v1/admin/${isScheduled ? "scheduled" : "recurring"}`),
            execution: (id: string) => path(REPORT_HOST_API, '/v1/admin/recurring/{0}/execution', [id])
        }
    }
};

export const Shipping = {
    admin: {
        container: {
            search: () => path(SHIPPING_HOST_API, '/v1/admin/container/search'),
            detail: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/{0}', [id]),
            close: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/{0}/closed', [id]),
            ship: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/{0}/shipped', [id]),
            searchTags: () => path(SHIPPING_HOST_API, '/v1/admin/container/tag/suggest'),
            getLogs: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/{0}/logs', [id]),
            addRequest: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/{0}/items/returnRequest', [id]),
            statistics: () => path(SHIPPING_HOST_API, '/v1/admin/container/statistics'),
            detailByTemplate: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/byTemplate/{0}', [id]),
            extraItems: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/{0}/extraItems', [id])
        },
        template: {
            search: () => path(SHIPPING_HOST_API, '/v1/admin/container/template/search'),
            detail: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/template/{0}', [id]),
            open: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/template/{0}/open', [id]),
            update: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/template/{0}', [id]),
            create: () => path(SHIPPING_HOST_API, '/v1/admin/container/template'),
            enable: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/template/{0}/enable', [id]),
            disable: (id: string) => path(SHIPPING_HOST_API, '/v1/admin/container/template/{0}/disable', [id]),
            searchTags: () => path(SHIPPING_HOST_API, '/v1/admin/container/template/tag/suggest'),
            statistics: () => path(SHIPPING_HOST_API, '/v1/admin/container/template/statistics')
        }
    }

};

export const Core = {
    admin: {
        role: {
            create: () => path(CORE_HOST_API, '/v1/admin/roles'),
            update: () => path(CORE_HOST_API, '/v1/admin/roles'),
            disable: (id: string) => path(CORE_HOST_API, '/v1/admin/roles/{0}/disable', [id]),
            enable: (id: string) => path(CORE_HOST_API, '/v1/admin/roles/{0}/enable', [id]),
            search: () => path(CORE_HOST_API, '/v1/admin/roles/search'),
            searchLogs: (id: string) => path(CORE_HOST_API, '/v1/admin/roles/{0}/logs', [id]),
            detail: (id: string) => path(CORE_HOST_API, '/v1/admin/roles/{0}', [id]),
            pageDetails: (id: string) => path(CORE_HOST_API, '/v1/admin/pageLayouts/{0}', [id]),
            pageDetails1: () => path(CORE_HOST_API, '/v1/admin/pageLayouts/'),
            organizations: {
                detail: (id: string) => path(CORE_HOST_API, '/v1/admin/organizations/roles/{0}', [id]),
                create: () => path(CORE_HOST_API, '/v1/admin/organizations/roles'),
                update: () => path(CORE_HOST_API, '/v1/admin/organizations/roles'),
                disable: (id: string) => path(CORE_HOST_API, '/v1/admin/organizations/roles/{0}/disable', [id]),
                enable: (id: string) => path(CORE_HOST_API, '/v1/admin/organizations/roles/{0}/enable', [id]),
                search: () => path(CORE_HOST_API, '/v1/admin/organizations/roles/search'),
                searchLogs: (id: string) => path(CORE_HOST_API, '/v1/admin/organizations/roles/{0}/logs', [id])
            }
        },
        TermsCondition: {
            getDefault: (entityType: string) => path(CORE_HOST_API, '/v1/admin/termAndCondition/default/{0}', [entityType]),
            detail: (id: string) => path(CORE_HOST_API, '/v1/admin/termAndCondition/{0}', [id]),
            update: (id: string) => path(CORE_HOST_API, '/v1/admin/termAndCondition/{0}', [id]),
            create: () => path(CORE_HOST_API, '/v1/admin/termAndCondition'),
            setDefault: (id: string, entityType: string) => path(CORE_HOST_API, '/v1/admin/termAndCondition/{0}/{1}/default', [id, entityType]),
            enable: (id: string) => path(CORE_HOST_API, '/v1/admin/termAndCondition/{0}/enable', [id]),
            disable: (id: string) => path(CORE_HOST_API, '/v1/admin/termAndCondition/{0}/disable', [id]),
            logs: (id: string) => path(CORE_HOST_API, '/v1/admin/termAndCondition/{0}/logs', [id]),
            search: () => path(CORE_HOST_API, '/v1/admin/termAndCondition'),
        },
        user: {
            detail: (id: string) => path(CORE_HOST_API, '/v1/admin/users/{0}', [id]),
            create: () => path(CORE_HOST_API, '/v1/admin/users/'),
            update: () => path(CORE_HOST_API, '/v1/admin/users/'),
            statistic: () => path(CORE_HOST_API, '/v1/admin/users/statistics'),
            enable: (id: string) => path(CORE_HOST_API, '/v1/admin/users/{0}/enable', [id]),
            disable: (id: string) => path(CORE_HOST_API, '/v1/admin/users/{0}/disable', [id]),
            resetPassword: (id: string) => path(CORE_HOST_API, '/v1/admin/users/{0}/resetPassword', [id]),
            verifyEmail: (email: string) => path(CORE_HOST_API, '/v1/admin/users/verify/email/{0}', [email]),
            verifyUsername: (username: string, provider: string) => path(CORE_HOST_API, '/v1/admin/users/verify/username/{0}/{1}', [username, provider]),
            search: () => path(CORE_HOST_API, '/v1/admin/users'),
            searchLogs: (userId: string) => path(CORE_HOST_API, '/v1/admin/users/{0}/logs', [userId]),
            assignRoles: (userId: string) => path(CORE_HOST_API, '/v1/admin/users/{0}/roles', [userId]),
            searchTags: () => path(CORE_HOST_API, '/v1/admin/users/tag/suggest'),
            iframeToken: () => path(CORE_HOST_API, '/v1/admin/users/biAuthentication'),
            associate: (id: string) => path(CORE_HOST_API, '/v1/admin/users/{0}/organizations/associate', [id]),
            getProviders: () => path(CORE_HOST_API, '/v1/admin/users/providers'),
            groups: () => path(GROUP_HOST_API, '/v1/admin/groups/getByIds'),
        },
        customField: {
            create: () => path(CORE_HOST_API, '/v1/admin/customFields'),
            update: () => path(CORE_HOST_API, '/v1/admin/customFields'),
            detail: (id: string) => path(CORE_HOST_API, '/v1/admin/customFields/{0}', [id]),
            disable: (id: string) => path(CORE_HOST_API, '/v1/admin/customFields/{0}/disable', [id]),
            enable: (id: string) => path(CORE_HOST_API, '/v1/admin/customFields/{0}/enable', [id]),
            search: () => path(CORE_HOST_API, '/v1/admin/customFields/search'),
            section: () => path(CORE_HOST_API, '/v1/admin/customFields/sections')
        },
        organizations: {
            customer: {
                statistic: () => path(CORE_HOST_API, '/v1/admin/organizations/customers/statistics'),
                searchTags: () => path(CORE_HOST_API, '/v1/admin/organizations/tag/suggest'),
                searchRegion: () => path(CORE_HOST_API, '/v1/admin/organizations/customers/region/suggest'),
                search: () => path(CORE_HOST_API, '/v1/admin/organizations/customers'),
            },
            vendor: {
                statistic: () => path(CORE_HOST_API, '/v1/admin/organizations/statistics'),
                searchTags: () => path(CORE_HOST_API, '/v1/admin/organizations/tag/suggest'),
                searchRegion: () => path(CORE_HOST_API, '/v1/admin/organizations/region/suggest'),
                search: () => path(CORE_HOST_API, '/v1/admin/organizations'),
            },
            getOwners: (id: string) => path(CORE_HOST_API, '/v1/admin/organizations/{0}/owners/display', [id]),
            searchLogs: (id: string) => path(CORE_HOST_API, '/v1/admin/organizations/{0}/logs', [id]),
            nonEditables: () => path(CORE_HOST_API, '/v1/public/integrations/fields/organizations'),
            detailGeneric: (id: string) => path(CORE_HOST_API, '/v1/admin/organizations/{0}/display', [id]),
            detailEdit: (id: string) => path(CORE_HOST_API, '/v1/admin/organizations/{0}', [id]),
            createGeneric: () => path(CORE_HOST_API, '/v1/admin/organizations'),
            updateGeneric: () => path(CORE_HOST_API, '/v1/admin/organizations'),
            detailMultiple: () => path(CORE_HOST_API, '/v1/admin/organizations/ids/display')
        },
        email: {
            details: (emailId: string) => path(NOTIFICATION_HOST_API, '/v1/admin/email/{0}', [emailId]),
            list: () => path(NOTIFICATION_HOST_API, '/v1/admin/email/logs')
        },
        brands: {
            create: () => path(CORE_HOST_API, '/v1/admin/brands'),
            update: () => path(CORE_HOST_API, '/v1/admin/brands'),
            search: () => path(CORE_HOST_API, '/v1/admin/brands/list'),
            detail: (id: string) => path(CORE_HOST_API, '/v1/admin/brands/{0}', [id]),
            logs: (id: string) => path(CORE_HOST_API, '/v1/admin/brands/{0}/logs', [id]),
            enable: (id: string) => path(CORE_HOST_API, '/v1/admin/brands/{0}/enable', [id]),
            disable: (id: string) => path(CORE_HOST_API, '/v1/admin/brands/{0}/disable', [id])
        }
    },
    public: {
        tenant: {
            detail: () => path(CORE_HOST_API, '/v1/public/tenant/'),
            customField: () => path(CORE_HOST_API, '/v1/public/tenant/customFields')
        },
        user: {
            culture: () => path(CORE_HOST_API, '/v1/public/user/culture'),
            profile: () => path(CORE_HOST_API, '/v1/public/user/profile'),
        },
        brand: {
            search: () => path(CORE_HOST_API, '/v1/public/brands/list'),
        }
    }
};

export const Group = {
    admin: {
        group: {
            list: () => path(GROUP_HOST_API, '/v1/admin/groups'),
            detail: (groupId: string) => path(GROUP_HOST_API, '/v1/admin/groups/{0}', [groupId]),
            create: () => path(GROUP_HOST_API, '/v1/admin/groups'),
            edit: (groupId: string) => path(GROUP_HOST_API, '/v1/admin/groups/{0}', [groupId]),
            members: (groupId: string) => path(GROUP_HOST_API, '/v1/admin/groups/{0}/members', [groupId]),
            enable: (groupId: string) => path(GROUP_HOST_API, '/v1/admin/groups/{0}/enable', [groupId]),
            disable: (groupId: string) => path(GROUP_HOST_API, '/v1/admin/groups/{0}/disable', [groupId]),
            logs: (groupId: string) => path(GROUP_HOST_API, '/v1/admin/groups/{0}/logs', [groupId]),
            organizations: (groupId: string) => path(GROUP_HOST_API, '/v1/admin/groups/{0}/organizations', [groupId]),
            statistic: () => path(GROUP_HOST_API, '/v1/admin/groups/statistics')
        }
    }
};