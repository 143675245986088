import axios from "axios";
import { EntityType, FileWithResizeInfo, ImageResizeParameters, UploadParams, UploadTypes } from "src/@types/media";
import { upload, uploadComplete } from "src/services/mediaService";

export const getFileType = (type: string): UploadTypes => {

    if (type.includes("image"))
        return "Image";

    if (type.includes("audio"))
        return "Audio";

    if (type.includes("video"))
        return "Video";

    return "Attach";
};

export async function handleUpload(files: FileWithResizeInfo[], id: string, type: EntityType, setIsSendingLoading?: (v: boolean) => void, mediaType?: UploadTypes) {

    try {
        const promises = files.map(async f => {
            const params: UploadParams = {
                entityId: id,
                entityType: type,
                fileName: f.name,
                mediaType: mediaType ?? getFileType(f.type)
            };

            const sect = f.section;

            let cropInfo: ImageResizeParameters | null = null;

            if (f.imageResizeParameters) {
                cropInfo = {
                    width: f.imageResizeParameters.width,
                    height: f.imageResizeParameters.height,
                    cropInfo: f.imageResizeParameters.cropInfo ?
                        {
                            height: Math.floor(f.imageResizeParameters.cropInfo.height),
                            width: Math.floor(f.imageResizeParameters.cropInfo.width),
                            x: Math.max(0, Math.floor(f.imageResizeParameters.cropInfo.x)),
                            y: Math.max(0, Math.floor(f.imageResizeParameters.cropInfo.y))
                        } : null
                };
            }

            delete f.section;

            delete f.imageResizeParameters;

            const bin = new Blob([f], { type: f.type });

            const { data } = await upload(params);

            await axios.put(data.uploadUrl, bin, {
                headers: { 'Content-Type': mediaType === "Bundle" ? 'text/csv' : bin.type }
            });

            if (mediaType !== "Bundle" && type !== "DiscountMatrix")
                await uploadComplete({ ...params, uploadUrl: data.uploadUrl, section: sect || null, imageResizeParameters: cropInfo });

        });

        await Promise.all(promises);
    }
    catch (e) {

        throw e;
    }
    finally {
        if (setIsSendingLoading)
            setIsSendingLoading(false);
    }

}