import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Box, FormControlLabel, Switch, TablePagination, Divider, Typography } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import useTable from 'src/appHooks/useTable';
import { useForm } from 'react-hook-form';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import { PagedResponseType, ToolbarSearchFilters } from 'src/@types/commons';
import { cloneDeep, isEqual, omitBy } from 'lodash';
import useResponsive from 'src/hooks/useResponsive';
import { GenericListFilters } from 'src/@types/list';
import { LogsFilters, LogsItem } from 'src/@types/logs';
import dayjs from 'dayjs';
import LogsGeneralFilters from './LogsGeneralFilters';
import DateZone from '../DateZone';

const getFromDate = () => {
    let from = new Date();

    from.setDate(from.getDate() - 13);

    return from;
};

export const DEFAULT_LOGS_FILTERS: LogsFilters = {
    from: dayjs(getFromDate()).startOf("day").toISOString(),
    to: dayjs().endOf("day").toISOString(),
    eventType: "all",
    pageIndex: 0,
    pageSize: 10
};

interface GenericLogsListProps {
    labels: string[],
    logsData: PagedResponseType<LogsItem>,
    searchFunc: (filtersFromUrl: LogsFilters) => void,
    isLoading: boolean,
    description?: string,
    localesPath: string
}

export default function GenericLogsList({ labels, logsData, searchFunc, isLoading, description, localesPath }: GenericLogsListProps) {

    const {
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage
    } = useTable();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const [filters, setFilters] = useState({ ...DEFAULT_LOGS_FILTERS });

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const methods = useForm<GenericListFilters>({ defaultValues: DEFAULT_LOGS_FILTERS });

    const { reset, getValues, watch } = methods;

    const { pageIndex, pageSize, totalCount, items } = logsData;

    var formValues = watch();

    //---- FILTERS IN URL GET/SET - START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters).filter((field) => filters[field] && !isEqual(filters[field], DEFAULT_LOGS_FILTERS[field]))
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
            .join('&');

        if (queryString) queryString = "#" + queryString;

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate]);

    const getPageAndSize = useCallback(() => {
        if (firstRender) return [pageSize, pageIndex];
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    const getFiltersFromUrl = useMemo(() => {

        const { hash } = location;

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize();

        let searchFilters: GenericListFilters = {
            pageIndex: pageIndexFunc,
            pageSize: pageSizeFunc
        };

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                from: DEFAULT_LOGS_FILTERS.from,
                to: DEFAULT_LOGS_FILTERS.to,
                ...(Object.fromEntries(searchParams.entries())),
                ...searchFilters
            };
        } else {
            searchFilters = {
                from: DEFAULT_LOGS_FILTERS.from,
                to: DEFAULT_LOGS_FILTERS.to,
                ...searchFilters
            };
        }

        searchFilters = omitBy(searchFilters, (x) => x === undefined || x === null) as GenericListFilters;

        return searchFilters as LogsFilters;

    }, [location, getPageAndSize]);
    //---- FILTERS IN URL GET/SET - END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: LogsFilters) => {

        var searchFilters: any = {};

        if (isEqual(values, DEFAULT_LOGS_FILTERS)) {
            searchFilters = DEFAULT_LOGS_FILTERS;
        } else {
            Object.keys(values).forEach((field) => { searchFilters[field] = !isEqual(values[field], DEFAULT_LOGS_FILTERS[field]) ? values[field] : null; });

            searchFilters = {
                ...searchFilters,
                pageIndex: page,
                pageSize: rowsPerPage
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [page, rowsPerPage, updateFiltersInUrl]);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    // This function is used to call APIs and get Users list and statistics using filters
    const onSearch = useCallback((filtersFromUrl: LogsFilters) => {

        setFilters(filtersFromUrl);

        updateFiltersInUrl(filtersFromUrl);

        const adjustedFiltersFromUrl: LogsFilters = {
            ...filtersFromUrl,
            from: dayjs(filtersFromUrl.from).startOf("day").toISOString(),
            to: dayjs(filtersFromUrl.to).endOf("day").toISOString()
        };

        searchFunc(adjustedFiltersFromUrl);

        if (firstRender) setFirstRender(false);

    }, [updateFiltersInUrl, firstRender, searchFunc]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    useEffect(() => {

        let searchFilters = cloneDeep(getFiltersFromUrl);

        if (!isEqual(searchFilters, filters) || lastUsedLang !== currentLang.label) {

            onSearch(searchFilters as LogsFilters);

            if (lastUsedLang !== currentLang.label) setLastUsedLang(currentLang.label);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
        }

    }, [location, page, rowsPerPage, firstRender, currentLang, lastUsedLang, filters, pageIndex, pageSize, getFiltersFromUrl, setPage, setRowsPerPage, onSearch]);

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = useCallback(() => {
        fetchData(getValues() as LogsFilters);
        setPage(0);
    }, [fetchData, getValues, setPage]);

    //---- FILTERS RESET - START ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = useCallback(() => {
        setPage(0);
        fetchData(DEFAULT_LOGS_FILTERS);
        reset(DEFAULT_LOGS_FILTERS);
    }, [fetchData, reset, setPage]);
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE START ----//
    const getHeight = useCallback(() => {
        let height: string | number = "auto";

        if (!dense || items.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    }, [dense, isDesktop, items, rowsPerPage]);

    const getMaxHeight = useCallback(() => {
        return isDesktop ? 650 : 700;
    }, [isDesktop]);

    const eventLabels: ToolbarSearchFilters[] = useMemo(
        () => [
            {
                label: translate(`commons.all`),
                key: 'all',
            },
            ...labels.map((label) => ({
                label: translate(`${localesPath}.logs.eventTypes.${label.charAt(0).toLowerCase() + label.slice(1)}`),
                key: label
            }))
        ], [labels, localesPath, translate]);

    const COLUMNS: GridColDef<LogsItem>[] = useMemo(() => [
        {
            field: 'date',
            headerName: translate('commons.date'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.eventDate}
                        variant={"body2"}
                        onlyDate
                    />
                );
            }
        },
        {
            field: 'time',
            headerName: translate('commons.time'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.eventDate}
                        variant={"body2"}
                        onlyHour
                        noSeconds
                    />
                );
            }
        },
        {
            field: 'loggedUser',
            headerName: translate('user.logs.loggedUser'),
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.loggedUser?.firstName + " " + obj.row.loggedUser?.lastName}
                    </Typography>
                );
            }
        },
        {
            field: 'description',
            headerName: translate('commons.description'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {eventLabels.find((label) => label.key === obj.row.eventType)?.label}
                    </Typography>
                );
            }
        }
    ], [translate, isDesktop, eventLabels]);

    useEffect(() => {
        const [rowsFromFunc, pageFromFunc] = getPageAndSize();

        if (totalCount <= rowsFromFunc * pageFromFunc) onChangePage(null, 0);

    }, [onChangePage, totalCount, getPageAndSize]);

    useEffect(() => {
        if (pageIndex !== page) setPage(pageIndex);
    }, [pageIndex, setPage]);

    //---- HANDLE TABLE END ----//

    return (
        <Card>

            {description &&
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', px: { xs: 2, md: 3.5 }, py: 2 }}>
                        <Typography variant="body2">
                            {description}
                        </Typography>
                    </Box>

                    <Divider />
                </>
            }

            <FormProvider methods={methods}>
                <LogsGeneralFilters
                    filters={{ ...formValues, ...getFiltersFromUrl }}
                    onFilter={handleSearchFilters}
                    resetAll={handleResetAllFilter}
                    optionsFields={eventLabels}
                />
            </FormProvider>

            <Divider />

            <Box>
                <DataGrid
                    rows={items}
                    columns={COLUMNS}
                    pagination
                    disableColumnResize
                    paginationModel={{
                        page: page,
                        pageSize: rowsPerPage
                    }}
                    density={(dense && items.length > 0) ? 'compact' : 'standard'}
                    loading={isLoading}
                    slots={{
                        noRowsOverlay: noData,
                        footer: () => (
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: { xs: "90vw", md: "auto" }
                                }}
                            >
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 30]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangeRowsPerPage}
                                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                    sx={{
                                        overflow: "hidden",
                                        "& .MuiTablePagination-input": {
                                            ml: { xs: 0.5, md: "default" },
                                            mr: { xs: 3.5, md: "default" }
                                        }
                                    }}
                                />
                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={`${translate('commons.dense')}`}
                                    sx={{
                                        px: { xs: 0, sm: 3 },
                                        py: { xs: 0, sm: 1.5 },
                                        pb: { xs: 1.5, sm: 0 },
                                        mx: 0,
                                        top: 0,
                                        justifyContent: "center",
                                        width: { xs: "90vw", sm: "auto" },
                                        position: { sm: 'absolute' }
                                    }}
                                />
                            </Box>
                        )
                    }}
                    disableColumnMenu
                    pageSizeOptions={[5, 10, 15, 30]}
                    sx={{
                        ...DataGridStyle,
                        cursor: 'auto',
                        height: getHeight(),
                        maxHeight: getMaxHeight()
                    }}
                />
            </Box>

        </Card>
    );
} 