import { useState, useEffect, useMemo, useCallback } from 'react';
import { Stack, Badge, Tooltip, Divider, Typography, Drawer, alpha, useTheme, Tabs, Tab, Box, Paper, Avatar, Link, Button, List } from '@mui/material';
import { inboxOperations } from 'src/redux/inbox';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import useLocales from '../../../appHooks/useLocales';
import Iconify from '../../../components/iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { bgBlur } from 'src/utils/cssStyles';
import Label, { LabelColor } from 'src/components/label';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { InboxSearchResult } from 'src/@types/inbox';
import { formatDistanceToNow } from 'date-fns';
import NotificationsSettingsPopover from 'src/sections/@dashboard/inbox/NotificationSettingsPopover';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { translate, currentLang } = useLocales();

  const { recentMessages, totalCount, unreadCount } = useSelector((state: RootState) => state.inbox);

  const [openPopover, setOpenPopover] = useState(false);

  const [currentTab, setCurrentTab] = useState("all");

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();

  const initialize = useCallback(() => {

    dispatch(inboxOperations.getRecentMessages({
      isRead: currentTab === "all" ? undefined : false,
      isSent: false,
      pageIndex: 0,
      pageSize: 10
    }));

    dispatch(inboxOperations.getRecentInboxLabelsAndStatistics());

  }, [currentTab]);

  useEffect(() => {
    initialize();
  }, [currentLang, currentTab, initialize]);

  const handleOpenPopover = () => {
    initialize();
    setOpenPopover(true);
  };

  const handleMarkAllAsRead = () => {
    const messagesIds = recentMessages.items.filter(x => !x.read).map((message) => (message.id));

    dispatch(inboxOperations.markAsRead(messagesIds)).unwrap()
      .then(() => initialize());

  };

  const statistics = useMemo(() =>
    [{
      label: `${translate('commons.all')}`,
      key: 'all',
      color: 'info'
    },
    {
      label: `${translate('notifications.unread')}`,
      key: 'unread',
      color: 'success'
    }], [translate]);

  const getStatistic = (key: 'all' | 'unread') => {
    switch (key) {
      case 'all':
        return totalCount;
      case 'unread':
        return unreadCount;
    }
  };

  const handleChangeTab = (key: string) => {
    setCurrentTab(key);
  };

  const handleDetail = (notification: InboxSearchResult) => {
    if (!notification.read) {
      dispatch(inboxOperations.markAsRead([notification.id]))
        .unwrap()
        .then(() => initialize());

    }

    navigate(PATH_DASHBOARD.inbox.detail("all", notification.id));
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={unreadCount} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <Drawer
        anchor="right"
        open={openPopover}
        onClose={() => setOpenPopover(false)}
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{
          sx: {
            bgcolor: 'transparent',
            ...bgBlur({ color: theme.palette.background.default, opacity: 0.78 }),
            width: isDesktop ? '25vw' : '75vw',
            boxShadow: (theme) =>
              `-24px 12px 40px 0 ${alpha(
                theme.palette.mode === 'light'
                  ? theme.palette.grey[500]
                  : theme.palette.common.black,
                0.16
              )}`,
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 2, pr: 1, pl: 2.5 }}
        >
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            {`${translate('notifications.title')}`}
          </Typography>

          {unreadCount >= 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ display: 'flex', alignItems: 'center', pr: 1 }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            sx={{
              width: '100%',
              display: 'flex',
              gap: '3',
              justifyContent: 'space-between',
            }}
          >
            {statistics.map((tab, index) =>
              <Tab
                disableRipple
                onClick={() => handleChangeTab(tab.key)}
                key={index}
                label={tab.label}
                value={tab.key}
                sx={{ mx: 2, fontSize: 'big' }}

                icon={
                  <Label sx={{ mr: 1 }} fontSize={'big'} color={tab.color as LabelColor}>
                    {getStatistic(tab.key as 'all' | 'unread')}
                  </Label>
                }
              />
            )}
          </Tabs>
          <NotificationsSettingsPopover />
        </Box>

        <List
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '100%'
          }}
        >
          {recentMessages.items.map((notification, index) => (
            <Box
              key={index}
              sx={{
                cursor: 'pointer',
                pb: 1,
                ":hover": {
                  backgroundColor: (theme) => theme.palette.action.selected
                }
              }}

              onClick={() => handleDetail(notification)}
            >
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Box sx={{ p: 2, display: 'flex', gap: 3 }}>
                <Avatar
                  sx={{ bgcolor: (theme) => theme.palette.primary.dark, width: 40, height: 40 }}
                  src={notification.from.avatar || '/assets/illustrations/illustration_maintenance.svg'}
                  alt={'avatar'}
                />
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', mb: 1 }}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>

                      <Typography variant='body1' sx={{ color: (theme) => theme.palette.primary.main }}>
                        {notification.from.name}
                      </Typography>
                    </Box>
                    {!notification.read &&
                      <Box sx={{
                        borderRadius: '50%',
                        bgcolor: (theme) => theme.palette.error.main,
                        width: '9px',
                        height: '9px',
                        ml: 'auto'

                      }} />
                    }
                  </Box>

                  <Box sx={{ display: 'flex', gap: 1, color: (theme) => theme.palette.action.disabled }}>
                    <Typography variant='caption'>
                      {formatDistanceToNow(new Date(notification.createdOn), {
                        addSuffix: true,
                        locale: currentLang.locale
                      })}
                    </Typography>
                    <Typography variant='caption'>-</Typography>
                    <Typography variant='caption' >system</Typography>
                  </Box>
                  <Paper sx={{ p: 2, my: '12px', mr: '12px' }} >
                    <Typography variant='body2' style={{ wordBreak: 'break-word' }}>
                      {notification.title}
                    </Typography>
                  </Paper>
                </Box>
              </Box>
            </Box>
          ))}

        </List>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ px: 2, py: 1 }}>
          <Link href={PATH_DASHBOARD.inbox.list}>
            <Button fullWidth disableRipple sx={{
              height: '100%'
            }}>
              {`${translate("notifications.viewAll")}`}
            </Button>
          </Link>
        </Box>
      </Drawer >
    </>);
}